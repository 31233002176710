import {createSlice} from '@reduxjs/toolkit'

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        isDarkTheme: true,
        message: {message: ''},
    },
    reducers: {
        toggleDarkTheme: (state) => {
            document.body.classList.toggle('white-content')
            state.isDarkTheme = !state.isDarkTheme
        },
        setMessage: (state, {payload}) => {
            state.message = {message: payload.message, type: payload.type}
        },
    },
})

export const {toggleDarkTheme, setMessage} = appSlice.actions

export const useIsDarkTheme = (state) => state?.app?.isDarkTheme
export const useAppMessage = (state) => state?.app?.message

export default appSlice.reducer
