import SwitchLanguage from 'components/Details/SwitchLanguage'
import Spinner from 'components/general/Display/Spinner'
import {LANGUAGES_OBJECT} from 'constant/languages'
import {useEffect, useState, useDispatch} from 'services/hooks'
import {
    getSuccessSaveMessageData,
    getTranslationData,
    showAlertMessage,
} from 'services/utils'

const getIsFieldMissing = (newName, displayName, hideDisplayName) => {
    if (!newName || (!displayName && !hideDisplayName)) {
        return true
    }
    return false
}

const ActivitySwitchLanguage = ({
    loading,
    language,
    setLanguage,
    activityId,
    activityName = '',
    activityDisplayName = '',
    activityInstructions = '',
    title,
    hideDisplayName,
    saveTranslation,
    isSavingError,
    isSavingSuccess,
    hideInstructions,
}) => {
    const dispatch = useDispatch()
    const [displayName, setDisplayName] = useState(activityDisplayName)
    const [newName, setNewName] = useState(activityName)
    const [instructions, setInstractions] = useState(activityInstructions)

    useEffect(() => {
        setDisplayName(activityDisplayName)
        setNewName(activityName)
        setInstractions(activityInstructions)
    }, [activityName, activityDisplayName, activityInstructions])

    useEffect(() => {
        const {message, type} = getSuccessSaveMessageData(newName)

        showAlertMessage(message, type, dispatch)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isSavingError, isSavingSuccess])

    const handleDataByLanguage = () => {
        if (getIsFieldMissing(newName, displayName, hideDisplayName)) {
            return alert('Missing Field!')
        } else {
            const data = {
                name: newName,
                display_name: displayName,
                instructions: [instructions],
            }

            const translationData = {
                translations: getTranslationData(data, language, activityId),
            }

            saveTranslation(translationData)
        }
    }

    const spinnerLabel = `PREPARING ${LANGUAGES_OBJECT[
        language
    ]?.toUpperCase()} TRANSLATION`
    return (
        <div className="content">
            {loading ? (
                <Spinner label={spinnerLabel} />
            ) : (
                <SwitchLanguage
                    hideDisplayName={hideDisplayName}
                    hideInstructions={hideInstructions}
                    name={newName}
                    header={title}
                    language={language}
                    setName={setNewName}
                    setLanguage={setLanguage}
                    displayName={displayName}
                    instructions={instructions}
                    setDisplayName={setDisplayName}
                    setInstractions={setInstractions}
                    handleDataByLanguage={handleDataByLanguage}
                />
            )}
        </div>
    )
}

export default ActivitySwitchLanguage
