import {React, styled} from 'common'
// import {Colors} from 'appearance'
import {NavLink, useEffect, useRef} from 'services/hooks'

import PerfectScrollbar from 'perfect-scrollbar'

import {Nav} from 'components/general/Control'

import logoIcon from 'assets/img/sidebar-logo.svg'

const SidebarLogo = () => {
    const logo = {
        outterLink: 'https://www.hyfitgear.com/',
        text: 'HyFit Gear',
        imgSrc: logoIcon,
    }

    const logoImg = (
        <a href={logo.outterLink} className="simple-text logo-mini">
            <div className="logo-img">
                <img src={logo.imgSrc} alt="react-logo" />
            </div>
        </a>
    )

    const logoText = (
        <a
            className="simple-text logo-normal"
            href="https://www.hyfitgear.com/">
            {logo.text}
        </a>
    )

    return (
        <SidebarLogoWrapper className="logo">
            {logoImg}
            {logoText}
        </SidebarLogoWrapper>
    )
}

const SidebarRoutes = ({routes}) => {
    const activeRoute = (routeName) =>
        window.location.pathname === routeName ? 'active' : ''

    const createLinks = (routes) => {
        return routes.map((prop, key) => {
            if (prop.redirect) {
                return null
            }
            if (prop.hidden) {
                return null
            }
            const id = 'menu-' + prop.title.toLowerCase().replace(' ', '-')
            return (
                <SidebarRoute
                    className={activeRoute(prop.layout + prop.path)}
                    key={'menu-' + key}>
                    <NavLink
                        to={prop.layout + prop.path}
                        id={id}
                        activeClassName="">
                        {prop.icon && (
                            <>
                                <i className={prop.icon} />
                                <p>{prop.name}</p>
                            </>
                        )}
                        {prop.imgIcon && (
                            <SidebarContainerImg
                                className={activeRoute(
                                    prop.layout + prop.path
                                )}>
                                <img
                                    src={prop.imgIcon}
                                    alt="ic"
                                    width={24}
                                    height={24}
                                />
                                <SidebarNameImg>{prop.name}</SidebarNameImg>
                            </SidebarContainerImg>
                        )}
                    </NavLink>
                </SidebarRoute>
            )
        })
    }

    return <Nav>{createLinks(routes)}</Nav>
}

const PageSidebar = ({routes}) => {
    const sidebarRef = useRef(null)
    useEffect(() => {
        let ps
        if (navigator.platform.indexOf('Win') > -1) {
            ps = new PerfectScrollbar(sidebarRef?.current, {
                suppressScrollX: true,
                suppressScrollY: false,
            })
        }
        // document.body.classList.List.toggle('sidebar-mini')

        return navigator.platform.indexOf('Win') > -1 ? ps.destroy() : null
    }, [])

    return (
        <StyledSidebar className="sidebar" data={'danger'}>
            <SidebarWrapper ref={sidebarRef} className="sidebar-wrapper">
                <SidebarLogo />
                <SidebarRoutes routes={routes} />
            </SidebarWrapper>
        </StyledSidebar>
    )
}

export default PageSidebar

const SidebarRoute = styled.li`
    display: flex;
    p {
        font-size: 0.8rem;
    }
`
const SidebarLogoWrapper = styled.div``
const SidebarWrapper = styled.div`
    overflow-x: hidden;
`
const StyledSidebar = styled.div``

const SidebarContainerImg = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 4px;
`

const SidebarNameImg = styled.p`
    padding-left: 15px;
`
