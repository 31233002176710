import {Row, Col} from 'components/general/Control'
import {primaryPlaneOfMotion} from 'constant/exercise'
import SelectInput from 'components/general/Control/SelectInput'

const PrimaryPlane = ({showError, primaryPlane, setPrimaryPlane}) => {
    return (
        <Row form>
            <Col md={4}>
                <SelectInput
                    required
                    showError={showError}
                    selected={primaryPlane}
                    onChange={setPrimaryPlane}
                    options={primaryPlaneOfMotion}
                    name={'primary_plane_of_motion'}
                    label={'primary plane of motion'}
                />
            </Col>
        </Row>
    )
}

export default PrimaryPlane
