/* eslint-disable no-unused-vars */

import styled from 'styled-components'
import React, {useEffect, useRef, useState} from 'react'
import {exerciseFilters} from 'constant/exercise'
import SubMenuFilterItem from './SubMenuFilterItem'
import SubMenuFilterLabel from './SubMenuFilterLabel'
import {
    List,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    Collapse,
} from 'reactstrap'
import {useIsDarkTheme} from 'store/store'
import {useSelector} from 'react-redux'
import {Colors} from 'appearance'

const MultiSelectDropdown = ({
    selectedItems,
    isInputFocused,
    setSelectedItems,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const isDarkTheme = useSelector(useIsDarkTheme)
    const [openLabels, setOpenLabels] = useState([])
    const isOpenRef = useRef(isOpen)

    useEffect(() => {
        isOpenRef.current = isOpen
    }, [isOpen])

    useEffect(() => {
        window.addEventListener('click', (ev) =>
            handleScreenClicked(ev, isOpenRef)
        )

        return () => {
            window.removeEventListener('click', (ev) =>
                handleScreenClicked(ev, isOpenRef)
            )
        }
    }, [])

    const handleScreenClicked = (ev, _isOpen) => {
        if (
            !_isOpen ||
            (_isOpen && ev.target.dataset?.type === 'filter-drop-down')
        ) {
            return
        } else {
            setIsOpen(false)
        }
    }

    const removeFilterLabel = (label) => {
        const newObj = {}
        Object.keys(selectedItems)?.map(
            (key) => key !== label && (newObj[key] = selectedItems[key])
        )
        return newObj
    }

    const isItemSelected = (label, list, isChild = false, child) => {
        const isListEmpty = Object.keys(list).length === 0
        if (!isListEmpty) {
            if (isChild) {
                return list[label]?.includes(child)
            } else {
                return Boolean(list[label])
            }
        }
        return false
    }

    const isLabelOpen = (label) =>
        openLabels.length > 0 && openLabels.includes(label)

    const handleExpandSubMenu = (isSubMenuOpen, item) => {
        const newOpenLabels = isSubMenuOpen
            ? openLabels?.filter((lbl) => lbl !== item.label)
            : [...openLabels, item.label]

        setOpenLabels(newOpenLabels)
    }

    useEffect(() => {
        isInputFocused && setIsOpen(false)
    }, [isInputFocused])

    const dropDownBackground = isDarkTheme ? Colors.OPAC_DARK : Colors.WHITE

    const hoverColor = isDarkTheme ? Colors.DARK_HOVER : Colors.LIGHT_HOVER
    const showDot = Object.keys(selectedItems)?.length > 0

    return (
        <Dropdown
            toggle={() => setIsOpen(!isOpen)}
            className="px-0"
            data-type="filter-drop-down">
            <StyledButton
                showdot={showDot ? 'true' : undefined}
                data-type="filter-drop-down">
                filter
            </StyledButton>
            <StyleDropDownMenu
                data-type="filter-drop-down"
                visible={isOpen ? 'true' : undefined}
                show={isOpen ? 'true' : undefined}
                bcakground={dropDownBackground}>
                {exerciseFilters.map((item) => {
                    const isSubMenuOpen = isLabelOpen(item.label)
                    const isSelected = Boolean(
                        selectedItems[item.label]?.length > 0
                    )

                    return (
                        <StyledItem
                            data-type="filter-drop-down"
                            hover={hoverColor}
                            className="dropdown list-group pl-1"
                            key={'filte_label_' + item.label}>
                            <SubMenuFilterLabel
                                item={item}
                                isSelected={isSelected}
                                isSubMenuOpen={isSubMenuOpen}
                                selectedItems={selectedItems}
                                isItemSelected={isItemSelected}
                                setSelectedItems={setSelectedItems}
                                removeFilterLabel={removeFilterLabel}
                                handleExpandSubMenu={handleExpandSubMenu}
                            />
                            <Collapse isOpen={isSubMenuOpen}>
                                <SubMenuFilterItem
                                    item={item}
                                    selectedItems={selectedItems}
                                    isItemSelected={isItemSelected}
                                    setSelectedItems={setSelectedItems}
                                    removeFilterLabel={removeFilterLabel}
                                />
                            </Collapse>
                        </StyledItem>
                    )
                })}
            </StyleDropDownMenu>
        </Dropdown>
    )
}

export default MultiSelectDropdown

const StyledButton = styled(DropdownToggle)`
    width: 100%;
    font-size: 0.7rem;
    text-transform: uppercase;
    padding-inline: 0;
    margin-top: 0.6px;
    padding-block: 9.9px;
    ${({showdot}) =>
        showdot &&
        `
        &::after {
             content: '•';
             position:absolute;
             color: red;
             margin-left: 5px;
         }`};
`

const StyleDropDownMenu = styled(DropdownMenu)`
    margin: 1.5rem 0 0;
    visibility: ${({visible}) => (visible ? 'visible' : 'hidden')} !important;
    opacity: ${({visible}) => (visible ? 1 : 0)} !important;
    max-height: 300px;
    overflow: scroll;
    box-shadow: 0px 10px 50px 3px rgba(0 0 0 0.5);
    background-color: ${({bcakground}) => bcakground};
    border-radius: 6px;
    padding-inline-start: 5px;
    padding: 5px 0 0 5px;
    &::before {
        content: none;
    }
`

const StyledItem = styled(List)`
    &:hover {
        background-color: ${({hover}) => hover};
    }
`
