import {Col, FormGroup} from 'components/general/Control'
import RadioButtonsInput from 'components/general/Control/RadioButtonsInput'
import {workoutAccessOptions} from 'constant/workout'
import React from 'react'

const WorkoutAccessSelection = ({
    workoutAccess,
    setWorkoutAccess,
    showError,
}) => {
    return (
        <Col md={6} className="px-0  pr-md-3">
            <FormGroup>
                <RadioButtonsInput
                    options={workoutAccessOptions}
                    showError={showError}
                    name={'workout_access'}
                    selected={workoutAccess || workoutAccessOptions[0].value}
                    label={'Workout Access'}
                    onChange={setWorkoutAccess}
                />
            </FormGroup>
        </Col>
    )
}
export default WorkoutAccessSelection
