import React from 'react'
import {FormGroup, Col} from 'components/general/Control'
import SelectInput from 'components/general/Control/SelectInput'
import {exercisePurposeOptions} from 'constant/exercise'

const ExercisePurpose = ({type, setType, showError}) => {
    return (
        <Col className="px-0" sm="12" lg="6" md="6">
            <FormGroup>
                <SelectInput
                    required
                    selected={type}
                    label={'purpose'}
                    onChange={setType}
                    showError={showError}
                    name={'exercise_purpose'}
                    options={exercisePurposeOptions}
                />
            </FormGroup>
        </Col>
    )
}

export default ExercisePurpose
