import {Row, Col} from 'components/general/Control'
import {actionType} from 'constant/exercise'
import SelectInput from 'components/general/Control/SelectInput'

const ExerciseAction = ({showError, action, setAction}) => {
    return (
        <Row form>
            <Col md={4}>
                <SelectInput
                    required
                    label={'Action'}
                    selected={action}
                    onChange={setAction}
                    options={actionType}
                    showError={showError}
                    name={'exercise_action'}
                />
            </Col>
        </Row>
    )
}

export default ExerciseAction
