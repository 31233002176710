import {React} from 'common'
import {Page} from 'components/general/Page'
import {
    TableWrapper,
    SelectColumnFilter,
    TableLink,
} from 'components/general/Display/Table'
import api from 'services/api'

import {useGetExercisesQuery, useSaveExerciseMutation} from 'store/store'
import {useMemo, useState, useEffect, useOnSaveCSV} from 'services/hooks'
import {SweetAlert} from 'components/general/Display'
import {Col} from 'components/general/Control'
import {getDuplicatedNamesMap} from 'services/utils'

const __FIND_DUPLICATED_NAMES__ = false

const columns = [
    {
        Header: 'Internal Name',
        accessor: 'name',
        filter: 'default',
        isSortableIcon: true,
    },
    {
        Header: 'App Display Name',
        accessor: 'display_name',
        filter: 'default',
        isSortableIcon: true,
    },

    {
        Header: 'Difficulty',
        accessor: 'difficulty',
        Filter: SelectColumnFilter,
        filter: 'includes',
        isSortableIcon: true,
    },
    {
        Header: 'Copy',
        accessor: 'copy',
        maxWidth: 70,
        isDownloadable: true,
    },
    {
        Header: 'Active',
        accessor: 'activeIcon',
        Filter: SelectColumnFilter,
        filter: 'equals',
    },
    {
        Header: 'Equipment',
        accessor: 'Equipment',
        Filter: SelectColumnFilter,
        filter: 'equals',
    },
    {
        Header: 'New Version',
        accessor: 'newVersion',
        Filter: SelectColumnFilter,
        filter: 'equals',
    },
]

const Exercises = () => {
    const [saveExercise] = useSaveExerciseMutation()
    const exercises = useGetExercisesQuery()
    const [exToCopy, setExToCopy] = useState(null)
    const alertRef = React.createRef()
    const [isLoading, onSaveCSV] = useOnSaveCSV()

    const onCopy = (exercise) => {
        let newEx = {
            ...exercise,
            id: null,
            active: false,
            deleted: false,
            name: exercise.name?.trim() + ' - Copy',
        }
        setExToCopy(newEx)
    }

    const saveExerciseAndRefetch = async (exercise) => {
        await saveExercise(exercise)
        exercises.refetch()
    }

    const createExerciseCopy = async () => {
        delete exToCopy.instructions
        delete exToCopy.muscle
        delete exToCopy.workout_id
        delete exToCopy.trainer

        saveExerciseAndRefetch({
            exercise: exToCopy,
            id: null,
        })

        return setExToCopy(null)
    }

    const data = useMemo(
        () =>
            exercises?.data?.map((prop, key) => {
                return {
                    id: key,
                    name: prop.name,
                    display_name: prop.display_name,
                    trainer: prop.trainer,
                    copy: (
                        <TableLink
                            color="green"
                            icon={'tim-icons icon-single-copy-04'}
                            onClick={() => onCopy(prop)}
                            text="Copy"
                        />
                    ),
                    pathname: `/admin/exercise/${prop.id}`,
                    _id: prop.id,
                    difficulty: prop.difficulty ? String(prop.difficulty) : '-',
                    muscles: prop.muscles,
                    active: prop.active,
                    version: prop.version,
                    Equipment: prop.exercise_type,
                    activeIcon: prop.active ? 'true' : 'false',
                    newVersion: prop.version > 1 ? 'true' : 'false',
                }
            }),
        [exercises.data]
    )
    useEffect(() => {
        exToCopy && alertRef?.current()
    }, [exToCopy, alertRef])

    useEffect(() => {
        if (exercises?.data && __FIND_DUPLICATED_NAMES__) {
            getDuplicatedNamesMap(exercises.data)
        }
    }, [exercises])
    return (
        <Page hideSidbar={false}>
            <TableWrapper
                title="Exercises"
                columns={columns}
                data={data}
                onSaveCSV={() => onSaveCSV(api.getExcerciseCSV(), 'exercises')}
                createNew={'/admin/exercise/new'}
                filterByVersion
                type={'EXERCISES'}
                isFetching={isLoading}
            />
            <Col>
                <SweetAlert
                    ref={alertRef}
                    title={'Copy Exercise'}
                    mainText={exToCopy?.name}
                    action="created"
                    deactivate={createExerciseCopy}
                    confirmBtnText="Copy exercise"
                    id={exToCopy?.id}
                />
            </Col>
        </Page>
    )
}

export default Exercises
