import {Col, Row} from 'components/general/Control'
import SelectInput from 'components/general/Control/SelectInput'
import {activityGearXOption} from 'constant/exercise'

const ActivityGearX = ({
    showError,
    activityGearXValue,
    setActivityGearXValue,
}) => {
    return (
        <Row>
            <Col className="checkbox-radios py-2" sm="12" lg="8">
                <SelectInput
                    required
                    showError={showError}
                    name={'activity_gear_x'}
                    label={'Activity Gear X'}
                    selected={activityGearXValue}
                    onChange={setActivityGearXValue}
                    options={activityGearXOption}
                />
            </Col>
        </Row>
    )
}

export default ActivityGearX
