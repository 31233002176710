import {
    SelectColumnFilter,
    TableWrapper,
} from 'components/general/Display/Table'
import {Page} from 'components/general/Page'
import {useMemo} from 'react'
import {getUrlParamsObject, useParams} from 'services/hooks'
import moment from 'moment'

import {useGetRoutinesGroupByDeviceQuarterQuery} from 'store/store'

const columns = [
    {
        Header: 'Gear',
        accessor: 'device_type',
    },
    {
        Header: 'Name',
        accessor: 'routine_name',
        filter: 'default',
        isSortableIcon: true,
    },
    {
        Header: 'Start Date',
        accessor: 'start_of_quarter',
        isSortableIcon: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
        Cell: ({cell: {value}}) => moment(value).format('YY-MM-DD'),
    },
    {
        Header: 'End Date',
        accessor: 'end_of_quarter',
        isSortableIcon: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
        Cell: ({cell: {value}}) => moment(value).format('YY-MM-DD'),
    },
]

const Routines = () => {
    const params = useParams()
    const urlParams = getUrlParamsObject()

    const {data: routines} = useGetRoutinesGroupByDeviceQuarterQuery({
        device_type: params.device_type,
    })

    const data = useMemo(
        () =>
            routines?.dhrs.map((prop, key) => {
                return {
                    id: key,
                    device_type: prop.device_label,
                    routine_name: prop.name,
                    start_of_quarter: prop.start_of_quarter,
                    end_of_quarter: prop.end_of_quarter,
                    pathname: `/admin/routine/modify/${prop.device_type}`,
                    queryParams: `?start_of_quarter=${prop.start_of_quarter}&end_of_quarter=${prop.end_of_quarter}`,
                }
            }),
        [routines]
    )

    const createNewPath = useMemo(() => {
        if (data?.[0]?.end_of_quarter) {
            return `/admin/routine/new/${params.device_type}?end_of_last_quarter=${data?.[0]?.end_of_quarter}&device_label=${urlParams.device_label}`
        }

        return `/admin/routine/new/${params.device_type}?device_label=${urlParams.device_label}`
    }, [data, params.device_type, urlParams])

    return (
        <Page hideSidbar={false}>
            <TableWrapper
                title="Daily Habit Routine"
                columns={columns}
                data={data}
                createNew={createNewPath}
                filterByVersion
                type={'ROUTINES'}
            />
        </Page>
    )
}

export default Routines
