import {Col, Row} from 'components/general/Control'
import SingleCheckbox from 'components/general/Control/SingleCheckbox'

const ActivationInput = ({active, setactive, type = 'exercise'}) => {
    return (
        <Row className="mt-5 disaply-2">
            <Col className="d-flex justify-content-center ">
                <SingleCheckbox
                    fontSize={19}
                    option={{
                        label: `Activate ${type} after saving?`,
                        value: active,
                    }}
                    onChange={setactive}
                />
            </Col>
        </Row>
    )
}

export default ActivationInput
