import {Row} from 'components/general/Control'
import RadioButtonsInput from 'components/general/Control/RadioButtonsInput'
import {WORKOUT_TARGETS} from 'constant/workout'

const WorkoutTarget = ({updatedWorkout, onUpdateWorkoutTarget}) => {
    const updateWorkoutTarget = (value) => {
        onUpdateWorkoutTarget(value)
    }

    return (
        <Row className={'mb-1 px-0 mx-1'} style={{minWidth: 135}}>
            <RadioButtonsInput
                filter
                selected={updatedWorkout?.target || WORKOUT_TARGETS[0].value}
                onChange={updateWorkoutTarget}
                options={WORKOUT_TARGETS}
                name={'workout_target'}
            />
        </Row>
    )
}

export default WorkoutTarget
