import React from 'react'
import {FormGroup, Row, Col} from 'components/general/Control'
import AppInput from 'components/general/Control/Input'
import {NameInput} from 'components/Details'

const NameAndDisplayName = ({
    name = '',
    label = '',
    setName,
    showError,
    nameError,
    displayName = '',
    disableName,
    className,
    required,
    setDisplayName,
    hideDisplayName,
    mandatoryDisplayName,
}) => {
    const display_name = `${label} DISPLAY NAME ${required ? '*' : ''}`
    return (
        <Row className={className}>
            <NameInput
                name={name}
                label={label}
                setName={setName}
                showError={showError}
                nameError={nameError}
                disableName={disableName}
            />

            {!hideDisplayName && (
                <Col className="px-0" sm="12" lg="6" md="6">
                    <FormGroup>
                        <label>{display_name}</label>
                        <AppInput
                            name="display_name"
                            placeholder="Enter Display Name"
                            value={displayName}
                            setter={setDisplayName}
                            showError={mandatoryDisplayName && showError}
                        />
                    </FormGroup>
                </Col>
            )}
        </Row>
    )
}

export default NameAndDisplayName
