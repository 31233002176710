import {Col} from 'components/general/Control'
import SingleCheckbox from 'components/general/Control/SingleCheckbox'
import React from 'react'

const AlternateRepetitions = ({
    combinedRepitionsCount,
    setCombinedRepitionsCount,
}) => {
    return (
        <Col sm="12" md="6" lg="6">
            <SingleCheckbox
                label={'Alternate Repetition Count'}
                description={
                    '(When Selected, repetitions are counted seperatly from each band)'
                }
                option={{
                    label: 'ALTERNATE Repetitions',
                    value: combinedRepitionsCount,
                }}
                onChange={setCombinedRepitionsCount}
            />
        </Col>
    )
}
export default AlternateRepetitions
