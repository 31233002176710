import {React, styled} from 'common'
import {Colors, Texts} from 'appearance'
import {Button} from 'components/general/Control'

import VersionsInputs from './VersionInputs'

const VersionsControl = ({
    currentVersionDounloadLink,
    currentVersion,
    newFirmwareData,
    setNewFirmwareData,
    handleFile,
    setShowInputs,
    showInputs,
}) => {
    return (
        <Container>
            <TitleWrapper>
                <DownloadUrl
                    id={'download-url'}
                    href={currentVersionDounloadLink}>
                    <Texts.SecondTitle>
                        Current Version:{' '}
                        <CurrentVersion
                            downloadable={Boolean(currentVersionDounloadLink)}>
                            {currentVersion}
                        </CurrentVersion>
                    </Texts.SecondTitle>
                </DownloadUrl>
                <Button
                    id="save_button"
                    color="secondary"
                    type="submit"
                    onClick={() => setShowInputs(!showInputs)}>
                    Change
                </Button>
            </TitleWrapper>
            {showInputs && (
                <VersionsInputs
                    newFirmwareData={newFirmwareData}
                    setNewFirmwareData={setNewFirmwareData}
                    handleFile={handleFile}
                />
            )}
        </Container>
    )
}

export default VersionsControl

const Container = styled.div``

const DownloadUrl = styled.a`
    color: ${Colors.LINK_TEXT};
`
const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 25px;
`
const CurrentVersion = styled.span`
    color: ${({downloadable}) =>
        downloadable ? Colors.PRIMARY_COLOR : Colors.PRIMARY_TEXT};
`
