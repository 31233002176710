import React from 'react'
import FormCheckLabel from 'react-bootstrap/esm/FormCheckLabel'
import styled from 'styled-components'

const SubMenuFilterLabel = ({
    item,
    isSelected,
    // selectedItems,
    // setSelectedItems,
    // removeFilterLabel,
    // isItemSelected,
    handleExpandSubMenu,
    isSubMenuOpen,
}) => {
    // const handleLabelsChecked = (label) => {
    //     const isSelected = isItemSelected(label, selectedItems)
    //     const typeListInExerciseFilter = exerciseFilters.find(
    //         (filter) => filter.label === label
    //     )
    //     const newItems = isSelected
    //         ? removeFilterLabel(label)
    //         : {
    //               ...selectedItems,
    //               [label]: [...typeListInExerciseFilter.children],
    //           }
    //     setSelectedItems(newItems)
    // }

    const labelName = item.display_name || item.label.replaceAll('_', ' ')

    return (
        <Container
            className="dropdown pl-0 d-flex align-items-center "
            data-type="filter-drop-down"
            onClick={() => {
                handleExpandSubMenu(isSubMenuOpen, item)
            }}>
            <StyledArrow
                data-type="filter-drop-down"
                isSubMenuOpen={isSubMenuOpen}
                color={isSelected ? 'red' : undefined}
                className={
                    isSubMenuOpen
                        ? 'tim-icons icon-minimal-down'
                        : 'tim-icons icon-minimal-right'
                }
            />

            <StyledLabel data-type="filter-drop-down">{labelName}</StyledLabel>
        </Container>
    )
}

export default SubMenuFilterLabel
const Container = styled.div`
    cursor: pointer;
`

const StyledLabel = styled(FormCheckLabel)`
    text-transform: capitalize;
    cursor: pointer;
`

const StyledArrow = styled.div`
    transform: rotate(${({rotate}) => rotate}deg);
    transition: transform 0.3s;
    margin-right: 6px !important;
    font-size: 10px !important;
    top: 0 !important;
    opacity: 1 !important;
    color: ${({color}) => color || 'inherit'};
`
