import React, {useState} from 'react'
import PropTypes from 'prop-types'
import ReactBSAlert from 'react-bootstrap-sweetalert'

/**
 * Renders a confirmation alert component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.showAlert - Determines whether to show the alert or not.
 * @param {string} props.title - The title of the alert.
 * @param {string} props.mainText - The main text of the alert.
 * @param {string} props.confirmText - The text for the confirm button.
 * @param {string} props.cancelText - The text for the cancel button.
 * @param {Function} props.onConfirm - The callback function to be called when the confirm button is clicked.
 * @param {Function} props.onCancel - The callback function to be called when the cancel button is clicked.
 */
function ConfirmAlert({
    showAlert,
    title,
    mainText,
    confirmText,
    cancelText,
    onConfirm,
    onCancel,
}) {
    const [alert, setAlert] = useState('warning')

    const onConfirmAlert = () => {
        onConfirm()
        setAlert('confirmed')
    }

    const onCanceledAlert = () => {
        onCancel()
        setAlert('warning')
    }

    if (showAlert) {
        if (alert === 'warning') {
            return (
                <ReactBSAlert
                    warning
                    style={{display: 'block', marginTop: '-100px'}}
                    title={title}
                    onConfirm={() => onConfirmAlert()}
                    onCancel={() => onCanceledAlert()}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText={confirmText}
                    cancelBtnText={cancelText}
                    showCancel>
                    {mainText}
                </ReactBSAlert>
            )
        }

        if (alert === 'confirmed') {
            return (
                <ReactBSAlert
                    success
                    style={{display: 'block', marginTop: '-100px'}}
                    title="Success"
                    onConfirm={() => setAlert('warning')}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok">
                    {mainText}
                </ReactBSAlert>
            )
        }
    }

    return null
}

ConfirmAlert.propTypes = {
    showAlert: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    mainText: PropTypes.string.isRequired,
    confirmText: PropTypes.string.isRequired,
    cancelText: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

export default ConfirmAlert
