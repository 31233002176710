import {
    useGetUserQuery,
    useDeleteUserMutation,
    setMessage,
    useUpdateUserMutation,
} from 'store/store'
import {React} from 'common'
import {
    useEffect,
    useHistory,
    useState,
    useParams,
    createRef,
    useDispatch,
    useCallback,
} from 'services/hooks'
import {SweetAlert} from 'components/general/Display'
import {Page, PageContent, DetailsWrapper} from 'components/general/Page'
import {UserDetails} from 'components/views/User'
import {userRoleOptions} from 'constant/user'
import {
    getSuccessSaveMessageData,
    getUserRole,
    showAlertMessage,
} from 'services/utils'

const User = () => {
    const history = useHistory()
    const params = useParams()
    const alertRef = createRef()
    const dispatch = useDispatch()

    const userId = params.id
    const validate = params?.id === 'new'

    const userFromServer = useGetUserQuery(userId, {
        skip: validate,
        refetchOnMountOrArgChange: true,
    })
    const [deleteUser, {isLoading: isDeletingUser, isSuccess, isError}] =
        useDeleteUserMutation()

    const [
        updateUser,
        {
            // isLoading: isSavingUser,
            isSuccess: savingSucces,
            isError: savingError,
        },
    ] = useUpdateUserMutation()

    // const user = userFromServer?.data

    const user = history?.location?.state

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [dob, setDob] = useState('')
    const [gender, setGender] = useState('')
    const [height, setHeight] = useState('')
    const [weight, setWeight] = useState('')
    const [id, setId] = useState('')
    const [active, setActive] = useState('')
    const [role, setRole] = useState(userRoleOptions[0].value)

    useEffect(() => {
        if (user) {
            const {
                name,
                email,
                birthday,
                gender,
                height,
                weight,
                id: userId,
                active,
                role: userRole,
            } = user
            console.log(user, 'GOT user')
            setRole(getUserRole(userRole)['value'])
            setName(name)
            setEmail(email)
            setDob(new Date(Number(birthday) || 0).toLocaleDateString('en-GB'))
            setGender(Number(gender) ? 'Male' : 'Female')
            setHeight(height)
            setWeight(weight)
            setId(userId)
            setActive(active)
        }
    }, [user])

    const setValidationMessage = useCallback(
        (message, type) => {
            dispatch(
                setMessage({
                    message,
                    type: type === 'fail' ? 'alert-danger' : 'alert-success',
                })
            )
        },
        [dispatch]
    )

    const deactivateUser = (userId) => {
        deleteUser(userId)
    }

    useEffect(() => {
        if (isSuccess) {
            setValidationMessage(name + ' has been deleted!', 'success')
            return history.push('/admin/users')
        }
        if (isError) {
            setValidationMessage(
                'Something went wrong saving ' + name + ', try again',
                'fail'
            )
        }
    }, [
        isDeletingUser,
        history,
        isError,
        isSuccess,
        name,
        setValidationMessage,
    ])

    useEffect(() => {
        if (savingSucces || savingError || name) {
            const {message, type} = getSuccessSaveMessageData(name)
            showAlertMessage(message, type, dispatch)
        }
    }, [dispatch, savingError, savingSucces, name])

    const onSuccessCopyUserId = () => {
        showAlertMessage('User ID has been copied', 'alert-success', dispatch)
    }

    const saveUser = () => {
        if ((!role && role !== 0) || role.value === 1) {
            return
        }
        try {
            updateUser({user: {...user, role}})
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <Page>
            <PageContent>
                <DetailsWrapper
                    isLoading={userFromServer.isLoading}
                    header="Edit Profile"
                    backButtonText="Back to Users"
                    saveButtonText="Save"
                    saveButtonAction={saveUser}
                    disabledSaveButton={(!role && role !== 0) || role === 1}>
                    <UserDetails
                        name={name}
                        email={email}
                        dob={dob}
                        gender={gender}
                        height={height}
                        weight={weight}
                        id={userId}
                        active={active}
                        role={role}
                        setRole={setRole}
                        onCopyUserId={onSuccessCopyUserId}
                    />
                    <SweetAlert
                        title={'Are you sure?'}
                        confirmBtnText="Delete user"
                        mainText="User"
                        action="deleted"
                        ref={alertRef}
                        deactivate={deactivateUser}
                        item={'User'}
                        redirect="users"
                        id={id}
                    />
                </DetailsWrapper>
            </PageContent>
        </Page>
    )
}

export default User
