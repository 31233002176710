import bandAngle from './BandAngle'
import singleBand from './SingleBand'
import primaryPlane from './PrimaryPlane'
import gripSelection from './GripSelection'
import segmentMotion from './SegmentMotion'
import anchorLocation from './AnchorLocation'
import anchorPosition from './AnchorPosition'
import exerciseAction from './ExerciseAction'
import exercisePurpose from './ExercisePurpose'
import movementPattern from './MovementPattern'
import postureSelection from './PostureSelection'
import contractionFocus from './ContractionFocus'
import adjusterPosition from './AdjusterPosition'
import exerciseEquipment from './ExerciseEquipment'
import imageUpload from '../../Details/ImageUpload'
import caloriesMETcategory from './CaloriesMETcategory'
import orientationToAnchor from './OrientationToAnchor'
import barContent from './ContentByDeviceType/BarContent'
import controlButtons from '../../Details/ControlButtons'
import alternateRepetitions from './AlternateRepetitions'
import muscleGroupSelection from './MuscleGroupSelection'
import ropeContent from './ContentByDeviceType/RopeContent'
import gear1Content from './ContentByDeviceType/Gear1Content'
import trainerGenderSelection from './TrainerGenderSelection'
import gearGoContent from './ContentByDeviceType/GearGoContent'
import gearXContent from './ContentByDeviceType/GearXContent'
import noBandsContent from './ContentByDeviceType/NoBandsContent'
import motionAnalysisContent from './ContentByDeviceType/MotionAnalysisContent'
import activityGearX from './ActivityGearX'

export const BandAngle = bandAngle
export const ActivityGearX = activityGearX
export const BarContent = barContent
export const SingleBand = singleBand
export const RopeContent = ropeContent
export const ImageUpload = imageUpload
export const Gear1Content = gear1Content
export const PrimaryPlane = primaryPlane
export const GripSelection = gripSelection
export const GearGoContent = gearGoContent
export const GearXContent = gearXContent
export const SegmentMotion = segmentMotion
export const NoBandsContent = noBandsContent
export const ControlButtons = controlButtons
export const AnchorPosition = anchorPosition
export const ExerciseAction = exerciseAction
export const AnchorLocation = anchorLocation
export const ExercisePurpose = exercisePurpose
export const MovementPattern = movementPattern
export const AdjusterPosition = adjusterPosition
export const ContractionFocus = contractionFocus
export const PostureSelection = postureSelection
export const ExerciseEquipment = exerciseEquipment
export const OrientationToAnchor = orientationToAnchor
export const CaloriesMETcategory = caloriesMETcategory
export const AlternateRepetitions = alternateRepetitions
export const MuscleGroupSelection = muscleGroupSelection
export const MotionAnalysisContent = motionAnalysisContent
export const TrainerGenderSelection = trainerGenderSelection
