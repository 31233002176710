import {React, styled} from 'common'
import {FormGroup, Col, Row} from 'reactstrap'
import DatePickerInput from './DatePickerInput'
import SingleCheckbox from './SingleCheckbox'

const TimeframeInput = ({
    timelimit,
    startDateValue,
    endDateValue,
    setterTimelimit,
    setterStartDateValue,
    setterEndDateValue,
    disabled,
    xl = 4,
    lg = 4,
    md = 4,
    sm = 4,
}) => {
    return (
        <Col xl={xl} lg={lg} md={md} sm={sm}>
            <FormGroup>
                <StyledRow>
                    <SingleCheckbox
                        option={timelimit}
                        onChange={setterTimelimit}
                        disabled={disabled}
                    />
                </StyledRow>
                <Row>
                    <DatePickerInput
                        name={'Start Date'}
                        placeholder={'Start Date'}
                        value={startDateValue}
                        minDate={Date.now()}
                        setter={setterStartDateValue}
                        disabled={!timelimit.value}
                    />
                    <DatePickerInput
                        name={'End Date'}
                        placeholder={'End Date'}
                        value={endDateValue}
                        minDate={startDateValue}
                        setter={setterEndDateValue}
                        disabled={!timelimit.value}
                    />
                </Row>
            </FormGroup>
        </Col>
    )
}

export default TimeframeInput

const StyledRow = styled(Row)`
    /* background: red; */
    padding: 0 0.7rem;
    :disabled {
        color: ${({color}) => (color ? 'white' : 'inherit')};
        cursor: not-allowed;
    }
`
