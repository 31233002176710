import {React} from 'common'
import {Page} from 'components/general/Page'
import api from 'services/api'

import {useSaveWorkoutMutation, useGetPaginationWorkoutQuery} from 'store/store'
import {
    useMemo,
    useOnSaveCSV,
    useState,
    useEffect,
    useUrlParams,
} from 'services/hooks'
import {SweetAlert} from 'components/general/Display'
import {Col} from 'components/general/Control'
import {
    TableWrapperV2,
    TableLinkV2,
    SelectColumnFilterV2,
} from 'components/general/Display/TableV2'
import _ from 'lodash'
import {useCallback} from 'react'
import {skipToken} from '@reduxjs/toolkit/dist/query'

const columns = [
    {
        Header: 'Name',
        accessor: 'name',
        filter: 'default',
        isSortableIcon: true,
    },
    {
        Header: 'Level',
        accessor: 'level',
        Filter: SelectColumnFilterV2,
        isSortableIcon: true,
        filterOptions: ['All', 1, 2, 3],
    },
    {
        Header: 'Copy',
        accessor: 'copy',
        maxWidth: 70,
        isDownloadable: true,
    },
    {
        Header: 'Active',
        accessor: 'activeIcon',
        Filter: SelectColumnFilterV2,
        filterOptions: ['All', 'true', 'false'],
    },
    {
        Header: 'New Version',
        accessor: 'newVersion',
        Filter: SelectColumnFilterV2,
        filterOptions: ['All', 'true', 'false'],
    },
]

const Workouts = () => {
    const [saveWorkout] = useSaveWorkoutMutation()

    const [urlParams] = useUrlParams()
    const {
        page_number = 0,
        page_size = 10,
        activeIcon = 'All',
        newVersion = 'All',
        sortBy = 'name',
        sortType = 'asc',
        name: keywords = '',
    } = urlParams
    let level = urlParams?.level !== 'All' ? urlParams.level : ''

    const [shouldFetch, setShouldFetch] = useState(true)
    const newWorkouts = useGetPaginationWorkoutQuery(
        shouldFetch
            ? {
                  page: page_number,
                  limit: page_size,
                  active: activeIcon,
                  newVersion: newVersion,
                  sortBy: sortBy,
                  sortType: sortType,
                  keywords: keywords,
                  level: level,
              }
            : skipToken
    )

    const [isLoading, onSaveCSV] = useOnSaveCSV()
    const [workoutToCopy, setWorkoutToCopy] = useState(null)
    const alertRef = React.createRef()

    const onCopy = (workout) => {
        let newEx = {
            ...workout,
            id: null,
            active: false,
            deleted: false,
            name: workout.name?.trim() + ' - Copy',
        }
        setWorkoutToCopy(newEx)
    }

    const saveWorkoutAndRefetch = async (workout) => {
        await saveWorkout(workout)
        newWorkouts.refetch()
    }

    const createWorkoutCopy = async () => {
        const stringdWorkout = {}
        Object.keys(workoutToCopy).forEach((key) => {
            const value = workoutToCopy[key]
            const stringdValue =
                typeof value === 'string' ? value : JSON.stringify(value)
            stringdWorkout[key] = stringdValue
        })

        saveWorkoutAndRefetch({
            workout: stringdWorkout,
            id: null,
        })

        return setWorkoutToCopy(null)
    }

    const dataNewWorkouts = useMemo(() => {
        return newWorkouts?.data?.data?.map((prop, key) => ({
            id: key,
            name: prop.name,
            _id: prop.id,
            pathname: `/admin/workout/${prop.id}`,
            level: String(prop.level),
            copy: (
                <TableLinkV2
                    color="green"
                    icon={'tim-icons icon-single-copy-04'}
                    onClick={() => onCopy(prop)}
                    text="Copy"
                />
            ),
            muscles: prop.muscles,
            active: prop.active,
            activeIcon: prop.active ? 'true' : 'false',
            newVersion: prop.version > 1 ? 'true' : 'false',
        }))
    }, [newWorkouts?.data?.data])

    useEffect(() => {
        workoutToCopy && alertRef?.current()
    }, [workoutToCopy, alertRef])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedRefetch = useCallback(
        _.debounce(() => {
            setShouldFetch(true)
            newWorkouts.refetch()
        }, 500),
        []
    )

    useEffect(() => {
        if (keywords) {
            setShouldFetch(false)
            debouncedRefetch()
        }
        return () => {
            debouncedRefetch.cancel()
        }
    }, [debouncedRefetch, keywords])

    return (
        <Page hideSidbar={false}>
            <TableWrapperV2
                title="Workouts"
                isFetching={isLoading}
                onSaveCSV={() => onSaveCSV(api.getWorkoutsCSV(), 'workouts')}
                createNew={'/admin/workout/new'}
                data={dataNewWorkouts}
                metadata={newWorkouts?.data?.metadata}
                columns={columns}
            />
            <Col>
                <SweetAlert
                    ref={alertRef}
                    title={'Copy Workout'}
                    mainText={workoutToCopy?.name}
                    action="created"
                    deactivate={createWorkoutCopy}
                    confirmBtnText="Copy Workout"
                    id={workoutToCopy?.id}
                />
            </Col>
        </Page>
    )
}

export default Workouts
