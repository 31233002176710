import I18n from 'i18n-js'
import en from './locales/en'
import he from './locales/he'

let activeLanguage = localStorage.getItem('language')
if (!activeLanguage) {
    localStorage.setItem('language', 'he')
    activeLanguage = 'he'
}
I18n.locale = activeLanguage
I18n.fallbacks = true
I18n.translations = {
    he,
    en,
}
export const setLocale = (lang) => {
    if (I18n.locale === lang) return
    I18n.locale = lang
    if (lang === 'he') {
        localStorage.setItem('language', 'he')
    } else {
        localStorage.setItem('language', 'en')
    }
    // window.location.reload(false);
}
export default I18n
