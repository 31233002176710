/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
import React, {useEffect, useRef, useState} from 'react'
import * as echarts from 'echarts'
import Headers from 'components/reportWeekly/Headers'
import WeeklyActiveRate from 'components/reportWeekly/WeeklyActiveRate'
import PlanStageDistribution from '../components/reportWeekly/PlanStageDistribution'
import PlanGoalDescription from 'components/reportWeekly/PlanGoalDescription'
import RetentionRates from 'components/reportWeekly/RetentionRates'
import RetentionTrend from 'components/reportWeekly/RetentionTrend'
import moment from 'moment'
import {jsPDF} from 'jspdf'
import html2canvas from 'html2canvas'
import 'assets/css/pageA4.css'
import {useLocation} from 'services/hooks'
import DownloadPDFComp from 'components/reportWeekly/DownloadPDFComp'

const ReportWeekly = () => {
    const [timeDownload, setTimeDownload] = useState(false)
    const location = useLocation()
    const echartsDom = useRef(null)
    const pdfRef = useRef(null)
    const pdfRef2 = useRef(null)
    const pdfRef3 = useRef(null)
    const pdfRef4 = useRef(null)
    const pdfRef5 = useRef(null)
    const dataReportPDF = location.state
    const isStage =
        dataReportPDF.plan_stage_distribution.total_participant &&
        dataReportPDF.plan_stage_distribution.total_participant.length >= 5
    const isStageMoreThanTwelve =
        dataReportPDF.plan_stage_distribution.total_participant &&
        dataReportPDF.plan_stage_distribution.total_participant.length > 12
    // Default export is a4 paper, portrait, using millimeters for units

    const dataList = [
        {
            id: 1,
            color: 'rgba(29, 73, 167, 1)',
            description: 'Joined so far',
            title: 'Subscribers',
            percentage: dataReportPDF.weekly_active_rate.subscriber_percentage,
            total: dataReportPDF.weekly_active_rate.subscriber,
            heightBar: dataReportPDF.weekly_active_rate.subscriber_percentage,
        },
        {
            id: 2,
            title: 'Participants',
            color: 'rgba(89, 112, 246, 1)',
            description: 'Finished at least 1 workout',
            percentage: dataReportPDF.weekly_active_rate.participant_percentage,
            total: dataReportPDF.weekly_active_rate.participant,
            heightBar: dataReportPDF.weekly_active_rate.participant_percentage,
        },
        {
            id: 3,
            title: 'Active Users',
            color: 'rgba(95, 180, 249, 1)',
            description: 'Finished at least 1 weekly goal(s)',
            percentage: dataReportPDF.weekly_active_rate.active_user_percentage,
            total: dataReportPDF.weekly_active_rate.active_user,
            heightBar: dataReportPDF.weekly_active_rate.active_user_percentage,
        },
        {
            id: 4,
            title: 'Top Performers',
            color: 'rgba(130, 222, 228, 1)',
            description: 'Finished all weekly goals',
            percentage:
                dataReportPDF.weekly_active_rate.top_performer_percentage,
            total: dataReportPDF.weekly_active_rate.top_performer,
            heightBar:
                dataReportPDF.weekly_active_rate.top_performer_percentage,
        },
        {
            id: 5,
            title: 'Overachievers',
            color: 'rgba(66, 149, 175, 1)',
            description:
                'Exceeded weekly goals by at least 1 workout of any type',
            percentage:
                dataReportPDF.weekly_active_rate.overachiever_percentage,
            total: dataReportPDF.weekly_active_rate.overachiever,
            heightBar: dataReportPDF.weekly_active_rate.overachiever_percentage,
        },
    ]

    useEffect(() => {
        const myChart = echarts.init(echartsDom.current)

        let option = {
            title: {
                text: 'Participants',
                subtext: '',
                textStyle: {
                    fontFamily: 'Hanken Grotesk',
                    fontWeight: '400',
                    fontSize: 13,
                },
            },
            style: {
                stroke: 'blue',
                lineWidth: 4,
                lineDash: [2], // put dash, gap values here
            },
            xAxis: {
                type: 'category',
                data:
                    dataReportPDF.plan_stage_distribution.total_participant &&
                    dataReportPDF.plan_stage_distribution.total_participant
                        .length > 0
                        ? dataReportPDF.plan_stage_distribution.total_participant.map(
                              (item) => item.stage
                          )
                        : [],
                axisLine: {
                    lineStyle: {
                        color: '#333',
                        width: 3,
                    },
                },
            },
            yAxis: {
                type: 'value',
            },
            series: [
                {
                    data:
                        dataReportPDF.plan_stage_distribution
                            .total_participant &&
                        dataReportPDF.plan_stage_distribution.total_participant
                            .length > 0
                            ? dataReportPDF.plan_stage_distribution.total_participant.map(
                                  (item) => {
                                      return {
                                          value: item.participant,
                                          itemStyle: {
                                              color: 'rgba(140, 226, 230, 1)',
                                          },
                                      }
                                  }
                              )
                            : [],
                    type: 'bar',
                    barMaxWidth: 25,
                    label: {
                        show: true,
                        position: 'top',
                    },
                },
            ],
        }

        // 绘制图表
        myChart.setOption(option)
        myChart.on('brushSelected', function (params) {
            //缩放时触发函数
            var brushed = []
            var brushComponent = params.batch[0]

            for (var sIdx = 0; sIdx < brushComponent.selected.length; sIdx++) {
                var rawIndices = brushComponent.selected[sIdx].dataIndex
                brushed.push(rawIndices)
            }
            console.log('params', brushed.join(','))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        saveDownloadPDF()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const saveDownloadPDF = () => {
        setTimeDownload(true)
        setTimeout(() => {
            onGeneratePDF()
        }, 2000)
    }

    const onGeneratePDF = async () => {
        const content = pdfRef.current
        const contentPage2 = pdfRef2.current
        const contentPage3 = pdfRef3.current

        try {
            const optionCanvas = {
                scale: 2,
                useCORS: true,
                allowTaint: true,
                height: window.outerHeight + window.innerHeight,
                windowHeight: window.outerHeight + window.innerHeight,
                scrollY: -window.scrollY,
            }
            const canvas = await html2canvas(content, optionCanvas)
            const canvas2 = await html2canvas(contentPage2, optionCanvas)
            const canvas3 = await html2canvas(contentPage3, optionCanvas)

            const imgData = canvas.toDataURL('image/png')
            const imgData2 = canvas2.toDataURL('image/png')
            const imgData3 = canvas3.toDataURL('image/png')

            const pdf = new jsPDF({
                orientation: 'p',
                unit: 'px',
                format: 'a4',
                compress: true,
            })
            const margin = 10

            // const pdfHeight = pdf.internal.pageSize.getHeight()

            const pdfWidth = pdf.internal.pageSize.getWidth()
            // const height = (canvas.height * pdfWidth) / canvas.width
            // let heightLeft = height
            const imgWidth = canvas.width
            const imgHeight = canvas.height

            const imgWidth2 = canvas2.width
            const imgHeight2 = canvas2.height

            const imgWidth3 = canvas3.width
            const imgHeight3 = canvas3.height

            // let position = 0

            pdf.addImage(
                imgData,
                'PNG',
                margin,
                margin,
                pdfWidth - margin * 2,
                (imgHeight * (pdfWidth - margin * 2)) / imgWidth
            )

            pdf.addPage()

            pdf.addImage(
                imgData2,
                'PNG',
                margin,
                margin,
                pdfWidth - margin * 2,
                (imgHeight2 * (pdfWidth - margin * 3)) / imgWidth2
            )

            if (isStage) {
                const contentPage4 = pdfRef4.current
                const canvas4 = await html2canvas(contentPage4, optionCanvas)
                const imgData4 = canvas4.toDataURL('image/png')
                const imgWidth4 = canvas4.width
                const imgHeight4 = canvas4.height

                if (isStageMoreThanTwelve) {
                    const contentPage5 = pdfRef5.current
                    const canvas5 = await html2canvas(
                        contentPage5,
                        optionCanvas
                    )
                    const imgData5 = canvas5.toDataURL('image/png')
                    const imgWidth5 = canvas5.width
                    const imgHeight5 = canvas5.height
                    pdf.addPage()
                    pdf.addImage(
                        imgData5,
                        'PNG',
                        margin,
                        margin,
                        pdfWidth - margin * 2,
                        (imgHeight5 * (pdfWidth - margin * 2)) / imgWidth5
                    )
                }

                pdf.addPage()

                pdf.addImage(
                    imgData4,
                    'PNG',
                    margin,
                    margin,
                    pdfWidth - margin * 2,
                    (imgHeight4 * (pdfWidth - margin * 2)) / imgWidth4
                )
            }

            pdf.addPage()

            pdf.addImage(
                imgData3,
                'PNG',
                margin,
                margin,
                pdfWidth - margin * 2,
                (imgHeight3 * (pdfWidth - margin * 2)) / imgWidth3
            )

            pdf.save(
                `${
                    location.state.name ? `${location.state.name}_` : ''
                }report_weekly_${moment().toISOString()}.pdf`
            )
            setTimeDownload(false)
        } catch (error) {
            setTimeDownload(false)
            console.log('cannot generate pdf', error)
        }
    }

    return (
        <div>
            <DownloadPDFComp
                disabled={timeDownload}
                loading={timeDownload}
                onClick={saveDownloadPDF}
            />
            <div className="page">
                <div className="subpage" ref={pdfRef}>
                    <div className="bg-white text-dark mt-2 rounded p-4">
                        <Headers
                            dateTitle={`${moment(
                                dataReportPDF.retention_trend[
                                    dataReportPDF.retention_trend.length - 1
                                ].start
                            ).format('DD/MM/YY')} - ${moment(
                                dataReportPDF.retention_trend[
                                    dataReportPDF.retention_trend.length - 1
                                ].end
                            ).format('DD/MM/YY')}`}
                            pageTitle={
                                isStageMoreThanTwelve
                                    ? 'Page 1 out of 5'
                                    : isStage
                                    ? 'Page 1 out of 4'
                                    : 'Page 1 out of 3'
                            }
                        />

                        <WeeklyActiveRate dataList={dataList} />

                        <PlanStageDistribution
                            echartsDom={echartsDom}
                            totalParticipant={dataReportPDF.plan_stage_distribution.total_participant.reduce(
                                (prev, currentValue) =>
                                    prev + currentValue.participant,
                                0
                            )}
                            totalStage={
                                dataReportPDF.plan_stage_distribution
                                    .total_participant.length
                            }
                        />
                    </div>
                </div>
            </div>

            <div className="page">
                <div className="subpage" ref={pdfRef2}>
                    <div className="bg-white text-dark mt-2 rounded p-2">
                        <Headers
                            dateTitle={`${moment(
                                dataReportPDF.retention_trend[
                                    dataReportPDF.retention_trend.length - 1
                                ].start
                            ).format('DD/MM/YY')} - ${moment(
                                dataReportPDF.retention_trend[
                                    dataReportPDF.retention_trend.length - 1
                                ].end
                            ).format('DD/MM/YY')}`}
                            pageTitle={
                                isStageMoreThanTwelve
                                    ? 'Page 2 out of 5'
                                    : isStage
                                    ? 'Page 2 out of 4'
                                    : 'Page 2 out of 3'
                            }
                        />

                        <PlanGoalDescription
                            StagePlanData={
                                isStageMoreThanTwelve
                                    ? dataReportPDF.plan_stage_distribution.total_participant.slice(
                                          0,
                                          12
                                      )
                                    : dataReportPDF.plan_stage_distribution
                                          .total_participant
                            }
                        />

                        {isStage ? null : (
                            <RetentionRates
                                one_workout={
                                    dataReportPDF.retention_rate.one_workout
                                }
                                all_workout={
                                    dataReportPDF.retention_rate.all_workout
                                }
                                exceed_goals={
                                    dataReportPDF.retention_rate.exceed_goals
                                }
                            />
                        )}
                    </div>
                </div>
            </div>

            {isStageMoreThanTwelve ? (
                <div className="page">
                    <div className="subpage" ref={pdfRef5}>
                        <div className="bg-white text-dark mt-2 rounded p-4">
                            <Headers
                                dateTitle={`${moment(
                                    dataReportPDF.retention_trend[
                                        dataReportPDF.retention_trend.length - 1
                                    ].start
                                ).format('DD/MM/YY')} - ${moment(
                                    dataReportPDF.retention_trend[
                                        dataReportPDF.retention_trend.length - 1
                                    ].end
                                ).format('DD/MM/YY')}`}
                                pageTitle={'Page 3 out of 5'}
                            />

                            <PlanGoalDescription
                                StagePlanData={
                                    isStageMoreThanTwelve
                                        ? dataReportPDF.plan_stage_distribution.total_participant.slice(
                                              12,
                                              dataReportPDF
                                                  .plan_stage_distribution
                                                  .total_participant.length
                                          )
                                        : dataReportPDF.plan_stage_distribution
                                              .total_participant
                                }
                            />
                        </div>
                    </div>
                </div>
            ) : null}

            {isStage ? (
                <>
                    <div className="page">
                        <div className="subpage" ref={pdfRef4}>
                            <div className="bg-white text-dark mt-2 rounded p-4">
                                <Headers
                                    dateTitle={`${moment(
                                        dataReportPDF.retention_trend[
                                            dataReportPDF.retention_trend
                                                .length - 1
                                        ].start
                                    ).format('DD/MM/YY')} - ${moment(
                                        dataReportPDF.retention_trend[
                                            dataReportPDF.retention_trend
                                                .length - 1
                                        ].end
                                    ).format('DD/MM/YY')}`}
                                    pageTitle={
                                        isStageMoreThanTwelve
                                            ? 'Page 4 out of 5'
                                            : 'Page 3 out of 4'
                                    }
                                />
                                <RetentionRates
                                    one_workout={
                                        dataReportPDF.retention_rate.one_workout
                                    }
                                    all_workout={
                                        dataReportPDF.retention_rate.all_workout
                                    }
                                    exceed_goals={
                                        dataReportPDF.retention_rate
                                            .exceed_goals
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </>
            ) : null}

            <div className="page">
                <div className="subpage" ref={pdfRef3}>
                    <div className="bg-white text-dark mt-2 rounded p-4">
                        <Headers
                            dateTitle={`${moment(
                                dataReportPDF.retention_trend[
                                    dataReportPDF.retention_trend.length - 1
                                ].start
                            ).format('DD/MM/YY')} - ${moment(
                                dataReportPDF.retention_trend[
                                    dataReportPDF.retention_trend.length - 1
                                ].end
                            ).format('DD/MM/YY')}`}
                            pageTitle={
                                isStageMoreThanTwelve
                                    ? 'Page 5 out of 5'
                                    : isStage
                                    ? 'Page 4 out of 4'
                                    : 'Page 3 out of 3'
                            }
                        />
                        <RetentionTrend
                            retention_trend={dataReportPDF.retention_trend}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportWeekly
