import {React, styled} from 'common'
import {Form, Input, Button} from 'components/general/Control'

const LoginCardBody = ({value, setter, buttons, type}) => {
    return (
        <LoginCardBodyContainer>
            <Input
                type={type === 'email' ? 'email' : 'text'}
                name={type === 'email' ? 'email' : 'otp'}
                id={type === 'email' ? 'email' : 'otp'}
                placeholder={type === 'email' ? 'Email' : 'Otp'}
                value={value}
                setter={setter}
            />
            <ButtonsContainer>
                {buttons.map((button, idx) => {
                    const disabled = button.disable
                    return (
                        <Form
                            key={idx}
                            onSubmit={(ev) => {
                                ev.preventDefault()
                                return button.onClickAction(value)
                            }}>
                            <StyledButton
                                className={'btn btn-success'}
                                key={idx}
                                children={button.text}
                                disabled={disabled}
                                id={button.id}
                            />
                        </Form>
                    )
                })}
            </ButtonsContainer>
        </LoginCardBodyContainer>
    )
}

export default LoginCardBody

const LoginCardBodyContainer = styled.div`
    > * {
        width: 85%;
    }
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`
const StyledButton = styled(Button)`
    width: 100%;
`
const ButtonsContainer = styled.div``
