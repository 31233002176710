import FormCheckLabel from 'react-bootstrap/esm/FormCheckLabel'
import {FormGroup, Input} from 'reactstrap'
import {useMemo, useCallback} from 'services/hooks'
import styled from 'styled-components'

const formattedName = {
    normal: 'gear 1',
    no_bands: 'body weight',
    gearGo: 'gear Go',
    gearX: 'gear X',
}

const CheckboxOption = ({
    label,
    child,
    onSelect,
    isSelected,
    selectedItems,
}) => {
    const itemName = useMemo(() => {
        if (formattedName[child]) {
            return formattedName[child]
        } else if (typeof child === 'string') {
            return child.replaceAll('_', ' ')
        }
        return child
    }, [child])

    const removeChildFromLabel = useCallback(() => {
        const newChildrenList = selectedItems[label].filter((c) => c !== child)
        let newSelectedItems = {...selectedItems}
        if (newChildrenList.length === 0) {
            delete newSelectedItems[label]
        } else {
            newSelectedItems[label] = newChildrenList
        }
        return newSelectedItems
    }, [child, label, selectedItems])

    const addChildToLabel = useCallback(() => {
        const currentLableList = selectedItems[label] || []
        return {
            ...selectedItems,
            [label]: [...currentLableList, child],
        }
    }, [child, label, selectedItems])

    const handleChildChange = (ev) => {
        ev.stopPropagation()
        const newItems = isSelected ? removeChildFromLabel() : addChildToLabel()
        onSelect && onSelect(newItems)
    }

    return (
        <FormGroup
            check
            data-type="filter-drop-down"
            className="custom-checkbox custom-control pl-4">
            <FormCheckLabel data-type="filter-drop-down">
                <Input
                    data-type="filter-drop-down"
                    style={{cursor: 'pointer'}}
                    className="custom-control-input"
                    type="checkbox"
                    checked={Boolean(isSelected)}
                    onChange={handleChildChange}
                />
                <StyledName
                    data-type="filter-drop-down"
                    className="form-check-sign">
                    {itemName}
                </StyledName>
            </FormCheckLabel>
        </FormGroup>
    )
}

export default CheckboxOption

const StyledName = styled.p`
    text-transform: capitalize;
`
