const Images = {
    LOGIN_HEADER_IMAGE: require('assets/images/hygearFitSmall.jpg').default,
    ARROW_LEFT: require('assets/images/arrow_left_gray.png').default,
    deviceIcons: {
        normal_white: require('assets/images/gears_white.png').default,
        normal_black: require('assets/images/gears_black.png').default,
        rope_white: require('assets/images/skipping_rope_white.png').default,
        rope_black: require('assets/images/skipping_rope_black.png').default,
        bar_white: require('assets/images/hybar_white.png').default,
        bar_black: require('assets/images/hybar_black.png').default,
        gearGo_white: require('assets/images/gear_go_white.png').default,
        gearGo_black: require('assets/images/gear_go_black.png').default,
    },
}
export default Images
