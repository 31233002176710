import {React, styled} from 'common'
import {PageContent} from 'components/general/Page'
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
} from 'components/general/Control'
import Spinner from 'components/general/Display/Spinner'
import TableWrapperButtonV2 from './TableWrapperButton'
import TableV2 from './Table'

const TableWrapperV2 = ({
    title,
    isError,
    isFetching,
    onSaveCSV,
    createNew,
    cerateNewText,
    data,
    metadata,
    columns,
}) => {
    return (
        <PageContent>
            <Card>
                <StyledCardHeader>
                    <Col>
                        <CardTitle className="h4">{title}</CardTitle>
                    </Col>
                    {!isFetching && (
                        <TableWrapperButtonV2
                            onSaveCSV={onSaveCSV}
                            createNew={createNew}
                            cerateNewText={cerateNewText}
                        />
                    )}
                </StyledCardHeader>

                <CardBody>
                    {!isFetching && data ? (
                        <TableV2
                            isError={isError}
                            data={data}
                            columns={columns}
                            metadata={metadata}
                        />
                    ) : (
                        <Spinner />
                    )}
                </CardBody>
            </Card>
        </PageContent>
    )
}

const StyledCardHeader = styled(CardHeader)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export default TableWrapperV2
