import {MOTION_ANALYSIS_KEYS, userPositionTypes} from 'constant/exercise'
import {Col, FormGroup, Row} from 'components/general/Control'
import SelectInput from 'components/general/Control/SelectInput'
import RadioButtonsInput from 'components/general/Control/RadioButtonsInput'

const motionCategoriesOptions = MOTION_ANALYSIS_KEYS

const MotionAnalysisContent = ({
    showError,
    updatedExercise,
    updateExerciseField,
}) => {
    const {motion_category, user_position} = updatedExercise || {}

    return (
        <Row>
            <Col sm="12" md="5" lg="5" className="checkbox-radios py-2">
                <RadioButtonsInput
                    required
                    showError={showError}
                    options={userPositionTypes}
                    label="User Position"
                    name="user_position"
                    selected={user_position}
                    onChange={(value) =>
                        updateExerciseField('user_position', value)
                    }
                />
            </Col>

            <Col sm="12" md="5" lg="5">
                <FormGroup>
                    <SelectInput
                        required
                        showError={showError}
                        label="Motion Category"
                        selected={motion_category}
                        options={motionCategoriesOptions}
                        onChange={(value) =>
                            updateExerciseField('motion_category', value)
                        }
                    />
                </FormGroup>
            </Col>
        </Row>
    )
}

export default MotionAnalysisContent
