import styled from 'styled-components'
import DefaultVariablesInput from 'components/Workout/Details/WorkoutBuilder/DefaultVariablesInput'
import {FormLabel} from 'react-bootstrap'

export const DEFAULT_VALUES = {
    target: 30,
    rest_time: 30,
    sets: 1,
    rounds: 1,
}

const DefaultVariablesForm = ({
    defaultWork,
    setDefaultWork,
    defaultRest,
    setDefaultRest,
    defaultSets,
    setDefaultSets,
}) => {
    const defaultVariables = [
        {
            type: 'Target',
            value: defaultWork,
            defaultNumber: DEFAULT_VALUES.target,
            setDefault: setDefaultWork,
        },
        {
            type: 'Rest',
            value: defaultRest,
            defaultNumber: DEFAULT_VALUES.rest_time,
            setDefault: setDefaultRest,
        },
        {
            type: 'Sets',
            value: defaultSets,
            defaultNumber: DEFAULT_VALUES.sets,
            setDefault: setDefaultSets,
        },
    ]

    return (
        <DefaultInputsConteinr className="d-flex mt-0 mb-1 justify-content-between align-items-center  flex-fill">
            <FormLabel className="ml-5">{'DEFAULT VALUES:'}</FormLabel>
            <Container className="d-flex">
                {defaultVariables.map((item, i) => (
                    <DefaultVariablesInput
                        key={('item_value', item.value, i)}
                        type={item.type}
                        value={item.value}
                        setDefault={item.setDefault}
                        defaultNumber={item.defaultNumber}
                    />
                ))}
            </Container>
        </DefaultInputsConteinr>
    )
}

export default DefaultVariablesForm

const Container = styled.div``
const DefaultInputsConteinr = styled.div`
    margin-right: 4.4rem;
`
