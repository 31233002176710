import {React, styled} from 'common'
import {Texts} from 'appearance'

import {Card, CardBody, CardTitle} from 'components/general/Control'
import ManufacturerFirmwaresVersions from './ManufacturerFirmwaresVersions'

const FirmwaresVersionsDisplay = ({versions}) => {
    const manufacturers = Object.keys(versions)
    return (
        <Card>
            <TitleWrapper>
                <Texts.Title>Current Firmwares</Texts.Title>
            </TitleWrapper>
            <CardContainer>
                {manufacturers.map((manufacturer) => (
                    <ManufacturerFirmwaresVersions
                        manufacturer={manufacturer}
                        versions={versions}
                        key={manufacturer}
                    />
                ))}
            </CardContainer>
        </Card>
    )
}

export default FirmwaresVersionsDisplay

const TitleWrapper = styled(CardTitle)`
    display: flex;
    justify-content: center;
    padding: 15px;
`

const CardContainer = styled(CardBody)`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`
