import {Col, Collapse, Row, FormGroup} from 'components/general/Control'
import moment from 'moment'

import AppInput from 'components/general/Control/Input'
import SingleCheckbox from 'components/general/Control/SingleCheckbox'
import ReactPlayer from 'react-player'
import {validateNumberInput} from 'services/utils'
import styled from 'styled-components'

const getFormattedDuration = (duration) =>
    duration ? moment(duration * 1000).format('mm:ss') : null

const VimeoInformation = ({
    xs = '11',
    sm = '8',
    md = '6',
    lg = '5',
    className = '',
    vimeoId = '',
    title = '',
    setVimeoId,
    mutedVideo,
    showError,
    setMuteVideo,
    duration,
    setDuration,
    video_url = '',
    setVideoUrl,
}) => {
    const handleID = (value) => {
        validateNumberInput(value) && setVimeoId(value)
    }
    console.log(vimeoId, video_url, '<<', typeof vimeoId)

    const formattedTime = getFormattedDuration(duration)

    const vimeoTitle = title || 'Vimeo ID Number'

    const renderPlayerVideo = () => {
        if (video_url) {
            return (
                <>
                    <Video
                        // playing
                        onDuration={(d) => setDuration && setDuration(d)}
                        loop
                        controls
                        height={200}
                        width={400}
                        url={video_url}
                    />
                </>
            )
        } else if (vimeoId) {
            return (
                <>
                    <Video
                        // playing
                        onDuration={(d) => setDuration && setDuration(d)}
                        loop
                        controls
                        height={200}
                        width={400}
                        config={{vimeo: {playerOptions: {id: vimeoId}}}}
                        url={`https://player.vimeo.com/video/${vimeoId}/config`}
                    />
                </>
            )
        }
    }

    return (
        <Container className={className}>
            {console.log(showError)}
            <CloudinaryVideoURL>
                <FormGroup
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        minWidth: 475,
                    }}>
                    <label>Cloudinary Video URL</label>
                    <AppInput
                        name="video_url"
                        placeholder="https://"
                        value={video_url}
                        setter={setVideoUrl}
                        disableAutoComplete
                    />
                </FormGroup>
            </CloudinaryVideoURL>
            <Row className="justify-content-center">
                <Col className="d-flex" xs={xs} sm={sm} md={md} lg={lg}>
                    <label>{vimeoTitle} </label>
                    <AppInput
                        type="text"
                        name="vimeo_id"
                        value={vimeoId}
                        setter={handleID}
                        placeholder="Enter vimeo ID"
                    />
                </Col>
            </Row>
            <Collapse isOpen={vimeoId || video_url}>
                <VideoContainer>{renderPlayerVideo()}</VideoContainer>
                {formattedTime && (
                    <Duration>
                        {'Duration: ' + (formattedTime || 'unknown')}
                    </Duration>
                )}
            </Collapse>
            {setMuteVideo && (
                <Col
                    sm={12}
                    md={12}
                    lg={12}
                    className="d-flex align-items-center justify-content-center">
                    <SingleCheckbox
                        onChange={setMuteVideo}
                        option={{
                            label: 'Mute video background audio (will be muted in the app)',
                            value: mutedVideo,
                        }}
                    />
                </Col>
            )}
        </Container>
    )
}

export default VimeoInformation

const Container = styled.div`
    min-width: 398px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const VideoContainer = styled(Row)`
    margin: 20px auto 5px;
    justify-content: center;
`

const Video = styled(ReactPlayer)`
    display: flex;
    border: 1px solid grey;
`

const Duration = styled.div`
    text-align: center;
`

const CloudinaryVideoURL = styled.div`
    display: flex;
    justify-content: center;
`
