import {Col, Label, Row} from 'components/general/Control'
import RadioButtonsInput from 'components/general/Control/RadioButtonsInput'
import {barModes} from 'constant/exercise'
import AnchorPosition from '../AnchorPosition'
import AnchorLocation from '../AnchorLocation'
import OrientationToAnchor from '../OrientationToAnchor'
import SegmentMotion from '../SegmentMotion'
import PrimaryPlane from '../PrimaryPlane'
import MovementPattern from '../MovementPattern'
import ExerciseAction from '../ExerciseAction'
import ContractionFocus from '../ContractionFocus'
import {useMemo} from 'services/hooks'

const BarContent = ({
    showError,
    updatedExercise,
    isPerformanceMode,
    updateExerciseField,
}) => {
    const {
        bar_mode,
        anchor_position_type,
        segment,
        movement_pattern,
        action,
        contraction_focus,
        anchor_orientation,
        primary_plane,
        anchor_location,
    } = updatedExercise || {}

    const barNote = useMemo(() => {
        if (bar_mode === 'strength') {
            return 'Sensor weight is multiplied by two in Strength mode'
        } else if (bar_mode === 'performance') {
            return 'Sensor weight is adjusted by a factor based on HYBAR Grip mode'
        }
        return null
    }, [bar_mode])

    return (
        <>
            <Row>
                <Col className="checkbox-radios py-2" sm="12" lg="8">
                    <RadioButtonsInput
                        required
                        showError={showError}
                        options={barModes}
                        label="HYBAR Mode"
                        name="bar_mode"
                        selected={bar_mode}
                        onChange={(value) =>
                            updateExerciseField('bar_mode', value)
                        }
                    />
                    <Label>{barNote}</Label>
                </Col>
            </Row>
            <AnchorPosition
                bar
                showError={showError}
                anchorPositionType={anchor_position_type}
                handleAnchorPositionType={(value) =>
                    updateExerciseField('anchor_position_type', value)
                }
            />
            <SegmentMotion
                segment={segment}
                setSegment={(value) => updateExerciseField('segment', value)}
                showError={showError}
            />
            <MovementPattern
                movementPattern={movement_pattern}
                setMovementPattern={(value) =>
                    updateExerciseField('movement_pattern', value)
                }
                showError={showError}
            />
            <ExerciseAction
                action={action}
                setAction={(value) => updateExerciseField('action', value)}
                showError={showError}
            />
            <ContractionFocus
                contractionFocus={contraction_focus}
                setContractionFocus={(value) =>
                    updateExerciseField('contraction_focus', value)
                }
                showError={showError}
            />
            <PrimaryPlane
                primaryPlane={primary_plane}
                setPrimaryPlane={(value) =>
                    updateExerciseField('primary_plane', value)
                }
                showError={showError}
            />
            {isPerformanceMode ? (
                <>
                    <OrientationToAnchor
                        anchorOrientation={anchor_orientation}
                        setAnchorOrientation={(value) =>
                            updateExerciseField('anchor_orientation', value)
                        }
                        showError={showError}
                    />
                </>
            ) : (
                <>
                    <AnchorLocation
                        anchorLocation={anchor_location}
                        setAnchorLocation={(value) =>
                            updateExerciseField('anchor_location', value)
                        }
                        showError={showError}
                    />
                </>
            )}
        </>
    )
}

export default BarContent
