import {React} from 'common'

import {LANGUAGES} from 'constant/languages'
import {checkValidFields} from 'services/utils'

import {
    useGetCatalogLabelQuery,
    setMessage,
    useSaveCatalogLabelMutation,
    useSaveCatalogLabelTranslationMutation,
    useDeleteCatalogLabelMutation,
    useGetWorkoutsQuery,
} from 'store/store'

import {Language, SweetAlert} from 'components/general/Display'
import {
    useEffect,
    useHistory,
    useState,
    useLocation,
    useParams,
    createRef,
    useDispatch,
    useCallback,
} from 'services/hooks'
import {DetailsInput, Row} from 'components/general/Control'
import {Page, PageContent, DetailsWrapper} from 'components/general/Page'
import {ImageLabelDisplayTypeSelect} from 'components/views/Lable'
import {SelectColumnFilter} from 'components/general/Display/Table'
import {useMemo} from 'react'
import FullWidthTableWrapper from 'components/general/Display/Table/FullWidthTableWrapper'

const DEFAULT_LANGUAGE = LANGUAGES[0].value

// eslint-disable-next-line complexity
const Label = () => {
    const history = useHistory()
    const location = useLocation()
    const params = useParams()
    const alertRef = createRef()
    const dispatch = useDispatch()

    const [language, setLanguage] = useState(DEFAULT_LANGUAGE)
    const [languageChanged, setLanguageChanged] = useState(false)
    const labelFromProps = location.label
    const labelId = params.id
    const validate = params?.id === 'new'

    const skip = validate || (!validate && labelFromProps && !languageChanged)

    const {data: workouts, isLoading: isWorkoutsLoading} = useGetWorkoutsQuery({
        tagId: labelId,
    })

    const labelFromServer = useGetCatalogLabelQuery(
        {id: labelId, language},
        {
            skip,
        }
    )

    const [
        saveCatalogLabel,
        {isSuccess: isSuccessSaving, isError: isErrorSaving},
    ] = useSaveCatalogLabelMutation()
    const [
        saveCatalogLabelTranslation,
        {
            isSuccess: isSuccessSavingTranslation,
            isError: isErrorSavingTranslation,
        },
    ] = useSaveCatalogLabelTranslationMutation()
    const [
        deleteCatalogLabel,
        {isSuccess: isSuccessDeleting, isError: isErrorDeleting},
    ] = useDeleteCatalogLabelMutation()

    const label = labelFromServer?.data || labelFromProps

    const [showError, setShowError] = useState(false)
    const [labelName, setLabelName] = useState('')
    const [displayType, setDisplayType] = useState(1)

    useEffect(() => {
        if (label) {
            const {display_type, name} = label

            name && setLabelName(name)
            typeof display_type === 'number' && setDisplayType(display_type)
        }
    }, [label])

    const setValidationMessage = useCallback(
        (message, type) => {
            dispatch(
                setMessage({
                    message,
                    type: type === 'fail' ? 'alert-danger' : 'alert-success',
                })
            )
        },
        [dispatch]
    )

    const saveTranslation = () => {
        if (!labelId) {
            setValidationMessage(
                `Error saving label translation before saving label`,
                'fail'
            )
            return
        }
        return saveCatalogLabelTranslation({
            translations: [
                {
                    field: 'name',
                    object_id: labelId,
                    language,
                    display_value: labelName,
                },
            ],
        })
    }

    const onSaveLabel = () => {
        if (language !== DEFAULT_LANGUAGE) {
            return saveTranslation()
        }
        const areFieldsValid = checkValidFields({labelName})
        if (!areFieldsValid) {
            setValidationMessage('Fields are not valid', 'fail')
            return setShowError(true)
        }
        saveCatalogLabel({
            name: labelName,
            display_type: displayType,
            _id: labelId === 'new' ? null : labelId,
        })
    }
    const onRemoveLabel = () => {
        alertRef.current()
    }
    const deactivateLabel = (labelId) => {
        deleteCatalogLabel(labelId)
    }

    const onSetLanguage = (language) => {
        setLanguage(language)
        setLanguageChanged(true)
    }
    useEffect(() => {
        let msg = `Error saving ${labelName}`
        if (isErrorDeleting) {
            msg = `Error deleting ${labelName}`
        } else if (isErrorSavingTranslation) {
            msg = `Error saving translation for ${labelName}`
        }
        if (isErrorSaving || isErrorDeleting || isErrorSavingTranslation) {
            setValidationMessage(msg, 'fail')
        }
    }, [
        isErrorSaving,
        isErrorDeleting,
        isErrorSavingTranslation,
        setValidationMessage,
        labelName,
    ])

    useEffect(() => {
        let msg = `${labelName} has been saved!`
        if (isSuccessDeleting) {
            msg = `${labelName} has been deleted!`
        } else if (isSuccessSavingTranslation) {
            msg = `${labelName} translation has been saved!`
        }
        if (
            isSuccessSaving ||
            isSuccessDeleting ||
            isSuccessSavingTranslation
        ) {
            setValidationMessage(msg, 'success')
            history.push('/admin/labels')
        }
    }, [
        isSuccessSaving,
        isSuccessDeleting,
        isSuccessSavingTranslation,
        setValidationMessage,
        labelName,
        history,
    ])

    const isLoading = labelFromServer.isLoading

    const workoutsColumns = [
        {
            Header: 'Name',
            accessor: 'name',
            filter: 'default',
            isSortableIcon: true,
        },
        {
            Header: 'Level',
            accessor: 'level',
            Filter: SelectColumnFilter,
            filter: 'includes',
            isSortableIcon: true,
        },
        {
            Header: 'Copy',
            accessor: 'copy',
            maxWidth: 70,
            isDownloadable: true,
        },
        {
            Header: 'Active',
            accessor: 'activeIcon',
            Filter: SelectColumnFilter,
            filter: 'equals',
        },
        {
            Header: 'New Version',
            accessor: 'newVersion',
            Filter: SelectColumnFilter,
            filter: 'equals',
        },
    ]

    const workoutsData = useMemo(
        () =>
            workouts?.map((prop, key) => {
                return {
                    id: key,
                    name: prop.name,
                    _id: prop.id,
                    pathname: `/admin/workout/${prop.id}`,
                    level: String(prop.level),
                    muscles: prop.muscles,
                    active: prop.active,
                    activeIcon: prop.active ? 'true' : 'false',
                    newVersion: prop.version > 1 ? 'true' : 'false',
                }
            }),
        [workouts]
    )

    return (
        <Page>
            <PageContent>
                <DetailsWrapper
                    isLoading={isLoading}
                    header="Edit Label"
                    backButtonText="Back to Labels"
                    saveButtonText="Save Label"
                    saveButtonAction={onSaveLabel}
                    deleteButtonText="Delete Label"
                    deleteButtonAction={onRemoveLabel}
                    disableDelete={validate}>
                    <Row>
                        <Language
                            language={language}
                            setLanguage={onSetLanguage}
                        />
                        <DetailsInput
                            type="text"
                            name="label name"
                            placeholder="name"
                            value={labelName}
                            setter={setLabelName}
                            showError={showError}
                        />
                    </Row>
                    <Row>
                        <ImageLabelDisplayTypeSelect
                            displayType={displayType}
                            setDisplayType={setDisplayType}
                        />
                    </Row>
                    <SweetAlert
                        title={'Are you sure?'}
                        confirmBtnText="Delete label"
                        mainText="Label"
                        action="deleted"
                        ref={alertRef}
                        deactivate={deactivateLabel}
                        id={labelId}
                    />
                </DetailsWrapper>
                <FullWidthTableWrapper
                    title="Workouts"
                    columns={workoutsColumns}
                    data={workoutsData}
                    filterByVersion
                    type={'WORKOUTS'}
                    isFetching={isWorkoutsLoading}
                />
            </PageContent>
        </Page>
    )
}

export default Label
