import {styled} from 'common'
import {Button} from 'components/general/Control'
import React from 'react'
import download from 'assets/img/download.svg'

const DownloadPDFComp = ({onClick = {}, loading = false, disabled = false}) => {
    return (
        <>
            <Container>
                <Nav>
                    <ButtonDownload disabled={disabled} onClick={onClick}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            {!loading ? (
                                <ImageDownload
                                    src={download}
                                    width={15}
                                    height={15}
                                    alt="down"
                                />
                            ) : null}
                            <TextPDF isLoading={loading}>
                                {loading ? 'Processing...' : 'Download PDF'}
                            </TextPDF>
                        </div>
                    </ButtonDownload>
                </Nav>
            </Container>
        </>
    )
}

const Container = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 999;
`

const Nav = styled.div`
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ButtonDownload = styled(Button)`
    display: flex;
    flex-direction: row;
    align-self: center;
`

const ImageDownload = styled.img`
    display: flex;
    align-self: center;
    justify-center: center;
`

const TextPDF = styled.p`
    color: white;
    margin-left: ${({isLoading}) => (isLoading ? 0 : 10)}px;
    margin-top: 5px;
`

export default DownloadPDFComp
