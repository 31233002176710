import {React} from 'common'
import {Page} from 'components/general/Page'

import {useGetTrainersQuery} from 'store/store'
import {TableWrapper} from 'components/general/Display/Table'

import {useMemo} from 'services/hooks'

const columns = [
    {
        Header: 'First Name',
        accessor: 'first_name',
        filter: 'default',
        isSortableIcon: true,
    },
    {
        Header: 'Last Name',
        accessor: 'last_name',
        filter: 'default',
        isSortableIcon: true,
    },
]

const Trainers = () => {
    const trainers = useGetTrainersQuery(
        {},
        {
            refetchOnMountOrArgChange: true,
        }
    )

    const data = useMemo(
        () =>
            trainers?.data?.map((prop, key) => {
                return {
                    id: prop._id,
                    first_name: prop.first_name,
                    last_name: prop.last_name,
                    pathname: `/admin/trainer/${prop._id}`,
                    _id: prop._id,
                    prop,
                }
            }),
        [trainers.data]
    )
    return (
        <Page hideSidbar={false}>
            <TableWrapper
                title="Trainers"
                columns={columns}
                data={data}
                createNew={'/admin/trainer/new'}
                isError={trainers?.isError}
            />
        </Page>
    )
}

export default Trainers
