import {React, styled} from 'common'
import {Input} from 'components/general/Control'
import {useCallback} from 'react'
import {useEffect, useUrlParams} from 'services/hooks'

const BetweenColumnFilter = ({column: {filterValue, setFilter, id}}) => {
    const [urlParams, setUrlParam] = useUrlParams()
    const {filter} = urlParams

    const setFilterIfDifferentThanParams = useCallback(
        (minParsed, maxParsed, minFilter, maxFilter) => {
            if (minParsed !== minFilter) {
                setFilter([minParsed, maxFilter])
            } else if (maxParsed !== maxFilter) {
                setFilter([minFilter, maxParsed])
            }
        },
        [setFilter]
    )

    useEffect(() => {
        if (filter && id) {
            const parsedFilter = JSON.parse(filter)
            const [minParsed, maxParsed] = parsedFilter[id] || [null, null]
            const [minFilter, maxFilter] = filterValue || [null, null]
            setFilterIfDifferentThanParams(
                minParsed,
                maxParsed,
                minFilter,
                maxFilter
            )
        }
    }, [filter, filterValue, id, setFilter, setFilterIfDifferentThanParams])

    const onChangeValue = (value) => {
        const newFilter = filter ? JSON.parse(filter) : {}
        newFilter[id] = value
        setUrlParam('filter', JSON.stringify(newFilter))
    }
    const onChangeMinValue = (val) => {
        onChangeValue([
            val ? parseInt(val, 10) : undefined,
            filterValue ? filterValue[1] : undefined,
        ])
    }
    const onChangeMaxValue = (val) => {
        onChangeValue([
            filterValue ? filterValue[0] : undefined,
            val ? parseInt(val, 10) : undefined,
        ])
    }
    return (
        <StyledDiv>
            <StyledInput
                value={(filterValue && filterValue[0]) || ''}
                type="number"
                placeholder={`Min`}
                setter={onChangeMinValue}
            />
            -
            <StyledInput
                value={(filterValue && filterValue[1]) || ''}
                type="number"
                placeholder={`Max`}
                setter={onChangeMaxValue}
            />
        </StyledDiv>
    )
}

export default BetweenColumnFilter

const StyledInput = styled(Input)`
    padding: 0;
    text-align: center;
`
const StyledDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // max-width: 150px;
`
