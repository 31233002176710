import React from 'react'
import {
    useRef,
    useMemo,
    useEffect,
    useHistory,
    useState,
    useDispatch,
    useCallback,
} from 'services/hooks'

import ControlButtons from 'components/Details/ControlButtons'

import {
    createFormData,
    formatDataToDragAndDrop,
    formatDataToDragAndDropFullObject,
    getIsActiveState,
    getIsEmptyMandatoryField,
    getSuccessSaveMessageData,
    showAlertMessage,
    getEntityDeletionAlertProps,
} from 'services/utils'
import ExercisesDragAndDrop from 'components/general/Control/DragAndDrop/ExercisesDragAndDrop'
import WorkoutGeneralContent from 'components/Workout/Details/WorkoutGeneralContent'
import {workoutMandatoryFields} from 'constant/workout'
import styled from 'styled-components'
import LabelsDragAndDrop from 'components/general/Control/DragAndDrop/LabelsDragAndDrop'
import {ActivationInput} from 'components/Details'
import {DEFAULT_VALUES} from 'components/Workout/Details/WorkoutBuilderControls'
import {prepareNewWorkout} from 'services/workout'
import Spinner from 'components/general/Display/Spinner'
import {
    addWorkoutImages,
    addWorkoutStructure,
    checkIsNameUniqe,
    isValidExerciseList,
    removeActivitiesWrapper,
} from 'services/workout'

import {SweetAlert} from 'components/general/Display'

const WorkoutDetails = ({
    workout,
    workouts,
    isNewWorkout,
    exercisesFromServer,
    showDraftError,
    setShowDraftError,
    onError,
    showSpinner,
    saveWorkout,
    saveOptions,
    language,
    setLanguage,
    showError,
    setShowError,
    showNameError,
    setShowNameError,
    updatedWorkout,
    setUpdatedWorkout,
    showSelectedExerciseError,
    setShowSelectedExerciseError,
    showSelectedTagsError,
    setShowSelectedTagsError,
    doneExercisesFormatting,
    setDoneExercisesFormatting,
    deleteWorkout,
    isSuccessDeleting,
}) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const {isSuccess} = saveOptions

    const [selectedExercises, setSelectedExercises] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [showDeletionPopUp, setShowDeletionPopUp] = useState(false)

    const [imageFile, setImageFile] = useState({file: null, previewUrl: null})
    const [longImage, setLongImage] = useState({file: null, previewUrl: null})
    const [smallImage, setSmallImage] = useState({file: null, previewUrl: null})

    //builder form for default values
    const [defaultWork, setDefaultWork] = useState('')
    const [defaultRest, setDefaultRest] = useState('')
    const [defaultSets, setDefaultSets] = useState('')

    const defaultsMap = {
        target: defaultWork,
        rest_time: defaultRest,
        sets: defaultSets,
    }

    const generalContentRef = useRef()
    const alertRef = React.createRef()

    const {name, active, workout_type} = updatedWorkout || {}

    const activeExercises = useMemo(
        () => exercisesFromServer?.filter((exercise) => exercise.active),
        [exercisesFromServer]
    )

    const updateWorkoutField = useCallback(
        (field, value) => {
            setUpdatedWorkout({...updatedWorkout, [field]: value})
        },
        [setUpdatedWorkout, updatedWorkout]
    )

    useEffect(() => {
        if (!doneExercisesFormatting) {
            if (!workout?.workout_activities?.length) {
                setDoneExercisesFormatting(true)
            } else {
                selectedExercises?.length && setDoneExercisesFormatting(true)
            }
        }
    }, [
        selectedExercises,
        doneExercisesFormatting,
        setDoneExercisesFormatting,
        workout,
    ])

    const redirectToList = useCallback(
        (redirect = false) => redirect && history.goBack(),
        [history]
    )

    useEffect(() => {
        if (workout) {
            const exercisesList = removeActivitiesWrapper(
                workout,
                activeExercises
            )
            setSelectedExercises(
                formatDataToDragAndDropFullObject(exercisesList)
            )
            setSelectedTags(formatDataToDragAndDrop(workout.tags))
            setImageFile({file: null, previewUrl: workout.image})
            setLongImage({file: null, previewUrl: workout.tall_image})
            setSmallImage({file: null, previewUrl: workout.small_image})
        }
    }, [activeExercises, isNewWorkout, workout])

    useEffect(() => {
        const shouldShowSuccess = isSuccess || isSuccessDeleting

        if (shouldShowSuccess) {
            const {message, type, redirect} = getSuccessSaveMessageData(name)

            showAlertMessage(message, type, dispatch)
            redirect && redirectToList(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isSuccess, redirectToList, isSuccessDeleting])

    const clearAlerts = useCallback(() => {
        setShowSelectedTagsError(false)
        setShowSelectedExerciseError(false)
        setShowError(false)
        setShowNameError(false)
        setShowDraftError(false)
    }, [
        setShowDraftError,
        setShowError,
        setShowNameError,
        setShowSelectedExerciseError,
        setShowSelectedTagsError,
    ])

    const onShowClassError = () => {
        onError('showClassError')
    }

    const isErrorFound = () => {
        clearAlerts()
        const isRequiredFieldsFull = getIsEmptyMandatoryField(
            workoutMandatoryFields,
            {
                ...updatedWorkout,
                image: imageFile,
                tall_image: longImage,
                small_image: smallImage,
            }
        )

        if (
            !selectedExercises.length ||
            !isValidExerciseList(selectedExercises)
        ) {
            onError('showSelectedExerciseError')
            setShowSelectedExerciseError(true)
            return true
        }
        if (!selectedTags.length) {
            onError('showSelectedTagsError')
            setShowSelectedTagsError(true)
            return true
        }
        if (!isRequiredFieldsFull) {
            onError('showError')
            setShowError(true)
            generalContentRef.current.scrollIntoView({
                behavior: 'auto',
                block: 'start',
            })
            return true
        }

        const isWorkoutNameUniqe = checkIsNameUniqe(name, workout, workouts)
        if (!isWorkoutNameUniqe) {
            onError('showNameError')
            setShowNameError(true)
            return true
        }
    }

    const handleDraftError = () => {
        onError('showError')
        setShowDraftError(true)
        return false
    }

    const handleNameCheckBeforeSave = () => {
        const isNmaeUniqe = checkIsNameUniqe(name, workout, workouts)
        if (!isNmaeUniqe) {
            onError('showNameError')
            setShowNameError(true)
            return false
        }
        return true
    }

    const checkIsAllowdToSave = (isActive) => {
        if (isActive && isErrorFound()) {
            return false
        }
        if (!isActive) {
            if (!updatedWorkout.name) {
                return handleDraftError()
            } else {
                return handleNameCheckBeforeSave()
            }
        }

        return true
    }

    const onSave = async (ev, toggleActive = false) => {
        ev && ev.preventDefault()

        const isActiveState = getIsActiveState(toggleActive, updatedWorkout)

        if (!checkIsAllowdToSave(isActiveState)) {
            return
        }
        const newWorkout = prepareNewWorkout(
            updatedWorkout,
            name,
            selectedExercises,
            defaultsMap,
            DEFAULT_VALUES,
            selectedTags
        )
        addWorkoutImages(newWorkout, imageFile, smallImage, longImage)
        addWorkoutStructure(newWorkout, selectedExercises)

        if (toggleActive) {
            newWorkout.active = !newWorkout.active
        }
        doSaveWorkout(newWorkout)
    }

    const doSaveWorkout = async (newWorkout) => {
        const workoutFormData = createFormData(newWorkout)
        try {
            await saveWorkout({
                workout: workoutFormData,
                id: workout?.id,
            }).unwrap()
        } catch (err) {
            const {message} = err.data
            showAlertMessage(message, 'alert-danger', dispatch)
            redirectToList(false)
        }
    }

    const onActivate = () => onSave(null, true)

    useEffect(() => {
        name?.length && setShowNameError(false)
    }, [name, setShowNameError])

    useEffect(() => {
        showDeletionPopUp && alertRef?.current()
    }, [showDeletionPopUp, alertRef])

    if (showSpinner) {
        return (
            <SpinnerContainer className="d-flex w-100 h-100 justify-content-center align-items-center flex-grow-1">
                <Spinner label={'PREPARING WORKOUT DETAILS . . .'} />
            </SpinnerContainer>
        )
    }

    const deletionAction = (id) => {
        deleteWorkout(id)
    }

    const onUpdateWorkoutTarget = (v) =>
        setUpdatedWorkout({...updatedWorkout, target: v})

    return (
        <div>
            <GeneralContentContainer ref={generalContentRef}>
                <WorkoutGeneralContent
                    isNewWorkout={isNewWorkout}
                    setImageFile={setImageFile}
                    setLongImage={setLongImage}
                    setSmallImage={setSmallImage}
                    imageFile={imageFile}
                    longImage={longImage}
                    smallImage={smallImage}
                    language={language}
                    setLanguage={setLanguage}
                    showError={showError}
                    showDraftError={showDraftError}
                    showNameError={showNameError}
                    updatedWorkout={updatedWorkout}
                    onShowClassError={onShowClassError}
                    updateWorkoutField={updateWorkoutField}
                    seletedExercises={selectedExercises}
                />
            </GeneralContentContainer>
            <ExercisesDragAndDrop
                onUpdateWorkoutTarget={onUpdateWorkoutTarget}
                showSelectedExerciseError={showSelectedExerciseError}
                setSelectedExercises={setSelectedExercises}
                selectedExercises={selectedExercises}
                updatedWorkout={updatedWorkout}
                setDefaultWork={setDefaultWork}
                setDefaultRest={setDefaultRest}
                setDefaultSets={setDefaultSets}
                defaultWork={defaultWork}
                defaultRest={defaultRest}
                defaultSets={defaultSets}
                isClass={workout_type === 'class'}
            />
            <LabelsDragAndDrop
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                showSelectedTagsError={showSelectedTagsError}
            />
            {isNewWorkout && (
                <ActivationInput
                    type={'workout'}
                    active={active || false}
                    setactive={(value) => updateWorkoutField('active', value)}
                />
            )}
            <ControlButtons
                hideActivationButton={isNewWorkout}
                hideControls={!isNewWorkout && workout?.version < 2}
                isActive={Boolean(workout?.active)}
                onSave={onSave}
                onActivate={onActivate}
                onDelete={() => setShowDeletionPopUp(true)}
                topic={'Workout'}
            />
            <SweetAlert
                ref={alertRef}
                hideAlertExtraFunction={() => setShowDeletionPopUp(false)}
                id={workout?.id}
                {...getEntityDeletionAlertProps(
                    workout,
                    'Workout',
                    deletionAction
                )}
            />
        </div>
    )
}

export default WorkoutDetails
const SpinnerContainer = styled.div``
const GeneralContentContainer = styled.div``
