import {React} from 'common'
import {useGetUsersQuery} from 'store/store'
import {useMemo, useOnSaveCSV, useCallback} from 'services/hooks'
import api from 'services/api'
import {Page} from 'components/general/Page'
import {
    TableWrapper,
    TableLink,
    SelectColumnFilter,
} from 'components/general/Display/Table'
import {getUserRole} from 'services/utils'
const columns = [
    // {
    //     Header: 'User ID',
    //     accessor: 'user_id',
    //     filter: 'default',
    // },
    {
        Header: 'Name',
        accessor: 'name',
        filter: 'default',
        isSortableIcon: true,
        defaultSort: true,
    },
    {
        Header: 'Email',
        accessor: 'mail',
        filter: 'default',
        isSortableIcon: true,
        defaultSort: true,
    },

    {
        Header: 'DOB',
        accessor: 'dob',
        filter: 'default',
    },
    {
        Header: 'Gender',
        accessor: 'gender',
        Filter: SelectColumnFilter,
        filter: 'includes',
    },
    {
        Header: 'Role',
        accessor: 'role',
        Filter: SelectColumnFilter,
        filter: 'includes',
    },

    {
        Header: 'Trainings',
        accessor: 'trainings',
        maxWidth: 70,
        isDownloadable: true,
    },
    {
        Header: 'Active',
        accessor: 'active',
        maxWidth: 70,
    },
]

const Users = () => {
    const [isLoading, onSaveCSV] = useOnSaveCSV()

    const users = useGetUsersQuery(
        {},
        {
            refetchOnMountOrArgChange: true,
        }
    )

    const onSaveFile = useCallback(
        (filename, userId) => onSaveCSV(api.getUserCSV(userId), filename),
        [onSaveCSV]
    )

    const data = useMemo(
        () =>
            users?.data?.data?.map((prop, key) => {
                return {
                    id: key,
                    // user_id: prop.id,
                    name: prop.name,
                    mail: prop.email,
                    trainings: (
                        <TableLink
                            color="red"
                            icon={'tim-icons icon-cloud-download-93'}
                            text="Download Trainings List"
                            onClick={() => onSaveFile(prop.name, prop.id)}
                        />
                    ),
                    pathname: `/admin/user-profile/${prop.id}`,
                    push_data: prop,
                    _id: prop.id,
                    dob: new Date(prop.birthday).toLocaleDateString('en-GB'),
                    gender: Number(prop.gender) ? 'Male' : 'Female',
                    weight: prop.weight,
                    height: prop.height,
                    email: prop.email,
                    role: getUserRole(prop.role)['label'],
                    active: !prop.deleted ? (
                        <i
                            value={1}
                            className={'tim-icons icon-check-2 text-success'}
                        />
                    ) : (
                        <i
                            value={0}
                            className={
                                'tim-icons icon-simple-remove text-danger'
                            }
                        />
                    ),
                }
            }),
        [users.data, onSaveFile]
    )

    // console.log(tableParams, 'tableParams')
    return (
        <Page hideSidbar={false}>
            <TableWrapper
                title="Users"
                columns={columns}
                data={data}
                onSaveCSV={() => onSaveFile('users')}
                createNew={'/admin/users-in-deletion'}
                cerateNewText={'Deleted users'}
                isError={users?.isError}
                isFetching={isLoading}
            />
        </Page>
    )
}

export default Users
