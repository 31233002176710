export const userRoleOptions = [
    {
        value: 0,
        label: 'User',
    },
    {
        value: 1,
        label: 'Admin',
        disabled: true,
    },

    {
        value: 2,
        label: 'Presenter',
    },
    {
        value: 3,
        label: 'Group Coach',
    },
]
