import {React} from 'common'
import {Spinner} from 'components/general/Display'
import {DetailsCard} from 'components/general/Page'
import {Row, Form} from 'components/general/Control'
import {Button} from 'reactstrap'

const DetailsWrapper = ({
    isLoading,
    children,
    header,
    width,
    height,
    backButtonText,
    saveButtonText,
    saveButtonAction,
    disabledSaveButton,
    deleteButtonText,
    deleteButtonAction,
    spinnerLabel,
    disableDelete,
}) => {
    return isLoading ? (
        <Spinner label={spinnerLabel} />
    ) : (
        <DetailsCard
            header={header}
            width={width}
            height={height}
            buttonText={backButtonText}>
            <Form
                onSubmit={(e) => {
                    e.preventDefault()
                    saveButtonAction && saveButtonAction()
                }}>
                {children}
                <Row className="d-flex justify-content-center ">
                    {saveButtonText && (
                        <Button
                            disabled={disabledSaveButton}
                            className={'btn btn-primary btn-md'}
                            // onClick={saveButtonAction}
                        >
                            {saveButtonText}
                        </Button>
                    )}
                    {deleteButtonText && (
                        <Button
                            className={'btn btn-success btn-md'}
                            onClick={deleteButtonAction}
                            disabled={disableDelete}>
                            {deleteButtonText}
                        </Button>
                    )}
                </Row>
            </Form>
        </DetailsCard>
    )
}

export default DetailsWrapper
