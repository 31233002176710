import React from 'react'
import 'assets/css/tooltipTable.css'

const Tooltip = ({content, children}) => {
    const [visible, setVisible] = React.useState(false)

    return (
        <div
            className="tooltip-container"
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}>
            {children}
            {visible && <div className="tooltip-content">{content}</div>}
        </div>
    )
}

export default Tooltip
