import {Col, FormGroup} from 'components/general/Control'
import RadioButtonsInput from 'components/general/Control/RadioButtonsInput'
import React from 'react'

export const IMAGE_PREVIEW_DISPLAY_TYPE = [
    {
        value: 0,
        label: 'Big (255 X 325)',
    },
    {
        value: 6,
        label: 'Long (225 X 160)',
    },
    {
        value: 1,
        label: 'Short (155 X 140)',
    },
]

const ImageLabelDisplayTypeSelect = ({displayType, setDisplayType}) => {
    return (
        <Col>
            <FormGroup>
                <RadioButtonsInput
                    options={IMAGE_PREVIEW_DISPLAY_TYPE}
                    name={'label_size'}
                    selected={displayType}
                    label={'Label Size'}
                    onChange={setDisplayType}
                />
            </FormGroup>
        </Col>
    )
}
export default ImageLabelDisplayTypeSelect
