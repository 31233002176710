import {Row, Col} from 'components/general/Control'
import SelectInput from 'components/general/Control/SelectInput'
import {contractionFocusOptions} from 'constant/exercise'

const ContractionFocus = ({
    showError,
    contractionFocus,
    setContractionFocus,
}) => {
    return (
        <Row form>
            <Col md={4}>
                <SelectInput
                    required
                    showError={showError}
                    name={'contraction_focus'}
                    label={'contraction focus'}
                    selected={contractionFocus}
                    onChange={setContractionFocus}
                    options={contractionFocusOptions}
                />
            </Col>
        </Row>
    )
}

export default ContractionFocus
