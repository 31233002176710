import {React} from 'common'

import {
    useEffect,
    useHistory,
    useState,
    useLocation,
    useParams,
    useDispatch,
    useCallback,
} from 'services/hooks'
import {
    useGetTrainerQuery,
    useSaveTrainerMutation,
    setMessage,
} from 'store/store'

import {LANGUAGES} from 'constant/languages'

import {Page, PageContent, DetailsWrapper} from 'components/general/Page'
import {
    LanguageAndFirstNameInput,
    LastNameAndDescriptionInput,
    ImagesUploaders,
} from 'components/views/Trainer'
import {checkValidFields} from 'services/utils'

const Trainer = () => {
    const history = useHistory()
    const location = useLocation()
    const params = useParams()
    const dispatch = useDispatch()

    const [language, setLanguage] = useState(LANGUAGES[0].value)
    const trainerFromProps = location.state
    const trainerId = params.id
    const validate = params?.id === 'new'

    const trainerFromServer = useGetTrainerQuery(
        {id: trainerId, language},
        {
            skip: validate,
            refetchOnMountOrArgChange: true,
        }
    )

    const [saveTrainer, {isLoading: isSavingTrainer, isSuccess, isError}] =
        useSaveTrainerMutation()

    const trainer = trainerFromServer?.data

    const [showError, setShowError] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [description, setDescription] = useState('')
    const [avatarImageFile, setAvatarImageFile] = useState({
        file: null,
        previewUrl: null,
    })
    const [coverImageFile, setCoverImageFile] = useState({
        file: null,
        previewUrl: null,
    })
    const [queryParams, setQueryParams] = useState('')

    useEffect(() => {
        if (trainer) {
            const {first_name, last_name, description, avatar, cover_image} =
                trainer

            setFirstName(first_name)
            setLastName(last_name)
            setDescription(description)
            setAvatarImageFile({previewUrl: avatar, file: null})
            setCoverImageFile({previewUrl: cover_image, file: null})
            setQueryParams(trainerFromProps.allQueryParmas)
        }
    }, [trainer, trainerFromProps.allQueryParmas])

    const setValidationMessage = useCallback(
        (message, type) => {
            dispatch(
                setMessage({
                    message,
                    type: type === 'fail' ? 'alert-danger' : 'alert-success',
                })
            )
        },
        [dispatch]
    )
    const onSaveTrainer = async (trainerId) => {
        const areFieldsValid = checkValidFields({firstName, lastName})
        if (!areFieldsValid) {
            setValidationMessage('Fields are not valid', 'fail')
            return setShowError(true)
        }
        const trainer = {
            first_name: firstName,
            last_name: lastName,
            description: description,
            avatar: avatarImageFile.file || avatarImageFile.previewUrl,
            cover_image: coverImageFile.file || coverImageFile.previewUrl,
            _id: trainerId === 'new' ? null : trainerId,
        }

        saveTrainer(trainer)
    }
    useEffect(() => {
        if (isSuccess) {
            setValidationMessage(firstName + ' has been saved!', 'success')
            return history.push('/admin/trainers')
        }
        if (isError) {
            setValidationMessage(
                'Something went wrong saving ' + firstName + ', try again',
                'fail'
            )
        }
    }, [
        isSavingTrainer,
        firstName,
        history,
        isError,
        isSuccess,
        setValidationMessage,
    ])

    console.log(queryParams, 'queryParams')

    return (
        <Page>
            <PageContent>
                <DetailsWrapper
                    isLoading={trainerFromServer.isLoading}
                    header="Edit Trainer"
                    backButtonText="Back to Trainers"
                    backButtonTo={
                        (queryParams && '/admin/trainers' + queryParams) ||
                        '/admin/trainers'
                    }
                    saveButtonText="Save Trainer"
                    saveButtonAction={onSaveTrainer}>
                    <LanguageAndFirstNameInput
                        language={language}
                        setLanguage={setLanguage}
                        firstName={firstName}
                        setFirstName={setFirstName}
                        showError={showError}
                    />
                    <LastNameAndDescriptionInput
                        lastName={lastName}
                        setLastName={setLastName}
                        description={description}
                        setDescription={setDescription}
                        showError={showError}
                    />
                    <ImagesUploaders
                        avatarImageFile={avatarImageFile}
                        setAvatarImageFile={setAvatarImageFile}
                        coverImageFile={coverImageFile}
                        setCoverImageFile={setCoverImageFile}
                    />
                </DetailsWrapper>
            </PageContent>
        </Page>
    )
}

export default Trainer
