import {useState, useEffect, useCallback} from 'react'
import {useLocation} from 'react-router-dom/cjs/react-router-dom.min'
import {saveFile} from 'services/utils'

export * from 'react'
export {useHistory, useLocation, useParams} from 'react-router-dom'
export {useDispatch} from 'react-redux'
export {Redirect} from 'react-router-dom'
export {NavLink} from 'react-router-dom'
export {Link} from 'react-router-dom'
export {useSelector} from 'react-redux'

// export * from 'store/uses'

// Hook - const size = useWindowSize()
export const useWindowSize = () => {
    const isClient = typeof window === 'object'

    const getSize = useCallback(() => {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined,
        }
    }, [isClient])

    const [windowSize, setWindowSize] = useState(getSize)

    useEffect(() => {
        if (!isClient) {
            return false
        }

        function handleResize() {
            setWindowSize(getSize())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [getSize, isClient]) // Empty array ensures that effect is only run on mount and unmount

    return windowSize
}

// hook to change url params and listen to url params changes

export const getUrlParamsObject = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const params = {}
    searchParams.forEach((v, k) => (params[k] = v))
    return params
}

const updateUrl = (key, val, refresh = false) => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set(key, val)
    const newUrl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        '?' +
        searchParams.toString()

    if (window.history.pushState) {
        window.history.pushState({path: newUrl}, '', newUrl)
    }
    return searchParams.toString()
}

export const useUrlParams = () => {
    const [urlParams, setUrlParams] = useState({})

    useEffect(() => {
        function handleUrlChange() {
            setUrlParams(getUrlParamsObject())
        }
        setUrlParams(getUrlParamsObject())
        window.addEventListener('popstate', handleUrlChange)

        return () => window.removeEventListener('popstate', handleUrlChange)
    }, [])

    // change url by adding or updating the key param with values
    const setUrlParam = (key, value) => {
        updateUrl(key, value)
        window.dispatchEvent(new Event('popstate'))
    }

    return [urlParams, setUrlParam]
}
export const useOnSaveCSV = () => {
    const [isLoading, setIsLoading] = useState(false)

    const onSaveCSV = async (csvDataPromise, fileString) => {
        setIsLoading(true)
        try {
            await saveFile(await csvDataPromise, fileString)
        } catch (error) {
            console.error('ERROR FETCHING CSV', error)
        }
        setIsLoading(false)
    }

    return [isLoading, onSaveCSV]
}

/**
 *
 * @returns pathname
 */
export const usePathList = () => {
    const location = useLocation()
    return location.pathname?.split('/')[2]
}
