import {
    TARGET_EXERCISE_TIME,
    workoutAccessOptions,
    workoutTypeOptions,
    WORKOUT_TARGETS,
} from 'constant/workout'
import * as uuid from 'uuid'
import {AWS_BASE_URL} from './api'
import {getFieldAsNumberOrZero} from './utils'

export const isValidExerciseList = (selectedExercises) =>
    selectedExercises.some((item) => item.activity_type === 'exercise') ||
    selectedExercises.some((item) => item.activity_type === 'group')

export const addWorkoutImages = (
    newWorkout,
    imageFile,
    smallImage,
    longImage
) => {
    if (imageFile.file) {
        newWorkout.img = imageFile.file
    }
    if (smallImage.file) {
        newWorkout.small_img = smallImage.file
    }
    if (longImage.file) {
        newWorkout.tall_img = longImage.file
    }
    delete newWorkout.image
}
const isGroup = (item) => item.activity_type === 'group'

const isExercise = (item) => item.activity_type === 'exercise'

const isIncludeGroup = (list) => list.some((item) => isGroup(item))
const isIncludeExercises = (list) => list.some((item) => isExercise(item))

const isCircuit = (list) =>
    !isIncludeExercises(list) &&
    list.some((item) => isGroup(item) && item.items.length !== 2)

const isSuperSet = (list) =>
    !isIncludeExercises(list) &&
    list
        .filter((item) => isGroup(item))
        .every((group) => group.items.length === 2)

const isBlock = (list) => isIncludeExercises(list) && isIncludeGroup(list)
const isLinear = (list) => !isIncludeGroup(list)

export const addWorkoutStructure = (workout, selectedExercises) => {
    let structure
    if (!selectedExercises.length) {
        return (workout.structure = '')
    }

    if (isLinear(selectedExercises)) {
        structure = 'linear'
    } else if (isBlock(selectedExercises)) {
        structure = 'block'
    } else if (isSuperSet(selectedExercises)) {
        structure = 'superset'
    } else if (isCircuit(selectedExercises)) {
        structure = 'circuit'
    }
    return (workout.structure = structure)
}

export const formatExercise = (item, fromGroup = false) => {
    const exerciseItem = {
        rest_time: item.rest_time,
        sets: item.sets,
        target: item.target,
        work_time: item.target || item.work_time, //TODO: DELETE AFTER CHANGING IN SERVER
        name: item.name,
        exercise: item.id,
        activity_type: item.activity_type,
        target_type: item.target_type || TARGET_EXERCISE_TIME,
    }
    if (fromGroup) {
        return exerciseItem
    }
    return {
        workoutActivity:
            item.activity_type === 'exercise' ? exerciseItem : {...item},
    }
}

export const addExercisesWrapper = (list) => {
    return list.map((item) => {
        if (item.activity_type === 'group') {
            const groupWithWrapper = {
                ...item,
                exercises: [
                    ...item.items.map((ex) => formatExercise(ex, true)),
                ],
            }
            delete item.items
            return {group: groupWithWrapper}
        }

        return formatExercise(item)
    })
}

export const prepareTags = (selectedTags) =>
    selectedTags.map((tag) => ({_id: tag.id}))

export const getWorkoutAccess = (workout_access) =>
    workout_access || workoutAccessOptions[0].value

export const getWorkoutTarget = (target) => target || WORKOUT_TARGETS[0].value

export const getWorkoutType = (workout_type) =>
    workout_type || workoutTypeOptions[0].value

export const checkArrayField = (fieldValue) => {
    if (!fieldValue) {
        return []
    } else {
        return Array.isArray(fieldValue) ? fieldValue : [fieldValue]
    }
}

const completeExerciseEmptyFields = (item, defaultsMap, defaultValues) => {
    const newItem = {...item}
    const filedsToComplete = ['rest_time', 'target', 'sets']
    filedsToComplete.map((field) => {
        let fieldWithValue = newItem[field]
        if (!fieldWithValue) {
            fieldWithValue = Number(defaultsMap[field]) || defaultValues[field]
        } else {
            fieldWithValue = Number(fieldWithValue)
        }
        if (field === 'target') {
            newItem.work_time = fieldWithValue
        }
        newItem[field] = fieldWithValue
        newItem.work_time = newItem.target
        return newItem
    })
    return newItem
}

const compoleteField = (field, item, defaultsMap, defaultValues) => {
    if (field === 'rounds') {
        return {
            ...item,
            rounds: Number(item.items[0]?.rounds || defaultValues[field]),
        }
    }
    item[field] = item[field]
        ? Number(item[field])
        : Number(defaultsMap[field]) || defaultValues[field]

    return item
}

export const handleEmptyFields = (
    selectedExercises,
    defaultsMap,
    defaultValues
) =>
    selectedExercises.map((ex) => {
        delete ex.highlight
        if (ex.activity_type === 'exercise') {
            return completeExerciseEmptyFields(ex, defaultsMap, defaultValues)
        }
        if (ex.activity_type === 'group') {
            ex.items = ex.items?.map((item) =>
                completeExerciseEmptyFields(item, defaultsMap, defaultValues)
            )
            return compoleteField('rounds', ex, defaultsMap, defaultValues)
        }

        if (
            ex.activity_type === 'transition' ||
            ex.activity_type === 'section'
        ) {
            return compoleteField('rest_time', ex, defaultsMap, defaultValues)
        }
        return ex
    })

export const checkIsNameUniqe = (name, workout, workouts) => {
    const existingName = workouts?.find(
        (wo) =>
            wo.name.toLowerCase().trim() === name.toLowerCase().trim() &&
            wo.id !== workout?.id
    )
    return !existingName
}

export const getExerciseById = (exercise, activeExercises) => {
    if (exercise.activity_type !== 'exercise') {
        return exercise
    }
    if (typeof exercise.exercise === 'string') {
        const exerciseFound = activeExercises?.find(
            (ex) => ex.id === exercise.exercise
        )
        return exerciseFound
            ? {
                  ...exercise,
                  ...exerciseFound,
                  uuid: uuid.v4(),
              }
            : null
    }
    return exercise
}

export const getItems = (group, activeExercises) => {
    const exerciseList = [...group.exercises] || []

    const listWithFullExercise = exerciseList?.map((ex) =>
        getExerciseById(ex, activeExercises)
    )
    const itemWithRounds = {
        ...listWithFullExercise[0],
        rounds: group.rounds,
    }
    listWithFullExercise?.splice(0, 1, itemWithRounds)
    //TODO: HANDLE IF EXERCISE IS MISIING /INACTIVE
    return listWithFullExercise?.map((item) => item)
}

export const removeActivitiesWrapper = (workout, activeExercises) =>
    workout.workout_activities?.map((item) =>
        item.workoutActivity
            ? getExerciseById(item.workoutActivity, activeExercises)
            : {
                  activity_type: 'group',
                  items: getItems(item.group, activeExercises),
              }
    )

export const prepareActivities = (
    selectedExercises,
    defaultsMap,
    DEFAULT_VALUES
) => {
    const newExercises = handleEmptyFields(
        selectedExercises,
        defaultsMap,
        DEFAULT_VALUES
    )
    const exercisesWithWrapper = addExercisesWrapper(newExercises)
    return exercisesWithWrapper
}

export const getWorkoutDevices = (selectedExercises = []) => {
    const validDevices = ['bar', 'rope', 'normal', 'gearGo', 'gearX']
    if (selectedExercises.length) {
        let newList = []
        selectedExercises.map((ex) => {
            if (ex.activity_type === 'group') {
                ex.items?.map((item) => {
                    if (validDevices.includes(item?.exercise_type)) {
                        return newList.push(item?.exercise_type)
                    }
                    return null
                })
            }
            return (
                validDevices.includes(ex?.exercise_type) &&
                newList.push(ex?.exercise_type)
            )
        })
        return newList.filter((v, i, a) => a.indexOf(v) === i)
    } else return []
}

export const prepareNewWorkout = (
    updatedWorkout,
    name,
    selectedExercises,
    defaultsMap,
    DEFAULT_VALUES,
    selectedTags
) => {
    const {
        instructions,
        estimated_calories,
        estimated_work,
        difficulty_level,
        body_region_focus,
        goal,
        background_music,
        workout_access,
        workout_type,
        target,
    } = updatedWorkout || {}

    return {
        ...updatedWorkout,
        target: getWorkoutTarget(target),
        workout_type: getWorkoutType(workout_type),
        workout_access: getWorkoutAccess(workout_access),
        name: name.trim(),
        workout_activities: prepareActivities(
            selectedExercises,
            defaultsMap,
            DEFAULT_VALUES
        ),
        tags: prepareTags(selectedTags),
        goal: checkArrayField(goal),
        devices: getWorkoutDevices(selectedExercises),
        instructions: checkArrayField(instructions),
        body_region_focus: checkArrayField(body_region_focus),
        estimated_calories: getFieldAsNumberOrZero(estimated_calories),
        estimated_work: getFieldAsNumberOrZero(estimated_work),
        difficulty_level: getFieldAsNumberOrZero(difficulty_level),
        background_music: background_music
            ? AWS_BASE_URL + '/music/' + background_music + '.mp3'
            : '',
    }
}
