import {Col, FormGroup, Row} from 'components/general/Control'
import RadioButtonsInput from 'components/general/Control/RadioButtonsInput'
import {anchorPositionTypes} from 'constant/exercise'
import React from 'react'

const AnchorPosition = ({
    bar,
    showError,
    anchorPositionType,
    handleAnchorPositionType,
}) => {
    const barOptions = anchorPositionTypes.filter(
        (item) => item.value !== 'none'
    )

    const options = bar ? barOptions : anchorPositionTypes
    return (
        <Row>
            <Col className="checkbox-radios py-2 pr-md-0" sm="12" md="6">
                <FormGroup>
                    <RadioButtonsInput
                        required
                        showError={showError}
                        options={options}
                        name={'anchor_position'}
                        selected={anchorPositionType}
                        label={'Anchor Position'}
                        onChange={handleAnchorPositionType}
                    />
                </FormGroup>
            </Col>
        </Row>
    )
}

export default AnchorPosition
