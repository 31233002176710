import {Colors} from 'appearance'
import React, {useMemo} from 'react'
import {styled} from 'common'
import {Input} from 'reactstrap'
import {useSelector} from 'react-redux'
import {useIsDarkTheme} from 'store/store'

const AppSelect = ({options = [], onChange, value, className}) => {
    const isDarkTheme = useSelector(useIsDarkTheme)
    const dropDownBackground = useMemo(
        () => (isDarkTheme ? Colors.OPAC_DARK : Colors.WHITE),
        [isDarkTheme]
    )
    return (
        <StyledSelect
            className={`form-control ${className}`}
            onChange={onChange}
            value={value}
            style={{backgroundColor: dropDownBackground}}
            type="select">
            {options.map((option, i) => (
                <option key={'select' + i} value={option.value}>
                    {option.label}
                </option>
            ))}
        </StyledSelect>
    )
}

const StyledSelect = styled(Input)`
    text-align: center;
    border-radius: 6px;
    width: 100%;
    max-width: 200px;
`

export default AppSelect

// color: ${({value, color}) =>
//     !value ? `rgba(255, 255, 255, 0.3)` : `rgba(255, 255, 255, 0.8)`};
