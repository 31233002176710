const colors = {
    APP_WHITE: '#fefefe',
    APP_BLACK: '#000',
    APP_GRAY: '#ccc',
    APP_GRAY_LIGHT: '#eee',
    APP_GRAY_DARK: '#aaa',
    INNER_CARD_BACKGROUND: '#9999c52b',
    PRIMARY_COLOR: '#c62636',
    PRIMARY_TEXT: '#45484b',
    OPAC_DARK: '#26293c',
    OPAC_LIGHT: '#ffffff',
    DARK_HOVER: '#2a2e3e8c',
    LIGHT_HOVER: '#ebeaea6e',
    LINK_TEXT: '#c62636',
}

export default colors
