import axios from 'axios'
import fileDownload from 'js-file-download'

const debugEnv = 'local' //'dev' // 'prod', 'dev', 'local'

const DEBUG_HOSTS = {
    prod: 'api.hyfitgear.com',
    dev: 'dev-api.hyfitgear.com',
    local: 'localhost:3009',
}

let apiSubDomain = window.location.host
    .replace('admin', 'api')
    .replace('cms', 'api')
    .replace('test-', 'dev-')
if (window.location.host.includes('localhost')) {
    apiSubDomain = DEBUG_HOSTS[debugEnv] || DEBUG_HOSTS.local
}

export const AWS_BASE_URL =
    'https://hyfit-' +
    (apiSubDomain === 'api.hyfitgear.com' ? 'prod' : 'dev') +
    '.s3.amazonaws.com'

const protocol =
    debugEnv === 'local' && window.location.host.indexOf('localhost') !== -1
        ? 'http'
        : 'https'

const BASE_URL = protocol + '://' + apiSubDomain + '/api/v1/' //"https://dev-api.hyfitgear.com/api/v1";

const HEADERS = {
    'Content-Type': 'application/json',
}

export const isDevServer = () =>
    apiSubDomain === DEBUG_HOSTS.dev || apiSubDomain === DEBUG_HOSTS.local

class Api {
    constructor() {
        this.baseUrl = BASE_URL
        this.supportedRequestMethods = {
            POST: 'post',
            PUT: 'put',
            GET: 'get',
            DELETE: 'delete',
        }
        this.accessToken = localStorage.getItem('token')
    }

    setAccessToken(token) {
        this.accessToken = token
        localStorage.setItem('token', token)
    }

    async requestWrapper({headers, method, endpoint, body}) {
        const allHeaders = {
            'x-access-token': localStorage.getItem('token'),
            Accept: 'application/json',
            'hyfit-version': '0002000400010000',
            ...headers,
        }
        let response

        response = await this.request(
            endpoint,
            method,
            null,
            method === 'delete' ? {data: body} : body,
            allHeaders
        )

        if (!response.success) {
            return Promise.reject(response)
        }
        response.success = true
        if (response) {
            response.message = response.data.message
        }
        if (process.env.NODE_ENV !== 'production') {
            console.log(
                'API RESPONSE: ',
                'method: ',
                method,
                'endpoint: ',
                endpoint,
                'headers: ',
                headers,
                'response: ',
                response
            )
        }

        return response
    }

    async request(
        endpoint,
        method,
        params,
        data,
        headers = {},
        onSuccess,
        rememberMe = true
    ) {
        try {
            let API = await axios.create({
                baseURL: this.baseUrl,
                headers: {...HEADERS, ...headers},
                withCredentials: true,
            })
            const response = await API[method](
                endpoint,
                method === 'delete' ? {data} : data
            )

            onSuccess && onSuccess(response?.data)
            return response?.data
        } catch (error) {
            console.error('request error', {error: error.response.data})
            if (rememberMe === 'true') {
                return await this.reLoginWithRefreshToken(error)
            }

            return error.response.data
        }
    }

    async clearTestData() {
        let requestParams = {
            method: this.supportedRequestMethods.GET,
            endpoint: '/admin/test/clear_test_data',
        }
        return await this.requestWrapper(requestParams)
    }

    async sendOTPToMail(body) {
        let requestParams = {
            method: this.supportedRequestMethods.POST,
            endpoint: '/admin/auth/login',
            body,
        }
        return await this.requestWrapper(requestParams)
    }

    async verifyOTP(body) {
        let requestParams = {
            method: this.supportedRequestMethods.POST,
            endpoint: '/admin/auth/verify',
            body,
        }
        return await this.requestWrapper(requestParams)
    }

    async getUser(id) {
        let requestParams = {
            method: this.supportedRequestMethods.GET,
            endpoint: `admin/user/${id}`,
        }
        const res = await this.requestWrapper(requestParams)
        return res.data
    }

    async getAccount() {
        let requestParams = {
            method: this.supportedRequestMethods.GET,
            endpoint: `accounts/?page=0&pageSize=22`,
        }
        const res = await this.requestWrapper(requestParams)
        return res.data
    }

    async getUserCSV(userId) {
        const endpoint = userId ? `admin/user/csv/${userId}` : `admin/user/csv`
        return await this.getCSV(endpoint)
    }

    async getExcerciseCSV() {
        console.log(this, 'EXERCISE CSV!')
        const endpoint = 'admin/program/workout/exercise/csv'
        return await this.getCSV(endpoint)
    }

    async getWorkoutsCSV() {
        const endpoint = 'admin/program/workout/csv'
        return await this.getCSV(endpoint)
    }

    async getProgramsCSV() {
        const endpoint = 'admin/program/csv'
        return await this.getCSV(endpoint)
    }

    async getCSV(endpoint) {
        const method = this.supportedRequestMethods.GET
        const allHeaders = {
            'x-access-token': localStorage.getItem('token'),
            Accept: 'application/json',
        }
        return await this.request(endpoint, method, null, null, allHeaders)
    }

    async reLoginWithRefreshToken(err) {
        const originalReq = err.config

        if (
            err.response?.status === 401 &&
            originalReq.url === 'auth/refreshToken'
        ) {
            return
        }

        originalReq._retry = true

        try {
            await this.refreshToken()
            return await this.request(
                originalReq.url,
                originalReq.method,
                originalReq.params,
                originalReq.data,
                originalReq.headers,
                null,
                null
            )
        } catch (err) {
            console.error('refresh error: ', err)
            // window.location.href = '/login'
        }
    }
    async handleDownload(url) {
        const filename = this.filenameFromPath(url)

        const res = await axios.get(url, {
            responseType: 'blob',
        })

        fileDownload(res.data, filename)
    }
    filenameFromPath(path) {
        return path.replace(/^.*[\\/]/, '')
    }
}

const api = new Api()
export default api
