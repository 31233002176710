import {React, styled} from 'common'
import {Colors} from 'appearance'
import {
    Col,
    Card,
    CardBody,
    Row,
    // CardTitle,
    CardFooter,
    FormGroup,
    Label,
    // Input,
} from 'components/general/Control'
import {Input} from 'reactstrap'

const UpdateFileCard = ({
    type,
    deviceName,
    current,
    value,
    setter,
    submit,
    handleFile,
    downloadLink,
}) => {
    return (
        <Col id={'version-card-' + type} xl="6" md="6">
            <Card className="card-stats">
                <CardBody>
                    <h3>{deviceName} </h3>
                    <h3>
                        Current Version:{' '}
                        <DownloadUrl id={'download-url'} href={downloadLink}>
                            {current}
                        </DownloadUrl>
                    </h3>
                    <FormGroup row>
                        <Label for="version" sm={4}>
                            Version Number:
                        </Label>
                        <Col sm={8}>
                            <Input
                                type="type"
                                value={value?.version}
                                onChange={(e) =>
                                    setter({...value, version: e.target.value})
                                }
                                id="version"
                                placeholder="Enter version number"
                            />
                        </Col>
                    </FormGroup>
                    <Row>
                        <Label for="file" sm={4}>
                            New Version File:
                        </Label>
                        <Col sm={8}>
                            <input
                                type="file"
                                onChange={(e) => handleFile(e, setter, value)}
                                name="file"
                                id="file"
                            />
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter>
                    <hr />
                    <div
                        onClick={() => submit(type)}
                        className="stats"
                        style={{cursor: 'pointer'}}>
                        <i className="tim-icons icon-refresh-01" /> Save Version
                    </div>
                </CardFooter>
            </Card>
        </Col>
    )
}

export default UpdateFileCard

const DownloadUrl = styled.a`
    color: ${Colors.LINK_TEXT};
`
