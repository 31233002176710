import {backgroundMusicOptions} from 'constant/workout'
import {Row, Col} from 'components/general/Control'
import SelectInput from 'components/general/Control/SelectInput'

const BackgroundMusicSelection = ({
    backgroundMusic,
    setBackgroundMusic,
    showError,
}) => {
    return (
        <Row form>
            <Col md={8}>
                <SelectInput
                    md={8}
                    required
                    showError={showError}
                    selected={backgroundMusic}
                    onChange={setBackgroundMusic}
                    options={backgroundMusicOptions}
                    name={'background_music'}
                    label={'background music'}
                />
            </Col>
        </Row>
    )
}

export default BackgroundMusicSelection
