import React, {useEffect, useMemo, useState, useCallback, useRef} from 'react'

import {formatDataToDragAndDropFullObject} from 'services/utils'
import DragAndDrop from 'components/general/Control/DragAndDrop'
import styled from 'styled-components'
import {Animations} from 'appearance'
import {useGetExercisesQuery} from 'store/store'

const ExercisesDragAndDrop = ({
    showSelectedExerciseError,
    onUpdateWorkoutTarget,
    setSelectedExercises,
    selectedExercises,
    updatedWorkout,
    setDefaultWork,
    setDefaultRest,
    setDefaultSets,
    defaultWork,
    defaultRest,
    defaultSets,
    isClass,
}) => {
    const {data: exercisesFromServer} = useGetExercisesQuery()

    const [checkboxList, setCheckboxList] = useState(selectedExercises)
    const [availableExercises, setAvailableExercises] = useState([])
    const [filterGender, setFilterGender] = useState('')
    const [selectedFilterTypes, setSelectedFilterTypes] = useState({})

    //filter exercise variables:
    const [searchText, setSearchText] = useState('')

    const exerciseListRef = useRef()

    useEffect(
        () =>
            setAvailableExercises(
                formatDataToDragAndDropFullObject(
                    exercisesFromServer?.filter(
                        (exercise) => exercise.active && exercise.version > 1
                    )
                )
            ),
        [exercisesFromServer]
    )

    useEffect(() => {
        if (showSelectedExerciseError && selectedExercises?.length < 1) {
            exerciseListRef.current.scrollIntoView({
                behavior: 'auto',
                block: 'start',
            })
        }
    }, [selectedExercises, showSelectedExerciseError])

    const filterBySearch = useCallback(
        (exercise) =>
            (exercise?.display_name || exercise?.name)
                ?.toLowerCase()
                .includes(searchText?.toLowerCase()),
        [searchText]
    )

    const filterByKey = useCallback(
        (ex) => {
            if (Object.keys(selectedFilterTypes).length === 0) {
                return true
            }
            return Object.keys(selectedFilterTypes).every((filterLabel) =>
                selectedFilterTypes[filterLabel].includes(ex[filterLabel])
            )
        },
        [selectedFilterTypes]
    )

    const filterByGender = useCallback(
        (exercise) =>
            filterGender ? exercise.trainer_gender === filterGender : true,
        [filterGender]
    )

    const filteredExercises = useMemo(
        () =>
            availableExercises?.filter((ex) => {
                return (
                    filterBySearch(ex) && filterByGender(ex) && filterByKey(ex)
                )
            }),
        [availableExercises, filterByGender, filterByKey, filterBySearch]
    )

    const onRemoveItem = (itemUuid) => {
        const newSelectedItems = selectedExercises.filter(
            (item) => item.uuid !== itemUuid
        )
        const newCheckboxSelectedExercises = checkboxList.filter(
            (item) => !item.activity_type && item.uuid !== itemUuid
        )
        setSelectedExercises(newSelectedItems)
        setCheckboxList(newCheckboxSelectedExercises)
    }

    return (
        <Container
            ref={exerciseListRef}
            error={showSelectedExerciseError}
            className="justify-content-center">
            <DragAndDrop
                showFilter
                isClass={isClass}
                showColumnSelection
                showSelectedExerciseError={showSelectedExerciseError}
                onRemoveItem={onRemoveItem}
                checkboxList={checkboxList}
                setCheckboxList={setCheckboxList}
                searchText={searchText}
                setSearchText={setSearchText}
                filterByGender={filterGender}
                setFilterByGender={setFilterGender}
                selectedItems={selectedExercises}
                setSelectedItems={setSelectedExercises}
                collection={filteredExercises}
                setCollection={setAvailableExercises}
                updatedWorkout={updatedWorkout}
                onUpdateWorkoutTarget={onUpdateWorkoutTarget}
                dropTitle={'Selected Exercises'}
                dragTitle={'Available Exercises'}
                selectedFilterTypes={selectedFilterTypes}
                setSelectedFilterTypes={setSelectedFilterTypes}
                defaultWork={defaultWork}
                setDefaultWork={setDefaultWork}
                defaultRest={defaultRest}
                setDefaultRest={setDefaultRest}
                defaultSets={defaultSets}
                setDefaultSets={setDefaultSets}
            />
        </Container>
    )
}

export default ExercisesDragAndDrop

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    ${({error}) => error && Animations.flickerAnimation};
`
