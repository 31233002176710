import input from './Input'
import appSelect from './Select'
import detailsInput from './DetailsInput'
import datePickerInput from './DatePickerInput'
import timeframeInput from './TimeframePicker'
import selectInput from './SelectInput'
import Rwitch from 'react-bootstrap-switch'
import StyledButton from './Button'
import {
    StyledCard,
    StyledCardBody,
    StyledCardHeader,
    StyledCardTitle,
    StyledCardFooter,
} from './Card'

export const Input = input
export const AppSelect = appSelect
export const DetailsInput = detailsInput
export const SelectInput = selectInput
export const DatePickerInput = datePickerInput
export const TimeframeInput = timeframeInput
export const Button = StyledButton

export const Switch = Rwitch

export const Card = StyledCard
export const CardBody = StyledCardBody
export const CardHeader = StyledCardHeader
export const CardTitle = StyledCardTitle
export const CardFooter = StyledCardFooter
export {
    Col,
    Row,
    Badge,
    Collapse,
    Container,
    Form,
    FormGroup,
    InputGroup,
    Label,
    Nav,
    NavItem,
    Navbar,
    NavbarBrand,
    Spinner,
    Table,
    InputGroupText,
    Alert,
    UncontrolledAlert,
    CardGroup,
} from 'reactstrap'

// Table
