import {React, styled} from 'common'
import {NavLink} from 'services/hooks'

// import api from 'services/api'

import {Button, Col} from 'components/general/Control'

const TableWrapperButtons = ({onSaveCSV, createNew, cerateNewText}) => {
    return (
        <ColFlexEnd>
            {onSaveCSV && (
                <Button
                    id="save_csv"
                    className={'btn btn-primary btn-md'}
                    onClick={onSaveCSV}>
                    Download CSV
                </Button>
            )}
            {createNew && (
                <NavLink id="create_new" to={createNew} activeClassName="">
                    <Button className={'btn btn-primary btn-md'}>
                        {cerateNewText || 'Create new'}
                    </Button>
                </NavLink>
            )}
        </ColFlexEnd>
    )
}

export default TableWrapperButtons
const ColFlexEnd = styled(Col)`
    display: flex;
    justify-content: end;
`
