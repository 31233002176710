import {Col} from 'components/general/Control'
import SelectInput from 'components/general/Control/SelectInput'
import {bodyRegion} from 'constant/exercise'

const BodyRegion = ({region, setRegion, showError}) => {
    return (
        <Col md={6} className="px-0  pr-md-3">
            <SelectInput
                required
                showError={showError}
                selected={region}
                onChange={setRegion}
                options={bodyRegion}
                name={'body_region'}
                label={'body region'}
            />
        </Col>
    )
}

export default BodyRegion
