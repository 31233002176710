import {React} from 'common'
import {Language} from 'components/general/Display'

import {DetailsInput, Row} from 'components/general/Control'

const LanguageAndFirstNameInput = ({
    language,
    setLanguage,
    firstName,
    setFirstName,
    showError,
}) => {
    return (
        <Row>
            <Language language={language} setLanguage={setLanguage} />
            <DetailsInput
                type="text"
                name="first name"
                placeholder="first name"
                value={firstName}
                setter={setFirstName}
                showError={showError}
            />
        </Row>
    )
}

export default LanguageAndFirstNameInput
