import React, {useMemo} from 'react'
import {InputGroup, Label} from 'reactstrap'
import styled from 'styled-components'

const RadioButtonsInput = ({
    label,
    options = [],
    name,
    selected,
    onChange,
    required,
    showError,
    noBorder,
    filter,
}) => {
    const showErrorClass = useMemo(
        () => showError && !selected && selected !== 0 && 'border-danger',
        [showError, selected]
    )

    const labelToDisplay = useMemo(
        () => (required ? label + ' *' : label),
        [required, label]
    )

    return (
        <Container
            noBorder={noBorder}
            className={`form-control  ${
                filter ? 'py-0' : 'h-auto'
            }  ${showErrorClass}`}>
            {label && (
                <StyledLabel className="form-check-label">
                    {labelToDisplay}
                </StyledLabel>
            )}
            <StyledInputGroup
                className={`form-check form-check-radio mr-3 ${
                    filter ? 'justify-content-between' : 'form-check-inline'
                }`}>
                {options.map((option, i) => (
                    <label key={'radio' + i} className="form-check-label ">
                        <input
                            className={`form-check-input ${showErrorClass}`}
                            type="radio"
                            name={name}
                            checked={selected === option.value}
                            onChange={(ev) => onChange(option.value)}
                        />
                        <span className="form-check-sign">{option.label}</span>
                    </label>
                ))}
            </StyledInputGroup>
        </Container>
    )
}

export default RadioButtonsInput

const Container = styled.div`
    display: flex;
    flex-direction: column;
    ${({noBorder}) => noBorder && 'border: none !important'};
`

const StyledLabel = styled(Label)`
    text-transform: uppercase;
`
const StyledInputGroup = styled(InputGroup)`
    display: flex;
    justify-content: space-between;
`
