import {styled} from 'common'
import UserParticipants from 'assets/img/users-participants.svg'
import {CardTitle} from 'components/general/Control'

import StageIcon from 'assets/img/streak.svg'

const PlanStageDistribution = ({
    echartsDom,
    totalParticipant = 0,
    totalStage = 0,
}) => {
    return (
        <Cards className="bg-white mt-4 mb-4 p-3">
            <CardTitles>Plan Stages Distribution</CardTitles>
            <hr />
            <Echarts ref={echartsDom} />
            <ContainerStage>
                <CardInfoStage className="p-3 d-flex flex-row justify-content-between">
                    <div className="d-flex flex-row">
                        <img
                            src={UserParticipants}
                            width={20}
                            height={20}
                            alt="userparticipants"
                            className="align-self-center mr-2"
                        />
                        <div>
                            <TitleInfoStage>Total Participants</TitleInfoStage>
                            <ContentInfoStage>
                                {totalParticipant} Participants
                            </ContentInfoStage>
                        </div>
                    </div>
                    <div className="d-flex flex-row">
                        <img
                            src={StageIcon}
                            width={20}
                            height={20}
                            alt="userparticipants"
                            className="align-self-center mr-2"
                        />
                        <div>
                            <TitleInfoStage>Total Stages</TitleInfoStage>
                            <ContentInfoStage>
                                {totalStage} Stages
                            </ContentInfoStage>
                        </div>
                    </div>
                    <div className="d-flex flex-row">
                        <LegendParticipant />
                        <TitleLegendParticipant>
                            Participants
                        </TitleLegendParticipant>
                    </div>
                </CardInfoStage>
            </ContainerStage>
        </Cards>
    )
}

const Cards = styled.div`
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 10px;
    min-height: 500px;
`

const ContainerStage = styled.div`
    display: flex;
    justify-content: center;
`

const CardTitles = styled(CardTitle)`
    font-size: 14px;
    color: black;
    font-family: 'Hanken Grotesk';
    font-weight: 800;
`

const Echarts = styled.div`
    width: 100%;
    height: 400px;
`

const CardInfoStage = styled.div`
    width: 82%;
    align-self: center;
    border-radius: 8px;
    background-color: rgba(247, 247, 247, 1);
`

const TitleInfoStage = styled.p`
    font-size: 10px;
    color: black;
    align-self: center;
    font-weight: 400;
    font-family: 'Hanken Grotesk';
`

const ContentInfoStage = styled.p`
    font-size: 12px;
    font-weight: bold;
    color: black;
    font-weight: 600;
    font-family: 'Hanken Grotesk';
`

const LegendParticipant = styled.div`
    width: 15px;
    height: 15px;
    align-self: center;
    background-color: rgba(140, 226, 230, 1);
`

const TitleLegendParticipant = styled.p`
    font-size: 12px;
    color: black;
    align-self: center;
    margin-left: 10px;
    font-family: 'Hanken Grotesk';
`

export default PlanStageDistribution
