import {React, styled} from 'common'
import {useState, useHistory} from 'services/hooks'

const getIsFlexStart = (cell) =>
    ['name', 'display_name', 'first_name', 'last_name'].includes(
        cell?.column?.id
    )

const Active = () => {
    return (
        <i
            defaultValue={'active'}
            className={'tim-icons icon-check-2 text-success'}
        />
    )
}
const Inactive = () => {
    return (
        <i
            defaultValue={'inactive'}
            className={'tim-icons icon-simple-remove text-danger'}
        />
    )
}

const TableBodyV2 = ({
    page,
    getTableBodyProps,
    prepareRow,
    isRowHover = true,
}) => {
    const history = useHistory()
    const [hoverdRow, setHoverdrow] = useState(null)

    const onClick = (cell) => {
        if (!cell.column.isDownloadable) {
            const data = {}

            Object.keys(cell.row.original).forEach((value) => {
                if (typeof cell.row.original[value] !== 'object')
                    return (data[value] = cell.row.original[value])
                return (data[value] =
                    cell.row.original[value].props?.value || null)
            })
            history.push(cell.row.original.pathname, data)
        }
    }

    const renderCell = (cell) => {
        if (
            ['Active', 'New Version', 'Deleted'].some(
                (header) => header === cell.column.Header
            )
        ) {
            return cell.render(cell.value === 'true' ? Active : Inactive)
        }

        return cell.render('Cell')
    }

    return (
        <tbody onMouseOut={() => setHoverdrow(null)} {...getTableBodyProps()}>
            {page.map((row, i) => {
                prepareRow(row)
                const rowProps = row.getRowProps()
                return (
                    <StyledTr
                        onMouseOver={() => setHoverdrow(rowProps.key)}
                        {...rowProps}
                        hoverd={
                            isRowHover ? rowProps.key === hoverdRow : false
                        }>
                        {row.cells.map((cell) => {
                            const flexStart = getIsFlexStart(cell)
                            return (
                                <StyledTd
                                    flexStart={flexStart}
                                    {...cell.getCellProps()}
                                    onClick={() => {
                                        onClick(cell)
                                    }}>
                                    {renderCell(cell)}
                                    {/* {console.log(cell)}
                                    {cell.render('Cell')} */}
                                </StyledTd>
                            )
                        })}
                    </StyledTr>
                )
            })}
        </tbody>
    )
}

export default TableBodyV2

const StyledTr = styled.tr`
    opacity: ${(p) => (p.hoverd ? '0.5' : '1')};
    cursor: pointer;
`
const StyledTd = styled.td`
    padding-left: 20px !important;
    text-align: ${({flexStart}) => (flexStart ? 'start' : 'center')};
`
