import {createApi} from '@reduxjs/toolkit/query/react'

import {
    transformResponseFromData,
    provideTags,
    provideTag,
    axiosBaseQuery,
} from 'store/common'

const trainerApi = createApi({
    baseQuery: axiosBaseQuery(),
    reducerPath: 'trainer',
    tagTypes: ['Trainer'],
    endpoints: (build) => ({
        getTrainers: build.query({
            query: () => ({url: 'admin/trainer', method: 'GET'}),
            transformResponse: transformResponseFromData,
            providesTags: (data) => provideTags('Trainer', data),
        }),
        getTrainer: build.query({
            query: ({id, language}) => ({
                url: `admin/trainer/${id}/${language}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('Trainer', data, '_id')],
        }),
        saveTrainer: build.mutation({
            query: (body) => ({
                url: `/admin/trainer`,
                method: 'PUT',
                body,
            }),
            transformResponse: transformResponseFromData,
            invalidatesTags: (data) => [provideTag('Trainer', data, '_id')],
        }),
    }),
})
export default trainerApi

export const {useGetTrainersQuery, useGetTrainerQuery, useSaveTrainerMutation} =
    trainerApi
