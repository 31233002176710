import MultiCheckboxSelection from 'components/general/Control/MultiCheckboxSelection'
import {trainingGoals} from 'constant/workout'
import {Col} from 'reactstrap'

const OverallTrainingGoal = ({required, showError, selected, updateField}) => {
    return (
        <Col sm="12" lg="6" md="6" className="pl-0 pr-0 pr-sm-0 pr-md-3">
            <MultiCheckboxSelection
                required={required}
                showError={showError}
                label={'overall training goal'}
                options={trainingGoals}
                selected={selected}
                onChange={(value) => updateField('overall_goals', value)}
                selectionLimit={4}
            />
        </Col>
    )
}

export default OverallTrainingGoal
