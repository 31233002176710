import RoutineDetail from 'components/Routine/RoutineDetail'
import MessageAlert from 'components/general/Display/MessageAlert'
import {DetailsWrapper, Page, PageContent} from 'components/general/Page'
import {useMemo, useState} from 'react'

const alertTextsMap = {
    showError: {
        title: 'Missing fields',
        mainText: 'Please fill in all the missing fields before saving',
    },
    showInvalidNameError: {
        title: 'DHR name problem',
        mainText: 'This name is already exist please select another one',
    },
    showSelectedWorkoutsError: {
        title: 'Workouts list',
        mainText: 'Workouts list must contain at least one workout',
    },
}

function Routine() {
    const [showAlert, setShowAlert] = useState('')
    const [errorType, setErrorType] = useState(null)
    const alertInfo = useMemo(() => alertTextsMap[errorType], [errorType])

    const onError = (error) => {
        setShowAlert('message')
        setErrorType(error)
    }

    return (
        <Page>
            <PageContent>
                <DetailsWrapper
                    header="routine detail"
                    backButtonText="Back to Routines">
                    <RoutineDetail onError={onError} />
                </DetailsWrapper>
            </PageContent>
            <MessageAlert
                showAlert={showAlert === 'message'}
                title={alertInfo?.title || ''}
                mainText={alertInfo?.mainText || ''}
                confirmBtnText="OK"
                onConfirm={() => setShowAlert('')}
            />
        </Page>
    )
}

export default Routine
