import backgroundMusicSelection from './BackgroundMusicSelection'
import bodyRegionsFocus from './BodyRegionsFocus'
import workoutImages from './WorkoutImages'
import workoutObjective from './WorkoutObjective'

export const WorkoutObjective = workoutObjective

export const WorkoutImages = workoutImages
export const BodyRegionsFocus = bodyRegionsFocus
export const BackgroundMusicSelection = backgroundMusicSelection
