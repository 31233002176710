import {Row, Col} from 'components/general/Control'
import {orientationToAnchor} from 'constant/exercise'
import SelectInput from 'components/general/Control/SelectInput'

const OrientationToAnchor = ({
    showError,
    anchorOrientation,
    setAnchorOrientation,
}) => {
    return (
        <Row form>
            <Col md={4}>
                <SelectInput
                    required
                    showError={showError}
                    label={'orientation to anchor'}
                    options={orientationToAnchor}
                    name={'orientation_to_anchor'}
                    selected={anchorOrientation}
                    onChange={setAnchorOrientation}
                />
            </Col>
        </Row>
    )
}

export default OrientationToAnchor
