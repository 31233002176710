import {React, styled} from 'common'
import {useEffect, useRef, useState} from 'react'

function CompositeInput({value, staticValue, setter, showError, setShowError}) {
    const [isFocused, setIsFocused] = useState(false)
    const editableRef = useRef(null)

    useEffect(() => {
        if (editableRef.current) {
            editableRef.current.textContent = value
        }
    }, [value])

    const handleInput = (e) => {
        const newContent = e.target.textContent
        setter(newContent)
    }

    const handleContainerClick = () => {
        editableRef.current.focus()
        setTimeout(() => {
            const el = editableRef.current
            const range = document.createRange()
            const sel = window.getSelection()
            el.focus()
            range.selectNodeContents(el)
            range.collapse(false)
            sel.removeAllRanges()
            sel.addRange(range)
        }, 0)
    }

    return (
        <Container
            isFocused={isFocused}
            showError={showError}
            onMouseDown={handleContainerClick}
            onBlur={() => setIsFocused(false)}>
            <Editable
                ref={editableRef}
                contentEditable
                onFocus={() => {
                    setShowError(false)
                    setIsFocused(true)
                }}
                onInput={handleInput}
            />{' '}
            {staticValue}
        </Container>
    )
}

export default CompositeInput
const Container = styled.div`
    ${({marginbottom}) =>
        marginbottom >= 0 && `margin-bottom: ${marginbottom}px !important`};
    border: 1px solid;
    border-color: ${({isFocused, showError}) => {
        if (isFocused) {
            return '#07F2C3'
        } else if (showError) {
            return '#D72F3F'
        } else {
            return '#2B3553'
        }
    }};
    border-radius: 0.25rem;

    display: block;
    width: 100%;
    height: calc(2.249999625rem + 2px);
    padding: 0.5rem 0.7rem;
    font-size: 0.7500000025rem;
    font-weight: 400;
    line-height: 1.428571;
    color: rgba(255, 255, 255, 0.8);
    background-clip: padding-box;
    box-shadow: none;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
`

const Editable = styled.div`
    display: inline-block;
    min-width: 10px;
    :focus {
        outline: none;
    }
`
