/* eslint-disable no-unused-vars */

import React from 'react'
import {FormGroup} from 'reactstrap'
import CheckboxOption from './CheckboxOption'

const SubMenuFilterItem = ({
    item,
    selectedItems,
    setSelectedItems,
    removeFilterLabel,
    isItemSelected,
}) => {
    const isChildSelected = (child) =>
        isItemSelected(item.label, selectedItems, true, child)
    return (
        <FormGroup data-type="filter-drop-down">
            {item.children.map((child, i) => {
                const isSelected = isChildSelected(child)
                return (
                    <CheckboxOption
                        child={child}
                        label={item.label}
                        isSelected={isSelected}
                        onSelect={setSelectedItems}
                        key={`dropitem ${child + i}`}
                        selectedItems={selectedItems}
                        removeFilterLabel={removeFilterLabel}
                    />
                )
            })}
        </FormGroup>
    )
}

export default SubMenuFilterItem
