/* eslint-disable no-unused-vars */

import {Col, Row} from 'components/general/Control'
import AppInput from 'components/general/Control/Input'
import styled from 'styled-components'

const FilterWorkouts = ({searchText, setSearchText}) => {
    return (
        <Row className="d-flex justify-content-beteween mb-2">
            <StyledInput sm={12} md={12} lg={12}>
                <AppInput
                    // inputFocused={inputFocused}
                    // setInputFocused={setInputFocused}
                    name="filter_exercises"
                    placeholder="Search. . ."
                    value={searchText}
                    setter={setSearchText}
                />
            </StyledInput>
        </Row>
    )
}

export default FilterWorkouts

const StyledInput = styled(Col)`
    flex-grow: ${({typing}) => (typing ? 1 : 0.5)};
    /* transition: all 0.4s ease-in; */
`

const StyledCol = styled(Col)`
    ${({typing}) => typing && 'display:none'};
`
