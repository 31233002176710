import {useEffect} from 'react'
import {FormGroup} from 'components/general/Control'
import RadioButtonsInput from 'components/general/Control/RadioButtonsInput'
import {PROGRAM_FLOW_TYPE_OPTIONS} from 'constant/program'

const FlowType = ({flowType, handleFlowTypeChange}) => {
    useEffect(() => {
        if (!flowType) {
            handleFlowTypeChange(PROGRAM_FLOW_TYPE_OPTIONS[0].value)
        }
    }, [flowType, handleFlowTypeChange])

    return (
        <FormGroup>
            <RadioButtonsInput
                noBorder
                selected={flowType}
                name="flow_type"
                label="Flow Type"
                options={PROGRAM_FLOW_TYPE_OPTIONS}
                onChange={handleFlowTypeChange}
            />
        </FormGroup>
    )
}

export default FlowType
