import page from './Page'
import pageContent from './PageContent'
import spacer from './Spacer'
import message from './Message'
import sidebar from './Sidebar'
import card from './Card'
import detailsCard from './DetailsCard'
import detailsWrapper from './DetailsWrapper'

export const Page = page
export const PageContent = pageContent
export const Spacer = spacer
export const Message = message
export const Sidebar = sidebar
export const Card = card
export const DetailsCard = detailsCard
export const DetailsWrapper = detailsWrapper
