import {React} from 'common'
import {useEffect, useState} from 'services/hooks'
import styled from 'styled-components'
import DraggableItemInput from 'components/Workout/Details/WorkoutBuilder/DraggableItemInput'
import {DEFAULT_VALUES} from '../WorkoutBuilderControls'

import {WORKOUT_TARGETS} from 'constant/workout'
import {AppSelect} from 'components/general/Control'

const DraggableItemForm = ({
    item,
    isChild,
    itemIndex,
    // isSection,
    childIndex,
    defaultSets,
    defaultRest,
    // isTransition,
    selectedItems,
    defaultWork,
    setSelectedItems,
    isCheckboxExercise,
}) => {
    const [updatedItem, setUpdatedItem] = useState({
        ...item,
        target: item?.target || item?.work_time,
    })
    const [isInitialized, setisInitialized] = useState(false)

    useEffect(() => {
        if (!isInitialized && item) {
            setUpdatedItem({...item})
            setisInitialized(true)
        }
    }, [isInitialized, item])

    const updateItemField = (field, value) => {
        if (!isNaN(value)) {
            setUpdatedItem({...updatedItem, [field]: value})
        }
    }

    const onTargetTypeChanged = (e) => {
        const newItem = {...updatedItem, target_type: e.target.value}
        setUpdatedItem(newItem)
        setNewValue(null, newItem)
    }

    const setNewValue = (ev, newUpdatedItem) => {
        //TODO: HANDLE IF USER DRAGGING AFTER FINISH TYPING AND NOT CLICKING OUTSIDE THE INPUT
        const selectedItemsCopy = [...selectedItems]
        const newItem = newUpdatedItem || updatedItem

        if (isChild) {
            const itemsCopy = [...selectedItemsCopy[itemIndex].items]
            itemsCopy.splice(childIndex, 1, newItem)
            selectedItemsCopy[itemIndex].items = itemsCopy
        } else {
            selectedItemsCopy.splice(itemIndex, 1, newItem)
        }
        setSelectedItems([...selectedItemsCopy])
    }

    const exerciseOrChild = isCheckboxExercise || isChild

    const showFields = {
        target: exerciseOrChild,
        rest_time: true,
        sets: exerciseOrChild,
        rounds: isChild && childIndex === 0,
    }

    const defaults = {
        target: defaultWork,
        rest_time: defaultRest,
        sets: defaultSets,
        rounds: 1,
    }

    return (
        <>
            {exerciseOrChild && (
                <Select
                    placeholder={'Type'}
                    value={updatedItem?.target_type}
                    onChange={onTargetTypeChanged}
                    options={WORKOUT_TARGETS}
                    name={'exercise_target_type'}
                />
            )}
            <TimeInputsContainer>
                {Object.keys(showFields).map((fieldName) => (
                    <DraggableItemInput
                        key={'field_form' + fieldName}
                        show={showFields[fieldName]}
                        spacer={!showFields[fieldName]}
                        value={updatedItem[fieldName]}
                        defaultValue={
                            defaults[fieldName] || DEFAULT_VALUES[fieldName]
                        }
                        name={fieldName}
                        setNewValue={setNewValue}
                        updateItemField={updateItemField}
                    />
                ))}
            </TimeInputsContainer>
        </>
    )
}

export default DraggableItemForm

const TimeInputsContainer = styled.div`
    max-width: 191px;
    align-self: baseline;
    margin-top: 4px;
    display: flex;
    align-items: flex-end;
    margin-left: 5px;
    position: relative;
`

const Select = styled(AppSelect)`
    font-size: 0.7rem;
    background: transparent;
    width: 60px;
    text-align: center;
    height: 33px;
    appearance: unset !important;
    padding: 8px;
    margin-inline-start: 5px;
    display: flex;
    align-self: flex-end;
`
