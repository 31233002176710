import {Colors} from 'appearance'
import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {FormGroup, Input, Label, Col} from 'reactstrap'
import {useIsDarkTheme} from 'store/store'
import styled from 'styled-components'

const getSelectedItemValue = (selectedItem = {}) =>
    selectedItem.value || selectedItem.value === 0 ? selectedItem.value : ''

const SelectInput = ({
    label,
    options = [],
    placeholder,
    name,
    selected,
    onChange,
    description,
    showError,
    required,
    className,
    disabled,
    xl = 4,
    lg = 4,
    md = 4,
    sm = 4,
}) => {
    const isDarkTheme = useSelector(useIsDarkTheme)
    const selectedItem = options.find((item) => item?.value === selected)
    const showErrorClass = useMemo(
        () => showError && !selectedItem && 'border-danger',
        [selectedItem, showError]
    )
    const labelToDisplay = required ? label + ' *' : label

    const value = getSelectedItemValue(selectedItem)

    const placeholderText = placeholder || 'Select ' + label

    const dropDownBackground = useMemo(
        () => (isDarkTheme ? Colors.OPAC_DARK : Colors.WHITE),
        [isDarkTheme]
    )

    return (
        <Col xl={xl} lg={lg} md={md} sm={sm} className={'px-0'}>
            <FormGroup className={className}>
                <Container description={description}>
                    {label && (
                        <StyledLabel description={description}>
                            {labelToDisplay}
                        </StyledLabel>
                    )}
                    {description && <Description>{description}</Description>}
                </Container>

                <Input
                    className={`form-control ${showErrorClass} `}
                    name={name}
                    disabled={disabled}
                    onChange={(ev) => onChange(ev.target.value)}
                    placeholder={placeholder}
                    value={value}
                    style={{backgroundColor: dropDownBackground}}
                    type="select">
                    <option value="" disabled>
                        {placeholderText}
                    </option>
                    {options.map((option, i) => (
                        <option
                            key={'select' + i}
                            value={option.value}
                            disabled={option.disabled}>
                            {option.label}
                        </option>
                    ))}
                </Input>
            </FormGroup>
        </Col>
    )
}

export default SelectInput

const StyledLabel = styled(Label)`
    text-transform: uppercase;
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${({description}) => (description ? 10 : 0)}px;
    flex: 1;
`

const Description = styled.span`
    margin-top: -6px;
    display: block;
    font-size: 12px;
`
