import {React, styled} from 'common'
import {useMemo, useEffect, useUrlParams} from 'services/hooks'
import {Table} from 'components/general/Control'
import {
    useFilters,
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from 'react-table'
import DefaultColumnFilterV2 from './DefaultColumnFilter'
import TableHeaderV2 from './TableHeader'
import TableBodyV2 from './TableBody'

const ConnectError = styled.div``

const TableV2 = ({isError, columns, data, metadata, isRowHover = true}) => {
    const [urlParams, setUrlParam] = useUrlParams()

    const {page_size = 10, page_number = 0} = urlParams

    const onSetGoToPage = (value) => {
        setUrlParam('page_number', value)
    }
    const onSetPageSize = (value) => {
        setUrlParam('page_size', value)
        setUrlParam('page_number', 0)
    }
    const nextPage = () => {
        setUrlParam('page_number', Number(page_number) + 1)
    }
    const previousPage = () => {
        setUrlParam('page_number', Number(page_number) - 1)
    }

    const defaultColumn = useMemo(
        () => ({
            Filter: (props) => {
                const {filterValue, setFilter, filter, id} = props.column

                // Ensure visibility and proper initialization
                return DefaultColumnFilterV2({
                    column: {
                        filterValue: filterValue || '', // Ensure filterValue is never undefined
                        totalData: metadata?.totalData,
                        setFilter,
                        id,
                    },
                    visibility: filter === 'default', // Only hide if filter is explicitly not default
                })
            },
        }),
        [metadata?.totalData]
    )

    const defaultSort = useMemo(() => {
        return columns.some((column) => column.accessor === 'name')
            ? 'name'
            : 'first_name'
    }, [columns])

    const initialState = useMemo(
        () => ({
            sortBy: [
                {
                    id: defaultSort,
                    desc: false,
                },
            ],
            filters: [
                {
                    id: 'newVersion',
                    value: 'true',
                },
            ],
            pageIndex: metadata?.page || 0, // Ensure this starts at 0 or a valid initial page index
            pageSize: metadata?.pageSize || 10, // Provide a default pageSize if metadata is not loaded yet
        }),
        [defaultSort, metadata]
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        setPageSize,
        state: {pageIndex, pageSize},
    } = useTable(
        {
            columns,
            data: data,
            initialState,
            defaultColumn,
            manualPagination: true,
            manualFilters: true,
            manualSortBy: true,
            pageCount: Math.floor(metadata.totalData / metadata.pageSize),
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    )
    useEffect(() => {
        if (page_number && +page_number !== pageIndex) {
            gotoPage(+page_number)
        }
        if (page_size && +page_size !== pageSize) {
            setPageSize(+page_size)
        }
    }, [page_number, page_size, pageIndex, pageSize, gotoPage, setPageSize])

    if (isError) {
        return <ConnectError>CONNECTION ERROR</ConnectError>
    }

    return (
        <Table {...getTableProps()}>
            <TableHeaderV2
                headerGroups={headerGroups}
                nextPage={nextPage}
                canNextPage={canNextPage}
                previousPage={previousPage}
                canPreviousPage={canPreviousPage}
                pageIndex={page_number}
                pageSize={page_size}
                setPageSize={onSetPageSize}
                gotoPage={onSetGoToPage}
                pageOptions={pageOptions}
                pageCount={pageCount}
            />
            <TableBodyV2
                page={page}
                getTableBodyProps={getTableBodyProps}
                prepareRow={prepareRow}
                isRowHover={isRowHover}
            />
        </Table>
    )
}

export default TableV2
