import {React} from 'common'
import {Page} from 'components/general/Page'
import {
    TableWrapper,
    SelectColumnFilter,
} from 'components/general/Display/Table'
import api from 'services/api'

import {useGetProgramsQuery} from 'store/store'

import {useMemo, useOnSaveCSV} from 'services/hooks'

const columns = [
    {
        Header: 'Name',
        accessor: 'name',
        filter: 'default',
        isSortableIcon: true,
    },
    {
        Header: 'Type',
        accessor: 'program_type',
        Filter: SelectColumnFilter,
        filter: 'equals',
    },
    {
        Header: 'Author',
        accessor: 'author',
        Filter: SelectColumnFilter,
        filter: 'includes',
        isSortableIcon: true,
    },
    {
        Header: 'Level',
        accessor: 'level',
        Filter: SelectColumnFilter,
        filter: 'includes',
        isSortableIcon: true,
    },
    {
        Header: 'Active',
        accessor: 'activeIcon',
        Filter: SelectColumnFilter,
        filter: 'equals',
    },
    {
        Header: 'New Version',
        accessor: 'newVersion',
        Filter: SelectColumnFilter,
        filter: 'equals',
    },
]

const Programs = () => {
    const programs = useGetProgramsQuery()
    const [isLoading, onSaveCSV] = useOnSaveCSV()

    const data = useMemo(
        () =>
            programs?.data?.map((prop, key) => {
                return {
                    id: key,
                    name: prop.name,

                    _id: prop.id,
                    pathname: `/admin/program/${prop.id}`,

                    author: prop.author,
                    level: String(prop.level),
                    muscles: prop.muscles,
                    active: prop.active,
                    version: prop.version,
                    activeIcon: prop.active ? 'true' : 'false',
                    newVersion: prop.version > 1 ? 'true' : 'false',
                    program_type: prop.program_type,
                }
            }),
        [programs.data]
    )

    return (
        <Page hideSidbar={false}>
            <TableWrapper
                title="Programs"
                columns={columns}
                data={data}
                onSaveCSV={() => onSaveCSV(api.getProgramsCSV(), 'programs')}
                createNew={'/admin/program/new'}
                filterByVersion
                type={'PROGRAMS'}
                isFetching={isLoading}
            />
        </Page>
    )
}

export default Programs
