export const LANGUAGES = [
    {value: 'en', label: 'English'},
    {value: 'ru', label: 'Russian'},
    {value: 'es', label: 'Spanish'},
    {value: 'de', label: 'German'},
    {value: 'fr', label: 'French'},
]

export const LANGUAGES_OBJECT = {
    en: 'English',
    ru: 'Russian',
    es: 'Spanish',
    de: 'German',
    fr: 'French',
}
