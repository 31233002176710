import {React, styled} from 'common'
import classNames from 'classnames'

import {Button, NavbarBrand, Container} from 'components/general/Control'

const SidebarHandle = ({sidebarOpened, toggleSidebar, activeRoute}) => {
    return (
        <StyledContainer>
            <MenuIconDesktop className="navbar-minimize d-inline">
                <Button
                    className="minimize-sidebar btn-just-icon"
                    color="link"
                    id="tooltip209599"
                    onClick={toggleSidebar}>
                    <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
                    <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
                </Button>
                {/* <UncontrolledTooltip
                    delay={0}
                    target="tooltip209599"
                    placement="right">
                    Sidebar toggle
                </UncontrolledTooltip> */}
            </MenuIconDesktop>
            <MenuIconMobile
                className={classNames('navbar-toggle d-inline', {
                    toggled: sidebarOpened,
                })}>
                <button
                    className="navbar-toggler btn-just-icon"
                    type="button"
                    onClick={toggleSidebar}>
                    <i className="navbar-toggler-bar bar1" />
                    <i className="navbar-toggler-bar bar2" />
                    <i className="navbar-toggler-bar bar3" />
                </button>
            </MenuIconMobile>
            <NavbarBrand href="#hyfit" onClick={(e) => e.preventDefault()}>
                {activeRoute}
            </NavbarBrand>
        </StyledContainer>
    )
}
export default SidebarHandle

const MenuIconDesktop = styled.div``
const MenuIconMobile = styled.div``
const StyledContainer = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
`
