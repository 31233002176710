import {Col, FormGroup} from 'components/general/Control'
import RadioButtonsInput from 'components/general/Control/RadioButtonsInput'
import {workoutTypeOptions} from 'constant/workout'
import React from 'react'

const WorkoutTypeSelection = ({
    workoutType,
    setWorkoutType,
    showError,
    seletedExercises = [],
    onShowClassError,
}) => {
    const isContainsGroupBeforeChange = (type) => {
        if (type === 'class') {
            const group = seletedExercises.find(
                (item) => item.activity_type === 'group'
            )
            if (group) {
                return onShowClassError && onShowClassError()
            }
        }
        setWorkoutType(type)
    }
    return (
        <Col md={6} className="px-0  pr-md-3">
            <FormGroup>
                <RadioButtonsInput
                    options={workoutTypeOptions}
                    showError={showError}
                    name={'workout_type'}
                    selected={workoutType || workoutTypeOptions[0].value}
                    label={'Workout Type'}
                    onChange={isContainsGroupBeforeChange}
                />
            </FormGroup>
        </Col>
    )
}
export default WorkoutTypeSelection
