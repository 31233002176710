import React from 'react'
import {FormGroup, Label} from 'reactstrap'
import styled from 'styled-components'

const SingleCheckbox = ({
    description,
    label,
    option,
    name,
    onChange,
    fontSize,
    disabled,
}) => {
    return (
        <Container>
            {label && (
                <StyledLabel className={'form-label'}>{label}</StyledLabel>
            )}
            {description && <Description>{description}</Description>}
            <FormGroup check disabled={disabled}>
                <label className="form-check-label">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name={name}
                        checked={option.value}
                        onChange={(ev) => {
                            onChange(!option.value)
                        }}
                        disabled={disabled}
                    />
                    <span style={{fontSize}} className="form-check-sign">
                        {option.label}
                    </span>
                </label>
            </FormGroup>
        </Container>
    )
}
export default SingleCheckbox

const Container = styled.div`
    display: flex;
    flex-direction: column;
`

const Description = styled.span`
    margin-top: -6px;
    display: block;
    font-size: 12px;
`
const StyledLabel = styled(Label)`
    text-transform: uppercase;
`
