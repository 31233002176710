import {React, styled} from 'common'

import {PageContent} from 'components/general/Page'
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
} from 'components/general/Control'

import Table from 'components/general/Display/Table/Table'
import TableWrapperButtons from 'components/general/Display/Table/TableWrapperButtons'
import Spinner from 'components/general/Display/Spinner'

const TableWrapper = ({
    title,
    data,
    columns,
    onSaveCSV,
    createNew,
    isError,
    filterByVersion,
    type,
    isFetching = false,
    setTableParams,
    cerateNewText,
    isRowHover = true,
}) => {
    return (
        <PageContent>
            <Card>
                <StyledCardHeader>
                    <Col>
                        <CardTitle className="h4">{title}</CardTitle>
                    </Col>
                    {!isFetching && (
                        <TableWrapperButtons
                            onSaveCSV={onSaveCSV}
                            createNew={createNew}
                            cerateNewText={cerateNewText}
                        />
                    )}
                </StyledCardHeader>

                <CardBody>
                    {!isFetching && data ? (
                        <Table
                            columns={columns}
                            data={data}
                            isError={isError}
                            filterByVersion={filterByVersion}
                            type={type}
                            setTableParams={setTableParams}
                            isRowHover={isRowHover}
                        />
                    ) : (
                        <Spinner />
                    )}
                </CardBody>
            </Card>
        </PageContent>
    )
}

export default TableWrapper

const StyledCardHeader = styled(CardHeader)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
