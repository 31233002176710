import {React, styled} from 'common'

import {Button, AppSelect} from 'components/general/Control'

const TablePagination = ({
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageIndex,
    setPageSize,
    gotoPage,
    pageOptions,
    pageSize = 10,
}) => {
    const pageSizeSelectValues = [
        {value: 5, label: '5 rows'},
        {value: 10, label: '10 rows'},
        {value: 15, label: '15 rows'},
        {value: 20, label: '20 rows'},
        {value: 25, label: '25 rows'},
        {value: 50, label: '50 rows'},
        {value: 100, label: '100 rows'},
    ]
    return (
        <tr role="row">
            <th role="columnheader" colSpan={20}>
                <StyledWrapper>
                    <Button
                        className="btn btn-success btn-md"
                        disabled={!canPreviousPage}
                        onClick={previousPage}>
                        Previous
                    </Button>
                    <PageSelect
                        color={'true'}
                        className={'form-control'}
                        type="select"
                        value={pageIndex}
                        onChange={({target}) => gotoPage(+target.value)}
                        options={pageOptions.map((option) => ({
                            value: option,
                            label: option + 1,
                        }))}
                    />
                    <PageSelect
                        className={'form-control'}
                        value={pageSize}
                        onChange={({target}) => setPageSize(+target.value)}
                        options={pageSizeSelectValues}
                    />

                    <Button
                        className="btn btn-success btn-md"
                        disabled={!canNextPage}
                        onClick={nextPage}>
                        Next
                    </Button>
                </StyledWrapper>
            </th>
        </tr>
    )
}

export default TablePagination

const StyledWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin: 25px 0;
`
const PageSelect = styled(AppSelect)`
    width: 150px;
`
