import React from 'react'
import {Col, FormGroup, Row} from 'components/general/Control'
import {adjusterPositionTypes} from 'constant/exercise'
import RadioButtonsInput from 'components/general/Control/RadioButtonsInput'

export default function AdjusterPosition({
    showError,
    adjusterPosition,
    setAdjusterPosition,
}) {
    return (
        <Row>
            <Col className="checkbox-radios py-2 pr-md-0" sm="12" md="6">
                <FormGroup>
                    <RadioButtonsInput
                        required
                        showError={showError}
                        name={'adjuster_position'}
                        selected={adjusterPosition}
                        onChange={setAdjusterPosition}
                        options={adjusterPositionTypes}
                        label={'Gear 1 Adjuster Position'}
                    />
                </FormGroup>
            </Col>
        </Row>
    )
}
