import {styled} from 'common'
import Colors from 'appearance/colors'
import 'assets/css/fonts.css'

const RegularText = styled.span`
    font-family: Heebo;
`

const MediumText = styled.span`
    font-family: Heebo;
    font-weight: 500;
`

const BoldText = styled.span`
    font-family: Heebo;
    font-weight: bold;
`

const Title = styled(MediumText)`
    color: ${Colors.APP_WHITE};
    font-size: 24px;
    font-family: Poppins;
`
const SecondTitle = styled(MediumText)`
    color: ${Colors.APP_WHITE};
    font-size: 18px;
    font-family: Poppins;
`

const Texts = {RegularText, MediumText, BoldText, Title, SecondTitle}
export default Texts
