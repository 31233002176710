import {configureStore} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/query'

// import {composeWithDevTools} from 'redux-devtools-extension'
// import {composeWithDevTools} from 'redux-devtools-extension'

// import logger from 'redux-logger'

// import {createLogger} from 'redux-logger'

import authReducer from './slices/auth'
import appReducer from './slices/app'
import userApi from './slices/user'
import exerciseApi from './slices/exercise'
import workoutApi from './slices/workout'
import programApi from './slices/program'
import trainerApi from './slices/trainer'
import catalogApi from './slices/catalog'
import couponsApi from './slices/coupons'
import routineApi from './slices/routine'

// create and filter specific actions
// const logger = createLogger({
//     predicate: (getState, action) => action.type !== 'SET_PROCESSING',
// })

const store = configureStore({
    reducer: {
        app: appReducer,
        auth: authReducer,
        user: userApi.reducer,
        exercise: exerciseApi.reducer,
        workout: workoutApi.reducer,
        program: programApi.reducer,
        trainer: trainerApi.reducer,
        catalog: catalogApi.reducer,
        coupons: couponsApi.reducer,
        routine: routineApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            // .concat(logger)
            .concat(userApi.middleware)
            .concat(exerciseApi.middleware)
            .concat(workoutApi.middleware)
            .concat(programApi.middleware)
            .concat(trainerApi.middleware)
            .concat(catalogApi.middleware)
            .concat(couponsApi.middleware)
            .concat(routineApi.middleware),
    // devTools: process.env.NODE_ENV !== 'production',
})

setupListeners(store.dispatch)

// // Infer the `RootState` and `AppDispatch` types from the store itself
// export const RootState = typeof store.getState
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export const AppDispatch = typeof store.dispatch

export * from './slices/app'
export * from './slices/auth'
export * from './thunk/auth'
export * from './slices/user'
export * from './slices/exercise'
export * from './slices/workout'
export * from './slices/program'
export * from './slices/trainer'
export * from './slices/catalog'
export * from './slices/coupons'
export * from './slices/routine'

export default store
