import PropTypes from 'prop-types'
import ReactBSAlert from 'react-bootstrap-sweetalert'

/**
 * Renders a message alert component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.showAlert - Determines whether to show the alert or not.
 * @param {string} props.title - The title of the alert.
 * @param {string} props.mainText - The main text of the alert.
 * @param {string} props.confirmBtnText - The text for the confirm button.
 * @param {function} props.onConfirm - The callback function to be executed when the confirm button is clicked.
 * @returns {JSX.Element|null} The rendered message alert component or null if showAlert is false.
 */
function MessageAlert({showAlert, title, mainText, confirmBtnText, onConfirm}) {
    if (showAlert) {
        return (
            <ReactBSAlert
                error
                style={{display: 'block', marginTop: '-100px'}}
                title={title}
                onConfirm={onConfirm}
                confirmBtnText={confirmBtnText}>
                {mainText}
            </ReactBSAlert>
        )
    }

    return null
}

MessageAlert.propTypes = {
    showAlert: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    mainText: PropTypes.string.isRequired,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
}

export default MessageAlert
