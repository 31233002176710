import {Col, FormGroup} from 'components/general/Control'
import SelectInput from 'components/general/Control/SelectInput'
import {caloriesMETOptions} from 'constant/exercise'
import React from 'react'

const CaloriesMETcategory = ({selected, onChange, showError}) => {
    return (
        <Col md={6} className="px-0">
            <FormGroup>
                <SelectInput
                    required
                    selected={selected ? +selected : selected}
                    onChange={onChange}
                    showError={showError}
                    options={caloriesMETOptions}
                    label="Calories MET Category"
                />
            </FormGroup>
        </Col>
    )
}
export default CaloriesMETcategory
