import {useMemo} from 'react'
import {Row} from 'reactstrap'
import WorkoutBuilderControlButton from './WorkoutBuilderControlButton'

const TRANSITION_ITEM = {label: 'Change anchor point', value: 'transition'}

const WorkoutBuilderControlButtons = ({
    isClass,
    unGroup,
    groupItems,
    checkboxList,
    onAddSpecialItem,
}) => {
    const isGroup =
        checkboxList.length === 1 && checkboxList[0].activity_type === 'group'

    const handleGroupClick = () => {
        isGroup ? unGroup() : groupItems()
    }

    const groupButtonLabel = useMemo(
        () => (isGroup ? 'UNGROUP' : 'GROUP'),
        [isGroup]
    )

    const controlData = [
        {
            type: 'button',
            padding: 10,
            name: groupButtonLabel,
            onClick: handleGroupClick,
        },
        {
            type: 'dropdown',
            onClick: (item) => onAddSpecialItem(item, 'section'),
            name: 'SECTION',
        },
        {
            type: 'button',
            padding: 10,
            name: 'CHANGE ANCHOR POINT',
            onClick: () => onAddSpecialItem(TRANSITION_ITEM, 'transition'),
        },
    ]
    return (
        <Row className="position-relative mb-0 mx-0">
            {controlData.map((button, i) => {
                const {type, padding, name, onClick} = button || {}
                return (
                    <WorkoutBuilderControlButton
                        key={'builder_controls' + i}
                        type={type}
                        padding={padding}
                        name={name}
                        onClick={onClick}
                        hideButtons={isClass}
                    />
                )
            })}
        </Row>
    )
}

export default WorkoutBuilderControlButtons
