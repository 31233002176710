import {React} from 'common'
import {Page} from 'components/general/Page'
import {TableWrapper} from 'components/general/Display/Table'

import {useGetResellerPagesQuery} from 'store/store'

import {useMemo} from 'services/hooks'

const columns = [
    {
        Header: 'Reseller Name',
        accessor: 'name',
        filter: 'default',
        isSortableIcon: true,
    },
]

const Resellers = () => {
    const resellers = useGetResellerPagesQuery('en', {
        refetchOnMountOrArgChange: true,
    })

    const data = useMemo(
        () =>
            resellers?.data?.map((prop, key) => {
                return {
                    id: prop._id,
                    name: prop.name,
                    pathname: `/admin/reseller/${prop._id}`,
                    _id: prop._id,
                    labelsCount: prop.labels?.length,
                }
            }),
        [resellers.data]
    )

    return (
        <Page hideSidbar={false}>
            <TableWrapper
                title="Resellers"
                columns={columns}
                data={data}
                createNew={'/admin/reseller/new'}
                isError={resellers?.isError}
            />
        </Page>
    )
}

export default Resellers
