import React from 'react'
import {WorkoutImages} from 'components/Workout/Details'

import styled from 'styled-components'
import {Row} from 'reactstrap'
import {Language} from 'components/general/Display'
import Instructions from 'components/Details/Instructions'
import NameAndDisplayName from 'components/Details/NameAndDisplayName'
import ProgramType from './ProgramType'
import {PROGRAM_TYPE_B2C, PROGRAM_TYPE_CORPORATE} from 'constant/program'
import CorporateFields from './CorporateFields'

const ProgramGeneralContent = ({
    setImageFile,
    setLongImage,
    setSmallImage,
    imageFile,
    longImage,
    smallImage,
    language,
    setLanguage,
    showError,
    isNewProgram,
    showDraftError,
    showNameError,
    updatedProgram,
    updateProgramField,
    showCorporateError,
}) => {
    const {name, display_name, instructions, program_type} =
        updatedProgram || {}

    return (
        <Container>
            <Row className="justify-content-between align-items-start px-3">
                <ProgramType
                    type={program_type}
                    handleProgramTypeChange={(v) =>
                        updateProgramField('program_type', v)
                    }
                />
                <Language
                    flexEnd
                    sm={6}
                    hide={isNewProgram}
                    className={'px-0'}
                    language={language}
                    setLanguage={setLanguage}
                />
            </Row>
            <NameAndDisplayName
                nameError={showNameError}
                className={'px-3'}
                label={'PROGRAM'}
                name={name || ''}
                setName={(value) => updateProgramField('name', value)}
                displayName={display_name}
                setDisplayName={(value) =>
                    updateProgramField('display_name', value)
                }
                showError={showDraftError || showError}
            />

            <Instructions
                className="col-md-6 col-lg-12"
                instructions={instructions}
                setInstractions={(value) =>
                    updateProgramField('instructions', [value])
                }
            />

            {program_type === PROGRAM_TYPE_CORPORATE && (
                <CorporateFields
                    showError={showCorporateError}
                    updatedProgram={updatedProgram}
                    updateProgramField={updateProgramField}
                />
            )}
            {program_type === PROGRAM_TYPE_B2C && (
                <WorkoutImages
                    imageFile={imageFile}
                    setImageFile={setImageFile}
                    longImage={longImage}
                    setLongImage={setLongImage}
                    smallImage={smallImage}
                    setSmallImage={setSmallImage}
                    showError={showError}
                />
            )}
        </Container>
    )
}

export default ProgramGeneralContent

const Container = styled.div``
