import {createApi} from '@reduxjs/toolkit/query/react'
import {axiosBaseQuery} from 'store/common'

const transformResponseFromData = (response) => response.data
const transformResponseFromMessage = (response) => response.message

const provideTags = (tagName, data) =>
    data
        ? [
              ...data.map(({id}) => ({type: tagName, id})),
              {type: tagName, id: 'LIST'},
          ]
        : [{type: tagName, id: 'LIST'}]

const provideTag = (tagName, data, tagField) =>
    data ? {type: tagName, id: data[tagField]} : {}

const exerciseApi = createApi({
    baseQuery: axiosBaseQuery(),

    reducerPath: 'exercise',
    tagTypes: ['Exercise', 'Csv', 'AssosiatedWorkouts'],
    endpoints: (build) => ({
        getExercises: build.query({
            query: () => ({
                url: '/admin/program/workout/exercise',
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => provideTags('Exercise', data?.data),
        }),
        getExercise: build.query({
            query: ({id, language}) => ({
                url: `/admin/program/workout/exercise/${id}/${language}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('Exercise', data, 'id')],
        }),

        saveExercise: build.mutation({
            query: ({exercise, id}) => ({
                url: id
                    ? `/admin/program/workout/exercise/${id}`
                    : '/admin/program/workout/exercise',
                method: id ? 'PUT' : 'POST',
                body: exercise,
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('Exercise', data, 'id')],
            invalidatesTags: (result, error, data) => [
                {type: 'Exercise', id: data?.id},
                {type: 'Exercise', id: 'LIST'},
            ],
        }),
        getExercisesSpreadsheet: build.query({
            query: ({id, language}) => ({
                url: '/admin/program/workout/exercise/csv',
                method: 'GET',
            }),
            transformResponse: transformResponseFromMessage,
            providesTags: (data) => provideTags('Csv', data?.data),
        }),
        saveExerciseTranslation: build.mutation({
            query: (body) => ({
                url: '/admin/translation/',
                method: 'POST',
                body,
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('Exercise', data, 'id')],
            invalidatesTags: (result, error, data) => [
                {type: 'Exercise', id: data?.id},
            ],
        }),
        deleteExercise: build.mutation({
            query: (id) => ({
                url: `/admin/program/workout/exercise/${id}`,
                method: 'DELETE',
            }),
            transformResponse: transformResponseFromData,
            invalidatesTags: (result, error, data) => [
                {type: 'Exercise', id: data?.id},
            ],
        }),
        getAssosiatedWorkouts: build.query({
            query: (id) => ({
                url: `/admin/program/workout/${id}/assosiatedExercises`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) =>
                provideTags('AssosiatedWorkouts', data?.data),
        }),
    }),
})
export default exerciseApi

export const {
    useGetExerciseQuery,
    useGetExercisesQuery,
    useSaveExerciseMutation,
    useGetExercisesSpreadsheetQuery,
    useSaveExerciseTranslationMutation,
    useDeleteExerciseMutation,
    useGetAssosiatedWorkoutsQuery,
} = exerciseApi
