const OSL = 'osl'
const YUNXI = 'yunxi'
const JOINTCORP = 'jointcorp'
const HYGEAR = 'hygear'

const GEAR = 'gear'
const GEARGO = 'gearGo'
const HYROPE = 'rope'
const HYBAR = 'bar'
const GEARX = 'gearX'
const HYWATCH = 'watch'

export const manufacturers = [
    {label: OSL, value: OSL},
    {label: YUNXI, value: YUNXI},
    {label: JOINTCORP, value: JOINTCORP},
    {label: HYGEAR, value: HYGEAR},
]

export const deviceTypesByManufacturers = {
    [OSL]: {
        [GEAR]: ['bootloader', 'firmware'],
        [HYBAR]: ['bootloader', 'firmware'],
    },
    [YUNXI]: {
        [GEAR]: ['both'],
        [GEARGO]: ['both'],
    },
    [JOINTCORP]: {
        [HYROPE]: ['both'],
        [HYWATCH]: ['both'],
    },
    [HYGEAR]: {
        [GEAR]: ['bootloader', 'firmware'],
        [HYBAR]: ['bootloader', 'firmware'],
        [GEARGO]: ['bootloader', 'firmware'],
        [GEARX]: ['bootloader', 'firmware'],
    },
}
