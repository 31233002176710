import {styled} from 'common'

const PageContent = styled.div`
    padding: 80px 30px 80px 130px;
    @media (max-width: 1200px) {
        padding: 80px 10px 80px;
    }
    flex: 1;
`
export default PageContent
