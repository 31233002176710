import {useMemo, useRef, useEffect} from 'react'
import {styled} from 'common'
import {Row} from 'reactstrap'
import {useGetCatalogPageQuery, useGetCatalogsPagesQuery} from 'store/store'
import {formatDataToDragAndDrop} from 'services/utils'
import DragAndDrop from 'components/general/Control/DragAndDrop'
import {Animations} from 'appearance'

const LabelsDragAndDrop = ({
    hide,
    selectedTags,
    setSelectedTags,
    showSelectedTagsError,
}) => {
    const {data: pagesData} = useGetCatalogsPagesQuery(null, {
        skip: hide,
        refetchOnMountOrArgChange: true,
    })

    const mainPgae = pagesData?.pages?.find((page) => page.name === 'main')

    const {data: labelsCatalog} = useGetCatalogPageQuery(mainPgae?._id, {
        skip: hide || !mainPgae,
        refetchOnMountOrArgChange: true,
    })

    const tagListRef = useRef()

    const availableLabels = useMemo(
        () => formatDataToDragAndDrop(labelsCatalog?.available_labels) || [],
        [labelsCatalog]
    )

    useEffect(() => {
        if (showSelectedTagsError && selectedTags.length < 1) {
            tagListRef.current.scrollIntoView({
                behavior: 'auto',
                block: 'start',
            })
        }
    }, [selectedTags.length, showSelectedTagsError])

    if (hide) {
        return null
    }
    return (
        <LabelsRow ref={tagListRef} error={showSelectedTagsError}>
            <Row className="flex-fill px-3">
                <DragAndDrop
                    selectedItems={selectedTags}
                    setSelectedItems={setSelectedTags}
                    collection={availableLabels}
                    dragTitle={'Available Tags'}
                    dropTitle={'Workouts Tags'}
                />
            </Row>
        </LabelsRow>
    )
}

export default LabelsDragAndDrop

const LabelsRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    ${({error}) => error && Animations.flickerAnimation};
`
