import {createAsyncThunk} from '@reduxjs/toolkit'
import api from 'services/api'

export const sendOTPToMail = createAsyncThunk(
    'auth/sendOTPToMail',
    async (body) => {
        try {
            return await api.sendOTPToMail(body)
        } catch (error) {
            console.error('send OTP error', error)
            throw error
        }
    }
)

export const verifyOTP = createAsyncThunk('auth/verifyOTP', async (body) => {
    try {
        return await api.verifyOTP(body)
    } catch (error) {
        console.error('verify OTP error', error)
        throw error
    }
})
