import {React} from 'common'
import {Page, PageContent} from 'components/general/Page'
import {Col, Row} from 'components/general/Control'
import {DashboardCard} from 'components/views/Dashboard'

import {useStatsQuery, useGetVersionsQuery, setMessage} from 'store/store'

import {useEffect, useDispatch} from 'services/hooks'
import FirmwaresUpdate from 'components/views/Dashboard/FirmwaresUpdate'
import FirmwaresVersions from 'components/views/Dashboard/FirmwaresVersions'

const dashboardCardsData = [
    {
        title: 'Users',
        type: 'users',
        icon: 'icon-single-02',
    },
    {
        title: 'Training Sessions',
        type: 'trainigs',
        icon: 'icon-shape-star',
    },
    {title: 'Exercises', type: 'exercises', icon: 'icon-single-02'},
    {
        title: 'Workouts',
        type: 'workouts',
        icon: 'icon-molecule-40',
    },
    {
        title: 'Programs',
        type: 'programs',
        icon: 'icon-molecule-40',
    },
    {
        title: 'Average workout score',
        type: 'averageDifficultyOfBodyweightWorkouts',
        icon: 'icon-molecule-40',
    },
]

const Main = () => {
    const dispatch = useDispatch()
    const stats = useStatsQuery()
    const versions = useGetVersionsQuery()

    useEffect(() => {
        if (stats?.isError) {
            dispatch(
                setMessage({
                    message: 'Error fetching stats',
                    type: 'alert-danger',
                })
            )
        }
        if (versions?.isError) {
            dispatch(
                setMessage({
                    message: 'Error fetching stats',
                    type: 'alert-danger',
                })
            )
        }
    }, [stats, versions, dispatch])

    const showDashboardCard = stats.data

    return (
        <Page hideSidbar={false}>
            <PageContent>
                <Row className=" d-flex justify-content-center">
                    {dashboardCardsData.map(({title, type, icon}) => (
                        <DashboardCard
                            show={showDashboardCard}
                            title={title}
                            type={type}
                            stats={stats}
                            icon={icon}
                            getStatistics={stats.refetch}
                            key={title}
                        />
                    ))}
                </Row>
                {versions?.data && (
                    <Row className=" d-flex justify-content-center">
                        <Col xl={5} lg={12}>
                            <FirmwaresUpdate
                                versions={versions.data}
                                refetchVersions={versions?.refetch}
                            />
                        </Col>
                        <Col xl={7} lg={12}>
                            <FirmwaresVersions versions={versions.data} />
                        </Col>
                    </Row>
                )}
            </PageContent>
        </Page>
    )
}

export default Main
