const {keyframes, css} = require('styled-components')

const flicker = keyframes`

0% {
    background-color: #ff67677d;
  }
  25%{
    background-color: inherit;

  }

  75%{
    background-color: #ff67677d;

  }
  
  100% {
    background-color: inherit;
  }

  `

const flickerAnimation = css`
    animation: ${flicker} 0.6s linear;
`

const highlight = keyframes`
0% {
  background-color: #ff67677d;
}

100% {
  background-color: inherit;
}
`

const fadedHilight = css`
    animation: ${highlight} 0.5s ease-in;
`

const Animations = {
    flickerAnimation,
    fadedHilight,
}

export default Animations
