import React, {useEffect, useState, useRef, useCallback} from 'react'
import {formatDataToDragAndDropFullObject} from 'services/utils'
import DragAndDrop from 'components/general/Control/DragAndDrop'
import styled from 'styled-components'
import {Animations} from 'appearance'
import _ from 'lodash'

const WorkoutsDragAndDrop = ({
    showSelectedWorkoutsError,
    setSelectedWorkouts,
    selectedWorkouts,
    workouts = [],
    workoutsGaps,
    setWorkoutsGaps,
    isProgram,
    programWorkoutFlowType,
    setQueryPaginateWo,
}) => {
    const [availableWorkouts, setAvailableWorkouts] = useState([])
    const [searchText, setSearchText] = useState('')
    const workoutListRef = useRef()

    const onRemoveItem = (itemUuid) => {
        const newSelectedItems = selectedWorkouts.filter(
            (item) => item.uuid !== itemUuid
        )

        setSelectedWorkouts(newSelectedItems)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedRefetch = useCallback(
        _.debounce(() => {
            console.log('HERE', searchText)
            if (searchText) {
                setQueryPaginateWo((old) => ({...old, keywords: searchText}))
            }
        }, 500),
        [searchText, setQueryPaginateWo]
    )

    useEffect(() => {
        if (workouts?.length) {
            const formatedWorkouts = formatDataToDragAndDropFullObject(workouts)
            setAvailableWorkouts(formatedWorkouts)
        }
    }, [workouts])

    useEffect(() => {
        if (showSelectedWorkoutsError && selectedWorkouts?.length < 1) {
            workoutListRef.current.scrollIntoView({
                behavior: 'auto',
                block: 'start',
            })
        }
    }, [selectedWorkouts, showSelectedWorkoutsError])

    useEffect(() => {
        debouncedRefetch()
        return () => {
            debouncedRefetch.cancel()
        }
    }, [debouncedRefetch])

    return (
        <Container
            ref={workoutListRef}
            error={showSelectedWorkoutsError}
            className="justify-content-center">
            <DragAndDrop
                showSearch
                enableDuplication
                showSelectedWorkoutsError={showSelectedWorkoutsError}
                onRemoveItem={onRemoveItem}
                searchText={searchText}
                setSearchText={setSearchText}
                selectedItems={selectedWorkouts}
                setSelectedItems={setSelectedWorkouts}
                collection={availableWorkouts}
                setCollection={setAvailableWorkouts}
                dropTitle={'Selected Workouts'}
                dragTitle={'Available Workouts'}
                workoutsGaps={workoutsGaps}
                setWorkoutsGaps={setWorkoutsGaps}
                isProgram={isProgram}
                programWorkoutFlowType={programWorkoutFlowType}
            />
        </Container>
    )
}

export default WorkoutsDragAndDrop

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    ${({error}) => error && Animations.flickerAnimation};
`
