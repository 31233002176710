import {styled} from 'common'
import {CardTitle} from 'components/general/Control'
import React, {useEffect, useRef} from 'react'
import * as echarts from 'echarts'

const RetentionTrend = ({retention_trend = []}) => {
    const echartsRetentionTrend = useRef(null)
    useEffect(() => {
        const chartRetention = echarts.init(echartsRetentionTrend.current)
        let optionTren = {
            title: {
                text: 'Weekly active users %',
                subtext: '',
                textStyle: {
                    fontFamily: 'Hanken Grotesk',
                    fontWeight: '400',
                    fontSize: 11,
                },
            },
            xAxis: {
                type: 'category',
                data:
                    retention_trend && retention_trend.length > 0
                        ? retention_trend.map((item) => {
                              return `${item.label}\n${item.start_end}`
                          })
                        : [],
                axisLine: {
                    lineStyle: {
                        color: '#333',
                        width: 3,
                    },
                },
                interval: 0, // Show all labels
                rotate: 0, // Adjust the angle if needed
                axisLabel: {
                    interval: 0,
                    rotate: retention_trend.length > 7 ? 45 : 0,
                    formatter: function (params) {
                        let parts = `${params}`.split('\n')
                        return parts[0] === 'Current'
                            ? `{primary|${parts[0]}}\n{secondary|${parts[1]}}`
                            : `{textBold|${parts[0]}}\n{secondary|${parts[1]}}`
                    },
                    rich: {
                        textBold: {
                            fontSize: 10,
                            color: '#333',
                            fontFamily: 'Hanken Grotesk',
                        },
                        primary: {
                            fontSize: 10,
                            fontWeight: 'bold',
                            color: '#333',
                            fontFamily: 'Hanken Grotesk',
                        },
                        secondary: {
                            fontSize: 6,
                            color: '#888',
                            fontFamily: 'Hanken Grotesk',
                        },
                    },
                },
            },

            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: '{value}',
                },
                min: 0,
                splitLine: {
                    lineStyle: {
                        type: 'dotted',
                    },
                },
            },
            series: [
                {
                    data:
                        retention_trend && retention_trend.length > 0
                            ? retention_trend.map((item) => {
                                  return item.retention
                              })
                            : [],
                    type: 'line',
                    smooth: true,
                    symbol: 'rect', // Change the symbol to rectangle
                    symbolSize: [3, 3],
                    label: {
                        position: 'top',
                        fontSize: 15,
                        show: true,
                        formatter: function (params) {
                            if (`${params.name}`.split('\n')[0] === 'Current') {
                                return '{current|' + params.value + '}'
                            } else {
                                return '{default|' + params.value + '}'
                            }
                        },
                        rich: {
                            default: {
                                borderColor: 'rgba(74, 199, 220, 1)', // Border color
                                borderWidth: 1, // Border width
                                padding: 5, // Padding around the label
                                backgroundColor: '#fff', // Background color for the label
                                color: 'rgba(74, 199, 220, 1)', // Text color
                                borderRadius: 3,
                            },
                            current: {
                                borderColor: 'rgba(74, 199, 220, 1)', // Border color
                                borderWidth: 1, // Border width
                                padding: 5, // Padding around the label
                                backgroundColor: 'rgba(74, 199, 220, 1)', // Background color for the label
                                color: 'white', // Text color
                                borderRadius: 3,
                            },
                        },
                    },
                    itemStyle: {
                        borderColor: 'rgba(123, 152, 244, 1)',
                        borderWidth: 2,
                    },
                    lineStyle: {
                        color: 'rgba(123, 152, 244, 1)',
                        width: 2,
                    },
                    z: 10,
                    markLine: {
                        silent: true, // Disable tooltip for the markLine
                        data: [
                            {
                                xAxis: retention_trend.length - 1, // Position at the last category
                                type: 'min',
                                label: {
                                    show: false,
                                },
                            },
                        ],
                        lineStyle: {
                            type: 'dotted', // Dotted line style
                            width: 4,
                            color: 'rgba(123, 152, 244, 1)', // Color of the dotted line
                        },
                        // symbol: 'circle', // Dot at the end
                        symbolSize: 6, // Size of the dot at the end
                        symbol: 'none',
                    },
                },
            ],
        }

        chartRetention.setOption(optionTren)
        chartRetention.on('brushSelected', function (params) {
            //缩放时触发函数
            var brushed = []
            var brushComponent = params.batch[0]

            for (var sIdx = 0; sIdx < brushComponent.selected.length; sIdx++) {
                var rawIndices = brushComponent.selected[sIdx].dataIndex
                brushed.push(rawIndices)
            }
            // console.log('params', brushed.join(','))
        })
    }, [retention_trend])
    return (
        <Cards className="bg-white mt-4 mb-4 p-3">
            <CardTitles>Retention Trend</CardTitles>
            <hr />
            <Echarts ref={echartsRetentionTrend} />

            <Container>
                <ContainerDisclaimer>
                    <div className="d-flex flex-row flex-fill">
                        <TextNote>Note:</TextNote>
                        <div className="d-flex flex-row ml-4">
                            <LegendParticipant />
                            <TextActiveUser>Active Users</TextActiveUser>
                        </div>
                    </div>
                </ContainerDisclaimer>
            </Container>
        </Cards>
    )
}

const Container = styled.div`
    display: flex;
    justify-content: center;
`
const TextActiveUser = styled.p`
    align-self: center;
    color: black;
    display: flex;
    margin-left: 15px;
    margin-top: 5px;
    font-size: 14px;
    font-family: 'Hanken Grotesk';
`

const LegendParticipant = styled.div`
    width: 15px;
    height: 15px;
    align-self: center;
    display: flex;
    background-color: rgba(123, 152, 244, 1);
`

const Cards = styled.div`
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 10px;
    font-family: 'Hanken Grotesk';
`

const CardTitles = styled(CardTitle)`
    font-size: 14px;
    font-weight: 800;
    color: black;
    font-family: 'Hanken Grotesk';
`

const Echarts = styled.div`
    width: 100%;
    height: 550px;
`

const ContainerDisclaimer = styled.div`
    background-color: rgba(247, 247, 247, 1);
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    width: 78%;
`

const TextNote = styled.p`
    font-size: 16px;
    font-weight: bold;
    color: black;
    font-family: 'Hanken Grotesk';
    align-self: center;
    display: flex;
`

export default RetentionTrend
