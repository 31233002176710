import {React, styled} from 'common'
import {Texts} from 'appearance'

import {CardTitle} from 'components/general/Control'

const getDataToDisplay = (versions, manufacturer, device) => {
    const firmwares = Object.keys(versions[manufacturer][device])
    if (!firmwares) {
        return
    }
    return (
        <DeviceData key={manufacturer + device}>
            <DeviceName>{device}:</DeviceName>
            <Firmwares>
                {firmwares.map((firmware) => {
                    const key =
                        firmware +
                        ' - ' +
                        versions[manufacturer][device][firmware].version
                    return <Firmware key={key}>{key}</Firmware>
                })}
            </Firmwares>
        </DeviceData>
    )
}

const ManufacturerFirmwaresVersions = ({manufacturer, versions}) => {
    const devices = Object.keys(versions[manufacturer])
    return (
        <Container>
            <Manufacturer>{manufacturer}</Manufacturer>
            <Devices>
                {devices.map((device) => {
                    return getDataToDisplay(versions, manufacturer, device)
                })}
            </Devices>
        </Container>
    )
}

export default ManufacturerFirmwaresVersions

const Container = styled.div`
    width: 50%;
    display: flex;
    align-items: space-around;
    height: 150px;
`

const Manufacturer = styled(CardTitle)`
    font-size: 20px;
    flex: 1;
`
const Devices = styled.div`
    display: flex;
    flex-direction: column;
    flex: 3;
`
const DeviceData = styled(CardTitle)`
    display: flex;
    height: 100%;
`
const DeviceName = styled(Texts.SecondTitle)`
    flex: 1;
`
const Firmwares = styled.div`
    display: flex;
    flex-direction: column;
    flex: 3;
`
const Firmware = styled.div``
