import {React} from 'common'

import {DetailsInput, Row} from 'components/general/Control'

const LastNameAndDescription = ({
    lastName,
    setLastName,
    description,
    setDescription,
    showError,
}) => {
    return (
        <Row>
            <DetailsInput
                type="text"
                name="last name"
                placeholder="last name"
                value={lastName}
                setter={setLastName}
                showError={showError}
            />
            <DetailsInput
                type="text"
                name="description"
                placeholder="description"
                value={description}
                setter={setDescription}
            />
        </Row>
    )
}

export default LastNameAndDescription
