import {skipToken} from '@reduxjs/toolkit/dist/query'
import ActivitySwitchLanguage from 'components/general/Control/ActivitySwitchLanguage'
import SweetAlert from 'components/general/Display/SweetAlert'
import {Page, PageContent, DetailsWrapper} from 'components/general/Page'
import ProgramDetails from 'components/Program/ProgramDetails'
import {LANGUAGES} from 'constant/languages'
import {useParams, useEffect, useState, useMemo, useRef} from 'services/hooks'
import {getInstructions} from 'services/utils'
import {
    useGetProgramQuery,
    useSaveProgramMutation,
    useSaveProgramTranslationMutation,
} from 'store/store'

const alertTextsMap = {
    showError: {
        title: 'Missing fields',
        mainText: 'Please fill in all the missing fields before saving',
    },
    showNameError: {
        title: 'Program name problem',
        mainText: 'This name is already exist \n please select another one',
    },
    showSelectedWorkoutsError: {
        title: 'Workouts list',
        mainText: 'Workouts list must contain at least one workout',
    },
    showSelectedTagsError: {
        title: 'Tags list',
        mainText: 'Tags list must contain at least one tag',
    },

    showCorporateError: {
        title: 'Missing Targets fields',
        mainText: 'Please complete at least one type of Program target fields',
    },
}

const Program = () => {
    const [saveProgram, {isError, isLoading: isSaving, isSuccess, data}] =
        useSaveProgramMutation()

    const alertRef = useRef()

    const params = useParams()
    const isNewProgram = params?.id === 'new'

    const [errorType, setErrorType] = useState(null)
    const [showError, setShowError] = useState(false)
    const [showNameError, setShowNameError] = useState(false)
    const [showDraftError, setShowDraftError] = useState(false)
    const [showSelectedTagsError, setShowSelectedTagsError] = useState(false)
    const [showSelectedWorkoutsError, setShowSelectedWorkoutsError] =
        useState(false)
    const [showCorporateError, setShowCorporateError] = useState(false)
    const [language, setLanguage] = useState(LANGUAGES[0].value)
    const [doneFormattingWorkouts, setDoneFormattingWorkouts] = useState(false)

    const skipRequest = isNewProgram

    const {
        data: program,
        isFetching: isFetchingProgram,
        // isError: isErrorFetchingProgram,
    } = useGetProgramQuery(
        skipRequest ? skipToken : {id: params?.id, language: language},
        {refetchOnMountOrArgChange: true}
    )

    const [
        saveTranslation,
        {
            isError: isSavingTranslationError,
            isLoading: isSavingTranslation,
            isSuccess: isSavingTranslationSuccess,
        },
    ] = useSaveProgramTranslationMutation()

    const [updatedProgram, setUpdatedProgram] = useState({})

    useEffect(() => {
        if (program) {
            setUpdatedProgram({
                ...program,
                instructions: getInstructions(program),
            })
        }
    }, [program])

    const alertInfo = useMemo(() => alertTextsMap[errorType], [errorType])
    const {name, display_name, instructions} = updatedProgram

    const onError = (error) => {
        setErrorType(error)
        if (error) {
            alertRef.current()
        }
    }

    const showSpinner = useMemo(
        () =>
            (!program && !isNewProgram) ||
            isSaving ||
            isFetchingProgram ||
            !doneFormattingWorkouts,
        [
            program,
            isNewProgram,
            isSaving,
            isFetchingProgram,
            doneFormattingWorkouts,
        ]
    )
    return (
        <Page>
            <PageContent>
                <DetailsWrapper
                    header="Program Details"
                    backButtonText="Back to Programs">
                    {language !== 'en' ? (
                        <ActivitySwitchLanguage
                            title={'EDIT PROGRAM'}
                            language={language}
                            activityId={program?.id}
                            setLanguage={setLanguage}
                            activityName={name}
                            activityDisplayName={display_name}
                            activityInstructions={instructions}
                            saveTranslation={saveTranslation}
                            isSavingError={isSavingTranslationError}
                            isSavingSuccess={isSavingTranslationSuccess}
                            loading={isSavingTranslation || isFetchingProgram}
                        />
                    ) : (
                        <ProgramDetails
                            onError={onError}
                            isNewProgram={isNewProgram}
                            showDraftError={showDraftError}
                            setShowDraftError={setShowDraftError}
                            program={program}
                            showSpinner={showSpinner}
                            saveProgram={saveProgram}
                            saveOptions={{
                                isError,
                                isSaving,
                                isSuccess,
                                data,
                            }}
                            language={language}
                            setLanguage={setLanguage}
                            showError={showError}
                            setShowError={setShowError}
                            showNameError={showNameError}
                            setShowNameError={setShowNameError}
                            updatedProgram={updatedProgram}
                            setUpdatedProgram={setUpdatedProgram}
                            showSelectedWorkoutsError={
                                showSelectedWorkoutsError
                            }
                            setShowSelectedWorkoutsError={
                                setShowSelectedWorkoutsError
                            }
                            showCorporateError={showCorporateError}
                            setShowCorporateError={setShowCorporateError}
                            showSelectedTagsError={showSelectedTagsError}
                            setShowSelectedTagsError={setShowSelectedTagsError}
                            doneFormattingWorkouts={doneFormattingWorkouts}
                            setDoneFormattingWorkouts={
                                setDoneFormattingWorkouts
                            }
                        />
                    )}
                    <SweetAlert
                        ref={alertRef}
                        title={alertInfo?.title}
                        mainText={alertInfo?.mainText}
                        action=""
                        deactivate={() => {}}
                        hideAlertOnConfirm={() => {
                            setErrorType(null)
                        }}
                        confirmBtnText="Got it"
                        id={program?.id}
                        hideCancel
                    />
                </DetailsWrapper>
            </PageContent>
        </Page>
    )
}

export default Program
