/* eslint-disable complexity */
import {styled} from 'common'
import {CardTitle} from 'components/general/Control'
import React from 'react'
import IconStrength from 'assets/img/icon-strength.svg'
import IconHearts from 'assets/img/icon-hearts.svg'
import IconWalk from 'assets/img/icon-walk.svg'
import CircleProgressReport from './CircleProgressReport'

const RetentionRates = ({
    one_workout = {},
    all_workout = {},
    exceed_goals = {},
}) => {
    return (
        <Cards className="bg-white mb-3 p-3">
            <CardTitles>Retention Rates</CardTitles>
            <hr />

            <CardRetention
                isTitle
                title="At least one workout"
                last_week={one_workout?.last_week}
                past_four_weeks={one_workout?.past_four_weeks}
                since_day_one={one_workout?.since_day_one}
                description={'Distribution by goal type'}
            />
            <CardRetention
                title="Finished all goals"
                last_week={all_workout?.last_week}
                past_four_weeks={all_workout?.past_four_weeks}
                since_day_one={all_workout?.since_day_one}
                description={'Finished a specific goal type'}
            />
            <CardRetention
                title="Exceed goals"
                last_week={exceed_goals?.last_week}
                past_four_weeks={exceed_goals?.past_four_weeks}
                since_day_one={exceed_goals?.since_day_one}
                description={'Exceeded a specific goal type'}
            />
        </Cards>
    )
}

const CardRetention = ({
    title = 'Finished all goals',
    description = '',
    isTitle = false,
    last_week = {
        strength: 0,
        aerobic: 0,
        step: 0,
        all: 0,
    },
    past_four_weeks = {
        strength: 0,
        aerobic: 0,
        step: 0,
        all: 0,
    },
    since_day_one = {
        strength: 0,
        aerobic: 0,
        step: 0,
        all: 0,
    },
}) => (
    <>
        <Container>
            <ContainerTitle>
                <TitleRetention>{title}</TitleRetention>
                <TitleRetention2>{description}</TitleRetention2>
            </ContainerTitle>
            <CardContainer className="d-flex flex-row justify-content-around">
                <div className="d-flex flex-column flex-wrap">
                    {isTitle && (
                        <>
                            <div className="mt-2" />
                            <TitleWeek>This Week</TitleWeek>
                        </>
                    )}
                    <CircleProgressReport
                        marginTop={-40}
                        percentage={last_week?.all}
                        colour={'rgba(46, 115, 241, 1)'}
                    />
                    <div
                        className="d-flex flex-row align-self-center mb-2"
                        style={{marginTop: -40}}>
                        <img
                            src={IconStrength}
                            alt="strength"
                            width={15}
                            height={16}
                        />
                        <TitleContentStage>
                            {last_week.strength || 0}%
                        </TitleContentStage>
                        <img
                            src={IconHearts}
                            alt="strength"
                            width={15}
                            height={16}
                            className="ml-2"
                        />
                        <TitleContentStage>
                            {last_week.aerobic || 0}%
                        </TitleContentStage>
                        <img
                            src={IconWalk}
                            alt="strength"
                            width={15}
                            height={16}
                            className="ml-2"
                        />
                        <TitleContentStage>
                            {last_week.step || 0}%
                        </TitleContentStage>
                    </div>
                </div>
                <div className="d-flex flex-column">
                    {isTitle && (
                        <>
                            <div className="mt-2" />
                            <TitleWeek>Past 4 Weeks</TitleWeek>{' '}
                        </>
                    )}
                    <CircleProgressReport
                        marginTop={-40}
                        percentage={past_four_weeks.all}
                        colour={'rgba(46, 115, 241, 1)'}
                    />
                    <div
                        className="d-flex flex-row align-self-center"
                        style={{marginTop: -40}}>
                        <img
                            src={IconStrength}
                            alt="strength"
                            width={15}
                            height={16}
                        />
                        <TitleContentStage>
                            {past_four_weeks.strength || 0}%
                        </TitleContentStage>
                        <img
                            src={IconHearts}
                            alt="strength"
                            width={15}
                            height={16}
                            className="ml-2"
                        />
                        <TitleContentStage>
                            {past_four_weeks.aerobic || 0}%
                        </TitleContentStage>
                        <img
                            src={IconWalk}
                            alt="strength"
                            width={15}
                            height={16}
                            className="ml-2"
                        />
                        <TitleContentStage>
                            {past_four_weeks.step || 0}%
                        </TitleContentStage>
                    </div>
                </div>
                <div className="d-flex flex-column">
                    {isTitle && (
                        <>
                            <div className="mt-2" />
                            <TitleWeek>Since Day 1</TitleWeek>{' '}
                        </>
                    )}
                    <CircleProgressReport
                        marginTop={-40}
                        percentage={since_day_one.all || 0}
                        colour={'rgba(46, 115, 241, 1)'}
                    />
                    <div
                        className="d-flex flex-row align-self-center"
                        style={{marginTop: -40}}>
                        <img
                            src={IconStrength}
                            alt="strength"
                            width={15}
                            height={16}
                        />
                        <TitleContentStage>
                            {since_day_one.strength || 0}%
                        </TitleContentStage>
                        <img
                            src={IconHearts}
                            alt="strength"
                            width={15}
                            height={16}
                            className="ml-2"
                        />
                        <TitleContentStage>
                            {since_day_one.aerobic || 0}%
                        </TitleContentStage>
                        <img
                            src={IconWalk}
                            alt="strength"
                            width={15}
                            height={16}
                            className="ml-2"
                        />
                        <TitleContentStage>
                            {since_day_one.step || 0}%
                        </TitleContentStage>
                    </div>
                </div>
            </CardContainer>
        </Container>
    </>
)

export default RetentionRates

const Container = styled.div`
    display: flex;
    flex-direction: row;
    background-color: rgba(247, 247, 247, 1);
    padding-right: 10px;
    margin-bottom: 15px;
`

const CardContainer = styled.div`
    width: 80%;
`

const Cards = styled.div`
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 10px;
`

const CardTitles = styled(CardTitle)`
    font-size: 14px;
    color: black;
    font-family: 'Hanken Grotesk';
    font-weight: 800;
`

const TitleContentStage = styled.p`
    font-size: 10px;
    color: black;
    align-self: center;
    margin-left: 2px;
`

const ContainerTitle = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 130px;
`

const TitleRetention = styled.p`
    display: flex;
    font-size: 12px;
    color: black;
    font-weight: 600;
    padding: 10px;
    flex: display;
    flex: 1;
    min-width: 200px;
    align-items: end;
    margin-left: 20px;
    font-family: 'Hanken Grotesk';
`

const TitleRetention2 = styled.p`
    display: flex;
    font-size: 10px;
    color: black;
    font-weight: 400;
    padding: 10px;
    flex: display;
    flex: 1;
    align-items: end;
    margin-left: 20px;
    min-width: 280px;
    font-family: 'Hanken Grotesk';
`

const TitleWeek = styled.p`
    color: black;
    align-self: center;
    font-size: 10px;
    font-family: 'Hanken Grotesk';
    font-weight: 400;
    line-height: 10.42px;
    margin-top: -20px;
    margin-bottom: 10px;
`
