/* eslint-disable no-unused-vars */
import React from 'react'
import {FormGroup, Row, Col} from 'components/general/Control'
import AppInput from 'components/general/Control/Input'
import {validateNumberInput} from 'services/utils'

const EstimatedWorkAndCalories = ({
    showError,
    required,
    estimatedWork = '',
    setEstimatedWork,
    estimatedCalories = '',
    setEstimatedCalories,
}) => {
    const getDisplayName = (label) =>
        required ? 'Estimated ' + label + ' *' : label

    const handleValue = (value, setter) => {
        if (validateNumberInput(value)) {
            setter(value)
        } else return
    }
    return (
        <Row className="justify-content-between">
            <Col className="pr-md-1" sm="12" lg="4" md="6">
                <FormGroup>
                    <label>{getDisplayName('Work')}</label>
                    <AppInput
                        disableAutoComplete
                        name="estimated_work"
                        placeholder="Enter Estimated Work"
                        value={estimatedWork}
                        setter={(value) => handleValue(value, setEstimatedWork)}
                        showError={showError}
                    />
                </FormGroup>
            </Col>

            <Col className="pr-md-1" sm="12" lg="4" md="6">
                <FormGroup>
                    <label>{getDisplayName('Calories')}</label>
                    <AppInput
                        disableAutoComplete
                        name="estimated_calories"
                        showError={showError}
                        placeholder="Enter Estimated Calories"
                        value={estimatedCalories}
                        setter={(value) =>
                            handleValue(value, setEstimatedCalories)
                        }
                    />
                </FormGroup>
            </Col>
        </Row>
    )
}

export default EstimatedWorkAndCalories
