import {styled} from 'common'

import {Card, CardBody, CardHeader, CardTitle, CardFooter} from 'reactstrap'

export const StyledCard = styled(Card)``
export const StyledCardBody = styled(CardBody)``
export const StyledCardHeader = styled(CardHeader)``
export const StyledCardTitle = styled(CardTitle)`
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
`
export const StyledCardFooter = styled(CardFooter)``
