import {React, styled} from 'common'
import {
    useTable,
    usePagination,
    useFilters,
    useGlobalFilter,
    useSortBy,
} from 'react-table'
import {useMemo, useEffect, useUrlParams} from 'services/hooks'
import DefaultColumnFilter from 'components/general/Display/Table/DefaultColumnFilter'
import {Table} from 'components/general/Control'
import TableHeader from 'components/general/Display/Table/TableHeader'
import TableBody from 'components/general/Display/Table/TableBody'

const MyTable = ({columns, data, isError, isRowHover = true}) => {
    const [urlParams, setUrlParam] = useUrlParams()

    const {page_size, page_number} = urlParams

    const onSetGoToPage = (value) => {
        setUrlParam('page_number', value)
    }
    const onSetPageSize = (value) => {
        setUrlParam('page_size', value)
    }

    const filterCaseInsensitive = (row1, row2, columnName) => {
        const rowOneColumn = row1.values[columnName] || ''
        const rowTwoColumn = row2.values[columnName] || ''

        if (isNaN(rowOneColumn)) {
            return rowOneColumn.toUpperCase() > rowTwoColumn.toUpperCase()
                ? 1
                : -1
        }
        return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1
    }

    const defaultColumn = useMemo(
        () => ({
            Filter: (props) => {
                const {filterValue, preFilteredRows, setFilter, filter, id} =
                    props.column
                return filter === 'default'
                    ? DefaultColumnFilter({
                          column: {filterValue, preFilteredRows, setFilter, id},
                      })
                    : DefaultColumnFilter({
                          visibility: false,
                          column: {
                              filterValue: 0,
                              preFilteredRows: [],
                              setFilter: () => 1,
                          },
                      })
            },
            sortType: filterCaseInsensitive,
        }),
        []
    )

    const defaultSort = useMemo(
        () =>
            columns.some((column) => column.accessor === 'name')
                ? 'name'
                : 'first_name',
        [columns]
    )

    const initialState = {
        sortBy: [
            {
                id: defaultSort,
                desc: false,
            },
        ],
        filters: [
            {
                id: 'newVersion',
                value: 'true',
            },
        ],
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize},
    } = useTable(
        {
            columns,
            data: data || [],
            initialState,
            defaultColumn,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    useEffect(() => {
        if (page_number && +page_number !== pageIndex) {
            gotoPage(+page_number)
        }
        if (page_size && +page_size !== pageSize) {
            setPageSize(+page_size)
        }
    }, [page_size, page_number, pageIndex, pageSize, setPageSize, gotoPage])

    if (isError) {
        return <ConnectError>CONNECTION ERROR</ConnectError>
    }
    return (
        <Table {...getTableProps()}>
            <TableHeader
                headerGroups={headerGroups}
                nextPage={nextPage}
                canNextPage={canNextPage}
                previousPage={previousPage}
                canPreviousPage={canPreviousPage}
                pageIndex={page_number}
                pageSize={page_size}
                setPageSize={onSetPageSize}
                gotoPage={onSetGoToPage}
                pageOptions={pageOptions}
                pageCount={pageCount}
            />

            <TableBody
                page={page}
                getTableBodyProps={getTableBodyProps}
                prepareRow={prepareRow}
                isRowHover={isRowHover}
            />
        </Table>
    )
}

export default MyTable

const ConnectError = styled.div``
