import {React, styled} from 'common'
import {FormGroup, Col, Input as Input2, Label} from 'reactstrap'
import Input from './Input'

const DetailsInput = ({
    type,
    name,
    placeholder,
    value,
    setter,
    showError,
    xl = 4,
    lg = 4,
    md = 4,
    sm = 4,
    onClick,
    iconLabel,
}) => {
    return (
        <Col xl={xl} lg={lg} md={md} sm={sm}>
            <FormGroup onClick={onClick}>
                <StyledLabel>
                    {placeholder}{' '}
                    {iconLabel && (
                        <IconLabel onClick={onClick}>{iconLabel}</IconLabel>
                    )}
                </StyledLabel>

                {setter ? (
                    <StyledInput
                        name={name}
                        value={value}
                        type={type}
                        placeholder={placeholder}
                        setter={setter}
                        showError={showError}
                    />
                ) : (
                    <StyledInput2
                        disabled
                        name={name}
                        value={value}
                        type={type}
                    />
                )}
            </FormGroup>
        </Col>
    )
}

export default DetailsInput
const StyledInput = styled(Input)`
    :disabled {
        color: ${({color}) => (color ? 'white' : 'inherit')};
    }
`
const StyledInput2 = styled(Input2)`
    :disabled {
        color: ${({color}) => (color ? 'white' : 'inherit')};
        cursor: not-allowed;
    }
`

const StyledLabel = styled(Label)`
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
`

const IconLabel = styled.div`
    margin-left: 10px;
`
