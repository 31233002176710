import React from 'react'
import {LANGUAGES} from 'constant/languages'
import {FormGroup, Col} from 'components/general/Control'
import SelectInput from 'components/general/Control/SelectInput'

const Language = ({
    language,
    setLanguage,
    className,
    sm = 12,
    md = 6,
    lg = 6,
    hide,
    flexEnd,
}) => {
    if (hide) {
        return null
    }
    return (
        <Col
            className={`${className} "pl-0 pr-0 pr-sm-0 pr-md-3"`}
            sm={sm}
            lg={lg}
            md={md}>
            <FormGroup
                className={`d-flex px-0  ${flexEnd && 'justify-content-end'}`}>
                <SelectInput
                    className={'px-0 w-100'}
                    name={'language'}
                    label={'language'}
                    options={LANGUAGES}
                    selected={language}
                    onChange={setLanguage}
                    placeholder={'select language'}
                />
            </FormGroup>
        </Col>
    )
}

export default Language
