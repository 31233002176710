import {React, styled} from 'common'
import FilterExercises from 'components/Workout/Details/FilterExercises'
import WorkoutBuilderControls from 'components/Workout/Details/WorkoutBuilderControls'
import {Droppable} from 'react-beautiful-dnd'
import DraggableItem from 'components/general/Control/DragAndDrop/DraggableItem'
import BuilderFormHeader from 'components/Workout/Details/WorkoutBuilder/BuilderFormHeader'
import FilterBubbles from 'components/Workout/Details/FilterBubbles'
import NonDraggableItem from 'components/Workout/Details/WorkoutBuilder/NonDraggableItem'
import DefaultVariablesForm from 'components/Workout/Details/WorkoutBuilder/DefaultVariablesForm'
import WorkoutDevices from 'components/Workout/Details/WorkoutDevices'
import FilterWorkouts from 'components/Program/Details/FilterWorkouts'
import ColumnSpacer from 'components/general/Control/DragAndDrop/ColumnSpacer'
import {useMemo} from 'react'

const removeFromCheckboxList = (checkboxList, selectedItem) =>
    checkboxList?.filter((item) => item.uuid !== selectedItem.uuid)

const findItemInList = (checkboxList, selectedItem) =>
    checkboxList?.find((item) => item.uuid === selectedItem.uuid)

const DroppableColumn = ({
    title,
    column,
    items,
    isClass,
    showSearch,
    showSearchSpacer,
    showFilter,
    searchText,
    setSearchText,
    filterByGender,
    setFilterByGender,
    showColumnSelection,
    onRemoveItem,
    checkboxList,
    setCheckboxList,
    selectedItems,
    setSelectedItems,
    selectedFilterTypes,
    setSelectedFilterTypes,
    defaultWork,
    setDefaultWork,
    defaultRest,
    setDefaultRest,
    defaultSets,
    setDefaultSets,
    updatedWorkout,
    onUpdateWorkoutTarget,
    isProgram,
    workoutsGaps,
    setWorkoutsGaps,
    programWorkoutFlowType,
}) => {
    const isItemChecked = (itemId) => {
        const ans = checkboxList?.findIndex((item) => item?.uuid === itemId)
        return ans !== -1
    }

    const handleSelection = (selectedItem) => {
        const itemInList = findItemInList(checkboxList, selectedItem)
        let newSelectedExercises
        if (itemInList) {
            newSelectedExercises = removeFromCheckboxList(
                checkboxList,
                selectedItem
            )
        } else {
            newSelectedExercises = [...checkboxList, selectedItem]
        }

        setCheckboxList(newSelectedExercises)
    }

    const isExercisetype = (item) =>
        item.activity_type !== 'section' && item.activity_type !== 'transition'

    const isAllItemsChecked = () => {
        const exercisesList = selectedItems?.filter((item) =>
            isExercisetype(item)
        )
        return (
            checkboxList.length > 0 &&
            exercisesList.length === checkboxList.length
        )
    }
    const updateCheckboxSelected = (isChecked) => {
        if (isChecked) {
            const newList = selectedItems?.filter((item) =>
                isExercisetype(item)
            )
            setCheckboxList(newList)
        } else {
            setCheckboxList([])
        }
    }

    const showBuilderControls = useMemo(
        () => showColumnSelection && !isClass,
        [isClass, showColumnSelection]
    )

    return (
        <React.Fragment>
            <div className="d-flex w-100 align-items-baseline">
                <h4>{title}</h4>
                {showColumnSelection && (
                    <WorkoutDevices selectedExercises={selectedItems} />
                )}
            </div>

            {showSearch && (
                <FilterWorkouts
                    searchText={searchText}
                    setSearchText={setSearchText}
                />
            )}
            <ColumnSpacer showSearchSpacer={showSearchSpacer} />
            {showFilter && (
                <FilterExercises
                    selectedFilterTypes={selectedFilterTypes}
                    setSelectedFilterTypes={setSelectedFilterTypes}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    filterByGender={filterByGender}
                    setFilterByGender={setFilterByGender}
                />
            )}
            {showFilter && (
                <FilterBubbles
                    selectedFilterTypes={selectedFilterTypes}
                    setSelectedFilterTypes={setSelectedFilterTypes}
                />
            )}
            {showColumnSelection && (
                <WorkoutBuilderControls
                    isClass={isClass}
                    updatedWorkout={updatedWorkout}
                    defaultWork={defaultWork}
                    setDefaultWork={setDefaultWork}
                    defaultRest={defaultRest}
                    setDefaultRest={setDefaultRest}
                    defaultSets={defaultSets}
                    setDefaultSets={setDefaultSets}
                    searchText={searchText}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    setSearchText={setSearchText}
                    filterByGender={filterByGender}
                    checkboxList={checkboxList}
                    setCheckboxList={setCheckboxList}
                    setFilterByGender={setFilterByGender}
                    onUpdateWorkoutTarget={onUpdateWorkoutTarget}
                />
            )}
            <Droppable droppableId={column} isDropDisabled={false}>
                {(provided, snapshot) => (
                    <List
                        className="position-relative pt-2"
                        ref={provided.innerRef}
                        {...provided.droppableProps}>
                        {showBuilderControls && (
                            <BuilderFormHeader
                                checked={isAllItemsChecked()}
                                updateCheckboxSelected={updateCheckboxSelected}
                            />
                        )}
                        {showBuilderControls && (
                            <DefaultVariablesForm
                                defaultRest={defaultRest}
                                defaultSets={defaultSets}
                                setDefaultRest={setDefaultRest}
                                setDefaultSets={setDefaultSets}
                                defaultWork={defaultWork}
                                setDefaultWork={setDefaultWork}
                            />
                        )}
                        <DraggableItemsContainer
                            paddingStart={showColumnSelection}>
                            {items?.map((item, index) => {
                                const isSelected = isItemChecked(item.uuid)
                                return (
                                    <div
                                        className="d-flex"
                                        key={'item' + item?.uuid}>
                                        <DraggableItem
                                            isClass={isClass}
                                            key={item?.uuid}
                                            item={item}
                                            index={index}
                                            selectedItems={selectedItems}
                                            setSelectedItems={setSelectedItems}
                                            isSelected={isSelected}
                                            itemSelection={showColumnSelection}
                                            onRemoveItem={onRemoveItem}
                                            handleSelection={handleSelection}
                                            defaultWork={defaultWork}
                                            defaultRest={defaultRest}
                                            defaultSets={defaultSets}
                                            isProgram={isProgram}
                                            workoutsGaps={workoutsGaps}
                                            setWorkoutsGaps={setWorkoutsGaps}
                                            programWorkoutFlowType={
                                                programWorkoutFlowType
                                            }>
                                            {item.activity_type === 'group' &&
                                                item.items
                                                    ?.filter((it) => it)
                                                    .map((it, i) => {
                                                        return (
                                                            <NonDraggableItem
                                                                key={
                                                                    'grouped_' +
                                                                    it?.uuid
                                                                }
                                                                selectedItems={
                                                                    selectedItems
                                                                }
                                                                setSelectedItems={
                                                                    setSelectedItems
                                                                }
                                                                showColumnSelection={
                                                                    showColumnSelection
                                                                }
                                                                defaultWork={
                                                                    defaultWork
                                                                }
                                                                defaultRest={
                                                                    defaultRest
                                                                }
                                                                defaultSets={
                                                                    defaultSets
                                                                }
                                                                itemIndex={
                                                                    index
                                                                }
                                                                childIndex={i}
                                                                item={it}
                                                            />
                                                        )
                                                    })}
                                        </DraggableItem>
                                    </div>
                                )
                            })}
                        </DraggableItemsContainer>

                        {provided.placeholder}
                    </List>
                )}
            </Droppable>
        </React.Fragment>
    )
}

export default DroppableColumn

const DraggableItemsContainer = styled.ul`
    padding-inline-start: ${({paddingStart}) => (paddingStart ? 3 : 0)}px;
`
const List = styled.div`
    min-height: 100px;
    max-height: 400px;
    padding: 8px;
    border: 1px solid red;
    border-radius: 5px;
    overflow-y: auto;
`
