import React from 'react'
import {exerciseEquipment} from 'constant/exercise'
import {FormGroup, Row, Col} from 'components/general/Control'
import SelectInput from 'components/general/Control/SelectInput'

const ExerciseEquipment = ({type, setType, showError}) => {
    return (
        <Row form>
            <Col md={4}>
                <FormGroup>
                    <SelectInput
                        required
                        selected={type}
                        onChange={setType}
                        label={'Equipment'}
                        showError={showError}
                        name={'exercise_equipment'}
                        options={exerciseEquipment}
                    />
                </FormGroup>
            </Col>
        </Row>
    )
}

export default ExerciseEquipment
