import {Colors} from 'appearance'
import {useEffect, useMemo, useRef, useState} from 'react'
import {useSelector} from 'react-redux'

import {
    Button,
    DropdownMenu,
    Dropdown,
    DropdownToggle,
    DropdownItem,
} from 'reactstrap'
import {useIsDarkTheme} from 'store/store'
import styled from 'styled-components'

const WO_BUILDER_SECTION = [
    {
        label: 'Warm Up',
        value: 'warm_up',
    },
    {
        label: 'Workout',
        value: 'workout',
    },
    {
        label: 'Cooldown',
        value: 'cooldown',
    },
]

const WorkoutBuilderControlButton = ({
    type,
    padding,
    name,
    onClick,
    hideButtons,
}) => {
    const [isSectionOpen, setIsSectionOpen] = useState(false)
    const isDarkTheme = useSelector(useIsDarkTheme)

    const isOpenRef = useRef(isSectionOpen)

    useEffect(() => {
        isOpenRef.current = isSectionOpen
    }, [isSectionOpen])

    useEffect(() => {
        window.addEventListener('click', (ev) =>
            handleScreenClicked(ev, isOpenRef)
        )

        return () => {
            window.removeEventListener('click', (ev) =>
                handleScreenClicked(ev, isOpenRef)
            )
        }
    }, [])

    const handleScreenClicked = (ev, _isOpen) => {
        if (ev.target.dataset?.type === 'builder-section') {
            return
        } else {
            setIsSectionOpen(false)
        }
    }

    const dropDownBackground = isDarkTheme
        ? Colors.OPAC_DARK
        : Colors.OPAC_LIGHT

    const textColor = isDarkTheme ? 'white' : 'inherit'
    const hoverColor = isDarkTheme ? Colors.DARK_HOVER : Colors.LIGHT_HOVER
    const isOpen = useMemo(() => (isSectionOpen ? 1 : 0), [isSectionOpen])

    return type === 'button' ? (
        !hideButtons && (
            <StyledButton
                className={'mt-0'}
                onClick={onClick}
                padding={padding}>
                {name}
            </StyledButton>
        )
    ) : (
        <Dropdown
            data-type="builder-section"
            direction="left"
            toggle={() => setIsSectionOpen(!isSectionOpen)}>
            <StyledToggle data-type="builder-section" className={'mx-1 mt-0'}>
                {name}
            </StyledToggle>

            <StyleDropDownMenu
                data-type="builder-section"
                bcakground={dropDownBackground}
                visible={isOpen}
                show={isOpen}>
                {WO_BUILDER_SECTION.map((item, i) => (
                    <StyledItem
                        data-type="builder-section"
                        key={('item_section', item.value, i)}
                        textcolor={textColor}
                        hovercolor={hoverColor}
                        onClick={() => onClick(item)}>
                        {item.label}
                    </StyledItem>
                ))}
            </StyleDropDownMenu>
        </Dropdown>
    )
}

export default WorkoutBuilderControlButton

const StyledButton = styled(Button)`
    padding-inline: ${({padding}) => (padding ? padding + 'px' : 'auto')};
    font-size: 0.7rem;
    margin-bottom: 0.4rem;
`
const StyleDropDownMenu = styled(DropdownMenu)`
    margin: 1.5rem 0 0;
    visibility: ${({visible}) => (visible ? 'visible' : 'hidden')} !important;
    opacity: ${({visible}) => (visible ? 1 : 0)} !important;
    box-shadow: 0px 10px 50px 3px rgba(0 0 0 0.5);
    background-color: ${({bcakground}) => bcakground};
    border-radius: 6px;
    &::before {
        content: none;
    }
`
const StyledToggle = styled(DropdownToggle)`
    font-size: 0.7rem;
    padding-inline: 10px;
    margin-left: 4px;
`

const StyledItem = styled(DropdownItem)`
    color: ${({textcolor}) => textcolor && textcolor};

    &:hover {
        background-color: ${({hovercolor}) => hovercolor};
    }
`
