import React from 'react'
import {Row} from 'components/general/Control'
import {
    AlternateRepetitions,
    SingleBand,
    AdjusterPosition,
    AnchorPosition,
    OrientationToAnchor,
    SegmentMotion,
    PrimaryPlane,
    ExerciseAction,
    ContractionFocus,
    GripSelection,
    MovementPattern,
    AnchorLocation,
} from 'components/Exercise/Details'
import styled from 'styled-components'

const Gear1Content = ({showError, updatedExercise, updateExerciseField}) => {
    const {
        grip_type,
        action,
        single_band,
        segment,
        primary_plane,
        anchor_location,
        movement_pattern,
        adjuster_position,
        contraction_focus,
        anchor_orientation,
        anchor_position_type,
        combined_repetitions,
    } = updatedExercise || {}

    return (
        <Container>
            <Row className="pb-5">
                <AlternateRepetitions
                    combinedRepitionsCount={combined_repetitions}
                    setCombinedRepitionsCount={(value) =>
                        updateExerciseField('combined_repetitions', value)
                    }
                />
                <SingleBand
                    singleBand={single_band}
                    setSingleBand={(value) =>
                        updateExerciseField('single_band', value)
                    }
                />
            </Row>
            <Row>
                <GripSelection
                    type={grip_type}
                    setType={(value) => updateExerciseField('grip_type', value)}
                    showError={showError}
                />
            </Row>
            <AdjusterPosition
                adjusterPosition={adjuster_position}
                setAdjusterPosition={(value) =>
                    updateExerciseField('adjuster_position', value)
                }
                showError={showError}
            />

            <AnchorPosition
                anchorPositionType={anchor_position_type}
                handleAnchorPositionType={(value) =>
                    updateExerciseField('anchor_position_type', value)
                }
                showError={showError}
            />
            <AnchorLocation
                anchorLocation={anchor_location}
                setAnchorLocation={(value) =>
                    updateExerciseField('anchor_location', value)
                }
                showError={showError}
            />
            <OrientationToAnchor
                anchorOrientation={anchor_orientation}
                setAnchorOrientation={(value) =>
                    updateExerciseField('anchor_orientation', value)
                }
                showError={showError}
            />

            <SegmentMotion
                segment={segment}
                setSegment={(value) => updateExerciseField('segment', value)}
                showError={showError}
            />
            <PrimaryPlane
                primaryPlane={primary_plane}
                setPrimaryPlane={(value) =>
                    updateExerciseField('primary_plane', value)
                }
                showError={showError}
            />
            <MovementPattern
                movementPattern={movement_pattern}
                setMovementPattern={(value) =>
                    updateExerciseField('movement_pattern', value)
                }
                showError={showError}
            />

            <ExerciseAction
                action={action}
                setAction={(value) => updateExerciseField('action', value)}
                showError={showError}
            />
            <ContractionFocus
                contractionFocus={contraction_focus}
                setContractionFocus={(value) =>
                    updateExerciseField('contraction_focus', value)
                }
                showError={showError}
            />
        </Container>
    )
}
export default Gear1Content

const Container = styled.div``
