import {React, styled} from 'common'
import {Texts} from 'appearance'

import {
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    Button,
} from 'components/general/Control'
import {useEffect, useState} from 'react'
import {useUpdateVersionsMutation} from 'store/store'
import FirmwareToUpdateRadios from './FirmwareToUpdateRadios'
import VersionsControl from './VersionsControl'
import {showAlertMessage} from 'services/utils'
import {deviceTypesByManufacturers, manufacturers} from 'constant/firmware'
import {useDispatch} from 'react-redux'

const getDefaultDeviceForManufactorer = (manufactorer) => {
    return Object.keys(deviceTypesByManufacturers[manufactorer])[0]
}
const getDefaultFileType = (manufactorer, device) => {
    return deviceTypesByManufacturers[manufactorer][device][0]
}

const getDevicesByManufactorer = (selectedManufactorer) => {
    return Object.keys(deviceTypesByManufacturers[selectedManufactorer]).map(
        (device) => ({label: device, value: device})
    )
}

const getFileTypesByManufactorerAndDevice = (
    selectedManufactorer,
    selectedDevice
) =>
    deviceTypesByManufacturers[selectedManufactorer][selectedDevice].map(
        (fileType) => ({
            label: fileType,
            value: fileType,
        })
    )

const getCurrentVersionAndUri = (
    versions,
    selectedManufactorer,
    selectedDevice,
    selectedFileType
) => {
    const notFound = {version: 'No version available', uri: null}
    try {
        const version =
            versions[selectedManufactorer][selectedDevice][selectedFileType]
        if (!version) {
            return notFound
        }
        return {version: version.version_number, uri: version.uri}
    } catch (error) {
        return notFound
    }
}

const handleFile = (e, setter, oldState) => {
    e.preventDefault()
    let reader = new FileReader()
    let file = e.target.files[0]
    reader.onloadend = () => {
        setter && setter({...oldState, file: file})
    }
    if (file) {
        reader.readAsDataURL(file)
    }
}

const defaultNewFirmwareData = {
    version: '',
    file: '',
}

const FirmwaresUpdate = ({versions, refetchVersions}) => {
    const [updateVersions, {isSuccess}] = useUpdateVersionsMutation()
    const dispatch = useDispatch()

    const [showInputs, setShowInputs] = useState(false)

    const [selectedManufactorer, setSelectedCompany] = useState(
        manufacturers[3].value
    )
    const [selectedDevice, setSelectedDevice] = useState(
        getDefaultDeviceForManufactorer(selectedManufactorer)
    )
    const [selectedFileType, setSelectedFileType] = useState(
        getDefaultFileType(selectedManufactorer, selectedDevice)
    )

    const [newFirmwareData, setNewFirmwareData] = useState(
        defaultNewFirmwareData
    )

    useEffect(() => {
        if (isSuccess) {
            showAlertMessage(
                'version has been uploaded!',
                'alert-success',
                dispatch
            )
            setNewFirmwareData(defaultNewFirmwareData)
            refetchVersions()
        }
    }, [isSuccess, refetchVersions, dispatch])

    const onSelectCompany = (manufactorer) => {
        setSelectedCompany(manufactorer)
        const defaultDeviceForManufactorer =
            getDefaultDeviceForManufactorer(manufactorer)

        setSelectedDevice(defaultDeviceForManufactorer)
        setSelectedFileType(
            getDefaultFileType(manufactorer, defaultDeviceForManufactorer)
        )
    }

    const devicesBySelectedManufactorer =
        getDevicesByManufactorer(selectedManufactorer)

    const fileTypesByManufactorerAndDevice =
        getFileTypesByManufactorerAndDevice(
            selectedManufactorer,
            selectedDevice
        )

    const {version: currentVersion, uri: currentVersionDounloadLink} =
        getCurrentVersionAndUri(
            versions,
            selectedManufactorer,
            selectedDevice,
            selectedFileType
        )

    const onSubmitFile = () => {
        const {version, file} = newFirmwareData
        if (!(version || file)) {
            return showAlertMessage(
                'version or file is missing',
                'alert-danger'
            )
        }

        const formData = new FormData()
        formData.append('file', file)
        formData.append('version', version)
        formData.append('device_type', selectedDevice)
        formData.append('manufacturer', selectedManufactorer)
        formData.append('file_type', selectedFileType)

        updateVersions(formData)
    }
    return (
        <Card>
            <TitleWrapper>
                <Texts.Title>Upload Firmwares</Texts.Title>
            </TitleWrapper>
            <CardBody>
                <FirmwareToUpdateRadios
                    manufacturers={manufacturers}
                    onSelectCompany={onSelectCompany}
                    selectedManufactorer={selectedManufactorer}
                    devicesBySelectedManufactorer={
                        devicesBySelectedManufactorer
                    }
                    setSelectedDevice={setSelectedDevice}
                    selectedDevice={selectedDevice}
                    fileTypesByManufactorerAndDevice={
                        fileTypesByManufactorerAndDevice
                    }
                    setSelectedFileType={setSelectedFileType}
                    selectedFileType={selectedFileType}
                />
                {selectedManufactorer === 'osl' ? (
                    <TitleWrapper>
                        <Texts.SecondTitle>
                            OSL Firmware Disabled
                        </Texts.SecondTitle>
                    </TitleWrapper>
                ) : (
                    <VersionsControl
                        currentVersionDounloadLink={currentVersionDounloadLink}
                        currentVersion={currentVersion}
                        newFirmwareData={newFirmwareData}
                        setNewFirmwareData={setNewFirmwareData}
                        handleFile={handleFile}
                        showInputs={showInputs}
                        setShowInputs={setShowInputs}
                    />
                )}
            </CardBody>
            {selectedManufactorer === 'osl' ? (
                ''
            ) : (
                <StyledCardFooter>
                    {showInputs && (
                        <Button
                            id="save_button"
                            color="primary"
                            type="submit"
                            onClick={onSubmitFile}>
                            Save Version
                        </Button>
                    )}
                </StyledCardFooter>
            )}
        </Card>
    )
}

export default FirmwaresUpdate

const TitleWrapper = styled(CardTitle)`
    display: flex;
    justify-content: center;
    padding: 15px;
`
const StyledCardFooter = styled(CardFooter)`
    display: flex;
    justify-content: center;
`
