import axios from 'axios'
import api from 'services/api'

export const axiosBaseQuery =
    () =>
    async ({url, method, body, params}) => {
        const baseUrl = api.baseUrl
        const headers = {
            Accept: 'application/json',
            'hyfit-version': '0002000400010000',
        }
        try {
            const result = await axios({
                url: baseUrl + url,
                method,
                data: body,
                headers,
                params,
                // ...headers,
                withCredentials: true,
            })

            return {data: result.data}
        } catch (axiosError) {
            let err = axiosError
            return {
                error: {status: err.response?.status, data: err.response?.data},
            }
        }
    }

export const getHeaders = () => {
    const token = api.accessToken //localStorage.getItem('token')
    if (token) {
        return {
            'x-access-token': token,
            Accept: 'application/json',
            'hyfit-version': '0002000400010000',
        }
    }
    return {
        Accept: 'application/json',
        'hyfit-version': '0002000400010000',
    }
}

export const transformResponseFromData = (response) => {
    return response.data
}
export const transformResponseFromMessage = (response) => response.message

export const provideTags = (tagName, data) => {
    return data ? data.map(({_id}) => ({type: tagName, id: _id})) : [{}]
}
export const provideTag = (tagName, data, tagField) => {
    return data ? {type: tagName, id: data[tagField]} : null
}
