import {React, styled} from 'common'
import DraggableItemForm from 'components/Workout/Details/WorkoutBuilder/DraggableItemForm'

const getItemName = (item) => item?.display_name || item?.name

const NonDraggableItem = ({
    showColumnSelection,
    selectedItems,
    setSelectedItems,
    defaultWork,
    defaultRest,
    defaultSets,
    itemIndex, //group index
    childIndex, // child index
    item,
}) => {
    if (!item) return null
    return (
        <div className="d-flex">
            <Container key={item.uuid} item={item}>
                {getItemName(item)}
            </Container>
            <DraggableItemForm
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                item={item}
                itemIndex={itemIndex}
                childIndex={childIndex}
                isChild={true}
                defaultWork={defaultWork}
                defaultRest={defaultRest}
                defaultSets={defaultSets}
            />
        </div>
    )
}

export default NonDraggableItem

const Container = styled.div`
    flex: 1;
    margin-top: 5px;
    font-weight: 500;
    border-radius: 4px;
    padding: 5px;
    background: pink;
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Likely future */
`
