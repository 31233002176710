import {React} from 'common'
import {DEFAULT_VALUES} from 'components/Workout/Details/WorkoutBuilderControls'
import Spacer from 'components/general/Page/Spacer'
import {Input} from 'reactstrap'
import styled from 'styled-components'

const DraggableItemInput = ({
    show,
    name,
    value,
    spacer,
    setNewValue,
    defaultValue,
    updateItemField,
}) => {
    if (spacer) {
        return <Spacer w={44} />
    }

    if (!show) {
        return null
    }
    return (
        <TimeInput
            onBlur={setNewValue}
            name={name}
            onChange={(ev) => updateItemField(name, ev.target.value)}
            placeholder={defaultValue || DEFAULT_VALUES[name]}
            autoComplete="off"
            type="text"
            value={value}
        />
    )
}

export default DraggableItemInput

const TimeInput = styled(Input)`
    min-width: 40px;
    max-width: 40px;
    padding-inline: 3px;
    text-align: center;
    margin-inline: 2px;
    padding-block: 7px;
    height: auto;
`
