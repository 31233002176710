import {Animations} from 'appearance'
import React from 'react'
import {Row} from 'react-bootstrap'
import {getIsInvalidNumber} from 'services/utils'
import styled from 'styled-components'
import AerobicGoals from './AerobicGoals'
import StepsGoals from './StepsGoals'
import StrengthGoals from './StrengthGoals'
import FlowType from './FlowType'

const CorporateFields = ({updatedProgram, updateProgramField, showError}) => {
    const handleCorporateFields = (value, field) => {
        if (getIsInvalidNumber(value, 0)) {
            return
        }
        updateProgramField(field, value)
    }
    return (
        <Container error={showError}>
            <Row className="px-3 justify-content-between">
                <AerobicGoals
                    handleCorporateFields={handleCorporateFields}
                    updatedProgram={updatedProgram}
                />
                <StepsGoals
                    updatedProgram={updatedProgram}
                    handleCorporateFields={handleCorporateFields}
                />
            </Row>
            <Row className="px-3">
                <StrengthGoals
                    updatedProgram={updatedProgram}
                    handleCorporateFields={handleCorporateFields}
                />
                <FlowType
                    flowType={updatedProgram?.flow_type}
                    handleFlowTypeChange={(v) => {
                        updateProgramField('flow_type', v)
                    }}
                />
            </Row>
        </Container>
    )
}

export default CorporateFields

const Container = styled.div`
    ${({error}) => error && Animations.flickerAnimation};
`
