import {React, styled} from 'common'
import {Card, CardHeader, CardBody, Button} from 'components/general/Control'
import {useHistory} from 'services/hooks'

const DetailsCard = ({
    header,
    buttonText,
    width,
    height,
    children,
    paddinginline,
    bodyClass,
    headerClass,
}) => {
    const history = useHistory()
    return (
        <StyledCard
            width={width}
            height={height}
            paddinginline={paddinginline}
            className={headerClass}>
            <StyledHeader>
                <HeaderTitle>{header}</HeaderTitle>
                <Button
                    className={'btn btn-success btn-md'}
                    onClick={() => history.goBack()}>
                    {buttonText}
                </Button>
            </StyledHeader>
            <StyledCardBody className={bodyClass}>{children}</StyledCardBody>
        </StyledCard>
    )
}

export default DetailsCard

const StyledCard = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${({width}) => width + 'px'};
    height: ${({height}) => height + 'px'};
    align-self: center;
`
const HeaderTitle = styled.h6``

const StyledHeader = styled(CardHeader)`
    display: flex;
    justify-content: space-between;
    width: 100%;
`
const StyledCardBody = styled(CardBody)`
    width: 100%;
`
