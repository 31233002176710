import {Col, Row} from 'react-bootstrap'
import {FormGroup, Label} from 'reactstrap'
import styled from 'styled-components'
import AppInput from 'components/general/Control/Input'
import {STRENGTH_FIELDS} from 'constant/program'

const StyledLabel = styled(Label)`
    text-transform: uppercase;
    display: inline;
`

const StrengthGoals = ({updatedProgram, handleCorporateFields}) => {
    return (
        <Col className={'px-0 pr-sm-0 pr-xs-0 pr-md-4 pr-lg-4'} sm={12} md={6}>
            <StyledLabel className="form-check-label">
                Strength Goals
            </StyledLabel>
            <FormGroup className={'form-check form-control mt-1 px-2 h-auto'}>
                {STRENGTH_FIELDS?.map((field, i) => (
                    <Row
                        className="w-100 mb-2 mx-0 flex-nowrap align-items-center justify-content-between"
                        key={i}>
                        <StyledLabel className="w-100 mr-2">
                            {field?.label}
                        </StyledLabel>
                        <AppInput
                            marginbottom={0}
                            pattern="\d*"
                            inputMode="numeric"
                            value={updatedProgram?.[field?.name]}
                            setter={(v) =>
                                handleCorporateFields(Number(v), field?.name)
                            }
                            disableAutoComplete
                            name={field?.name}
                        />
                    </Row>
                ))}
            </FormGroup>
        </Col>
    )
}

export default StrengthGoals
