import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {React, styled} from 'common'
import {FormGroup, Col, Label} from 'reactstrap'
import {useIsDarkTheme} from 'store/store'
import {useSelector} from 'react-redux'

const DatePickerInput = ({
    placeholder,
    value,
    setter,
    minDate,
    disabled,
    xl = 4,
    lg = 4,
    md = 4,
    sm = 4,
}) => {
    const isDarkTheme = useSelector(useIsDarkTheme)
    return (
        <Col xl={xl} lg={lg} md={md} sm={sm}>
            <FormGroup>
                <StyledLabel>{placeholder}</StyledLabel>
                <StyledDatePicker
                    isDarkTheme={isDarkTheme}
                    selected={value}
                    onChange={(date) => setter(date)}
                    minDate={minDate}
                    disabled={disabled}
                />
            </FormGroup>
        </Col>
    )
}

export default DatePickerInput
const StyledDatePicker = styled(DatePicker)`
    background: transparent;
    border: ${({isDarkTheme}) =>
        `1px solid ${isDarkTheme ? '#2b3553' : '#1d253b80'}`};
    border-radius: 0.4285rem;
    padding: 0.5rem 0.7rem;
    font-size: 13px;
    color: ${({isDarkTheme}) => (isDarkTheme ? '#ffffffcc' : '#222a42')};
    cursor: pointer;

    :disabled {
        color: ${({color}) => (color ? 'white' : 'inherit')};
        cursor: not-allowed;
    }
    :focus {
        border-color: #00f2c3;
    }
`

const StyledLabel = styled(Label)`
    text-transform: uppercase;
`
