import {React, styled} from 'common'
import {Images} from 'appearance'

import {
    useDispatch,
    useState,
    useSelector,
    useEffect,
    Redirect,
} from 'services/hooks'

import {sendOTPToMail, verifyOTP, useAuthState, setMessage} from 'store/store'
import {setAuthenticated} from 'store/slices/auth'

import {Card, Page} from 'components/general/Page'

import {LoginCardBody} from 'components/views/Login'

const Login = () => {
    const [email, setEmail] = useState('')
    const [otp, setOtp] = useState('')
    const authState = useSelector(useAuthState)

    const dispatch = useDispatch()

    useEffect(() => {
        if (
            authState.message &&
            !authState.isLoading &&
            !authState.authenticated
        ) {
            dispatch(setMessage(authState.message))
        }
    }, [authState, dispatch])

    useEffect(() => {
        const token = localStorage.getItem('token')
        if (token) {
            dispatch(setAuthenticated())
        }
    }, [dispatch])

    const sendOTPTOMailButton = {
        onClickAction: () => {
            dispatch(sendOTPToMail({email}))
        },
        text: 'SEND OTP TO MAIL',
        disable: authState.isLoading,
        id: 'set-otp-to-email',
    }
    const verifyOTPButton = {
        onClickAction: () => {
            dispatch(verifyOTP({email, otp}))
        },
        text: 'VERIFY OTP',
        disable: authState.isLoading,
        id: 'verify-otp',
    }

    if (authState.authenticated) {
        return <Redirect to="/admin/" />
    }

    return (
        <Page preventBars={true}>
            <LoginContainer>
                <Card
                    imageHeader={<StyledImg src={Images.LOGIN_HEADER_IMAGE} />}
                    title="Hyfit Gear"
                    width={450}
                    height={300}>
                    {!authState.otpSent ? (
                        <LoginCardBody
                            buttons={[sendOTPTOMailButton]}
                            value={email}
                            setter={setEmail}
                            type={'email'}
                        />
                    ) : (
                        <LoginCardBody
                            buttons={[verifyOTPButton, sendOTPTOMailButton]}
                            value={otp}
                            setter={setOtp}
                            type={'otp'}
                        />
                    )}
                </Card>
            </LoginContainer>
        </Page>
    )
}

export default Login

const StyledImg = styled.img`
    border-radius: 50%;
    width: 100%;
`
const LoginContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 100px 0;
`
