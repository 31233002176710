import {createApi} from '@reduxjs/toolkit/query/react'

import {
    transformResponseFromData,
    provideTags,
    provideTag,
    axiosBaseQuery,
} from 'store/common'

const trainerApi = createApi({
    baseQuery: axiosBaseQuery(),

    reducerPath: 'catalog',
    tagTypes: ['Catalog', 'Label'],
    endpoints: (build) => ({
        getCatalogsPages: build.query({
            query: () => ({
                url: 'admin/catalog/get_catalog_pages',
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => provideTags('Catalog', data?.pages),
        }),
        getCatalogPage: build.query({
            query: (id) => ({
                url: `admin/catalog/get_catalog_page/${id}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('Catalog', data, '_id')],
        }),
        saveCatalogPage: build.mutation({
            query: (body) => ({
                url: `/admin/catalog/save_catalog_page`,
                method: 'PUT',
                body,
            }),
            transformResponse: transformResponseFromData,
            invalidatesTags: (data) => [provideTag('Catalog', data, '_id')],
        }),

        getCatalogLabels: build.query({
            query: (language = 'en') => ({
                url: `admin/catalog/get_catalog_labels/${language}`,
                method: 'GET',
            }),
            transformResponse: (response) =>
                response.data.labels.filter(
                    ({label_type}) => label_type === 'tag'
                ),
            providesTags: (data) => provideTags('Label', data),
        }),
        getCatalogLabel: build.query({
            query: ({id, language = 'en'}) => ({
                url: `admin/catalog/get_catalog_label/${id}/${language}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('Label', data, '_id')],
        }),
        saveCatalogLabel: build.mutation({
            query: (body) => ({
                url: `/admin/catalog/save_catalog_label`,
                method: 'PUT',
                body,
            }),
            transformResponse: transformResponseFromData,
            invalidatesTags: (data) => [provideTag('Label', data, '_id')],
        }),
        saveCatalogLabelTranslation: build.mutation({
            query: (body) => ({
                url: '/admin/translation/',
                method: 'POST',
                body,
            }),
            transformResponse: transformResponseFromData,
            invalidatesTags: (data) => {
                return [provideTag('Label', data[0], 'object_id')]
            },
        }),
        deleteCatalogLabel: build.mutation({
            query: (labelId) => ({
                url: `/admin/catalog/delete_catalog_label/${labelId}`,
                method: 'DELETE',
            }),
            transformResponse: transformResponseFromData,
            invalidatesTags: (data) => [provideTag('Label', data, '_id')],
        }),
    }),
})
export default trainerApi

export const {
    useGetCatalogsPagesQuery,
    useGetCatalogPageQuery,
    useSaveCatalogPageMutation,
    useGetCatalogLabelsQuery,
    useGetCatalogLabelQuery,
    useSaveCatalogLabelMutation,
    useSaveCatalogLabelTranslationMutation,
    useDeleteCatalogLabelMutation,
} = trainerApi
