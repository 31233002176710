import Images from 'appearance/images'
import {useEffect, useState} from 'react'
import {getWorkoutDevices} from 'services/workout'
import styled from 'styled-components'

const WorkoutDevices = ({selectedExercises = []}) => {
    const [usedDevices, setUsedDevices] = useState([])

    useEffect(() => {
        if (selectedExercises.length) {
            setUsedDevices(getWorkoutDevices(selectedExercises))
        } else setUsedDevices([])
    }, [selectedExercises, selectedExercises.length])

    const getImageByType = (device) => {
        return Images.deviceIcons[device + '_white']
    }

    return (
        <div className="d-flex flex-fill h-auto mx-3">
            {usedDevices.map((device, i) => (
                <DeviceCircle
                    key={'used_device' + i}
                    className="bg-orange px-1 mx-1 py-1 btn-round ">
                    <DeviceImg src={getImageByType(device)} alt="" />
                </DeviceCircle>
            ))}
        </div>
    )
}

export default WorkoutDevices

const DeviceCircle = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
`
const DeviceImg = styled.img`
    max-height: 100%;
`
