import {React, styled} from 'common'
import {AppSelect} from 'components/general/Control'
import {useMemo, useUrlParams} from 'services/hooks'

const SelectColumnFilterV2 = ({column: {filterValue, filterOptions, id}}) => {
    const [urlParams, setUrlParam] = useUrlParams()

    const options = useMemo(() => {
        const opts = filterOptions?.map((val) => {
            if (val === 'All' || val === '') {
                return {value: 'All', label: 'All'}
            }

            return {value: val, label: val}
        })
        return opts
    }, [filterOptions])

    return (
        <StyledSelect
            className={'form-control'}
            type="select"
            value={urlParams[id] || 'All'}
            name={filterValue}
            onChange={(e) => setUrlParam(id, e.target.value || '')}
            options={options}
        />
    )
}

export default SelectColumnFilterV2

const StyledSelect = styled(AppSelect)``
