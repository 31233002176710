import {React} from 'common'

import {checkValidFields} from 'services/utils'

import {
    useGetCouponGroupPageQuery,
    setMessage,
    useSaveCouponGroupPageMutation,
    useUpdateCouponGroupPageMutation,
    useDeleteCouponGroupMutation,
    useGetResellerPagesQuery,
    useGetSubscriptionPlanQuery,
} from 'store/store'

import {SweetAlert} from 'components/general/Display'
import {
    useEffect,
    useHistory,
    useState,
    useParams,
    createRef,
    useDispatch,
    useCallback,
    useMemo,
} from 'services/hooks'
import {
    Row,
    SelectInput,
    DetailsInput,
    TimeframeInput,
} from 'components/general/Control'
import {Page, PageContent, DetailsWrapper} from 'components/general/Page'

const Coupon = () => {
    const history = useHistory()
    const params = useParams()
    const alertRef = createRef()
    const dispatch = useDispatch()

    const couponGroupId = params.id
    const validate = params?.id === 'new'

    const resellers = useGetResellerPagesQuery('en', {
        refetchOnMountOrArgChange: true,
    })
    const plans = useGetSubscriptionPlanQuery('en', {
        refetchOnMountOrArgChange: true,
    })

    const resellersOptionsList = useMemo(
        () =>
            resellers?.data?.map((prop, key) => {
                return {
                    label: prop.name,
                    value: prop._id,
                }
            }),
        [resellers.data]
    )

    const couponGroupFromServer = useGetCouponGroupPageQuery(couponGroupId, {
        skip: validate,
        refetchOnMountOrArgChange: true,
    })

    const [
        saveCouponGroupPage,
        {isSuccess: isSuccessSaving, isError: isErrorSaving},
    ] = useSaveCouponGroupPageMutation()

    const [
        updateCouponGroupPage,
        {isSuccess: isSuccessUpdating, isError: isErrorUpdating},
    ] = useUpdateCouponGroupPageMutation()

    const [
        deleteCatalogLabel,
        {isSuccess: isSuccessDeleting, isError: isErrorDeleting},
    ] = useDeleteCouponGroupMutation()

    const couponGroup = couponGroupFromServer?.data

    const [showError, setShowError] = useState(false)
    const [subscriptionPlan, setSubscriptionPlan] = useState('')
    const [quantity, setQuantity] = useState(500)
    const [isTimelimit, setIsTimelimit] = useState({
        value: false,
        label: 'Set Time Limits',
    })
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(
        new Date(Date.now() + 1000 * 60 * 60 * 24)
    )
    const [reseller, setReseller] = useState('')
    const [contactName, setContactName] = useState('')
    const [contactEmail, setContactEmail] = useState('')

    useEffect(() => {
        if (couponGroup) {
            const {
                subscription_plan,
                quantity,
                start_date,
                end_date,
                reseller,
                contact_name,
                contact_email,
            } = couponGroup

            setSubscriptionPlan(subscription_plan)
            setQuantity(quantity)
            setStartDate(new Date(start_date))
            setEndDate(new Date(end_date))
            setReseller(reseller._id)
            setContactName(contact_name)
            setContactEmail(contact_email)
        }
    }, [couponGroup])

    const onStartDateChange = (newStartDate) => {
        if (newStartDate > endDate) {
            const day = 1000 * 60 * 60 * 24
            setEndDate(new Date(+newStartDate + day))
        }
        setStartDate(newStartDate)
    }

    const onIsTimeframeChange = (value) => {
        setIsTimelimit({...isTimelimit, value})
    }

    const setValidationMessage = useCallback(
        (message, type) => {
            dispatch(
                setMessage({
                    message,
                    type: type === 'fail' ? 'alert-danger' : 'alert-success',
                })
            )
        },
        [dispatch]
    )

    const validateQuantity = (quantity) => {
        if (quantity <= 0) {
            return 'Coupons quantity must be greater then 0'
        }
        if (quantity > 1000) {
            return 'Coupons quantity must be less then 1000'
        }
    }

    const getCouponsErrMsg = (quantityValidation) =>
        quantityValidation || 'Fields are not valid'

    const onSaveCouponGroup = () => {
        const areFieldsValid = checkValidFields({
            subscriptionPlan,
            reseller,
            contactName,
            contactEmail,
            quantity,
            startDate,
            endDate,
        })
        const quantityValidation = validateQuantity(quantity)

        if (!areFieldsValid || quantityValidation) {
            const errMsg = getCouponsErrMsg(quantityValidation)
            setValidationMessage(errMsg, 'fail')
            return setShowError(true)
        }
        const formatStartDate = isTimelimit.value
            ? startDate.toISOString().split('T').shift()
            : null
        const formatEndDate = isTimelimit.value
            ? endDate.toISOString().split('T').shift()
            : null

        if (couponGroupId === 'new') {
            saveCouponGroupPage({
                subscriptionPlan,
                resellerId: reseller,
                contactName,
                contactEmail,
                quantity,
                startDate: formatStartDate,
                endDate: formatEndDate,
            })
        } else {
            updateCouponGroupPage({
                couponGroupId,
                subscription_plan: subscriptionPlan,
                reseller: reseller,
                contact_name: contactName,
                contact_email: contactEmail,
            })
        }
    }
    const onRemoveLabel = () => {
        alertRef.current()
    }
    const deactivateLabel = (couponGroupId) => {
        deleteCatalogLabel(couponGroupId)
    }

    useEffect(() => {
        let msg = `Error saving ${subscriptionPlan}`
        if (isErrorDeleting) {
            msg = `Error deleting ${subscriptionPlan}`
        }
        if (isErrorSaving || isErrorDeleting || isErrorUpdating) {
            setValidationMessage(msg, 'fail')
        }
    }, [
        isErrorSaving,
        isErrorDeleting,
        isErrorUpdating,
        setValidationMessage,
        subscriptionPlan,
    ])

    useEffect(() => {
        let msg = `${subscriptionPlan} has been saved!`
        if (isSuccessDeleting) {
            msg = `${subscriptionPlan} has been deleted!`
        }
        if (isSuccessSaving || isSuccessDeleting || isSuccessUpdating) {
            setValidationMessage(msg, 'success')
            history.push('/admin/coupons')
        }
    }, [
        isSuccessSaving,
        isSuccessDeleting,
        isSuccessUpdating,
        setValidationMessage,
        subscriptionPlan,
        history,
    ])

    const isLoading = couponGroupFromServer.isLoading

    const quantityOptions = new Array(5).fill({}).map((_, index) => ({
        label: 500 + 500 * index,
        value: 500 + 500 * index,
    }))

    const onChangeQuantity = (value) => {
        setQuantity(Number(value))
    }

    return (
        <Page>
            <PageContent>
                <DetailsWrapper
                    isLoading={isLoading}
                    header="Edit Coupon Group"
                    backButtonText="Back to Coupons"
                    saveButtonText="Save Coupon Group"
                    saveButtonAction={onSaveCouponGroup}
                    deleteButtonText="Delete Coupon Group"
                    deleteButtonAction={onRemoveLabel}
                    disableDelete={validate}>
                    <Row>
                        <SelectInput
                            name={'Subscription Plan'}
                            label={'Subscription Plan'}
                            options={plans.data}
                            selected={subscriptionPlan}
                            onChange={setSubscriptionPlan}
                            placeholder={'Subscription Plan'}
                            disabled={couponGroupId !== 'new'}
                        />
                        <SelectInput
                            name={'Quantity'}
                            label={'Quantity'}
                            options={quantityOptions}
                            selected={quantity}
                            onChange={onChangeQuantity}
                            placeholder={'Quantity'}
                            disabled={couponGroupId !== 'new'}
                        />
                        <TimeframeInput
                            timelimit={isTimelimit}
                            startDateValue={startDate}
                            endDateValue={endDate}
                            setterTimelimit={onIsTimeframeChange}
                            setterStartDateValue={onStartDateChange}
                            setterEndDateValue={setEndDate}
                            disabled={couponGroupId !== 'new'}
                        />
                    </Row>
                    <Row>
                        <SelectInput
                            name={'Reseller'}
                            label={'Reseller'}
                            options={resellersOptionsList}
                            selected={reseller}
                            onChange={setReseller}
                            placeholder={'Reseller'}
                        />
                        <DetailsInput
                            type="text"
                            name="Contact Name"
                            placeholder="Contact Name"
                            value={contactName}
                            setter={setContactName}
                            showError={showError}
                        />
                        <DetailsInput
                            type="text"
                            name="Contact Email"
                            placeholder="Contact Email"
                            value={contactEmail}
                            setter={setContactEmail}
                            showError={showError}
                        />
                    </Row>
                    <SweetAlert
                        title={'Are you sure?'}
                        confirmBtnText="Delete Coupon Group"
                        mainText="Coupon Group"
                        action="deleted"
                        ref={alertRef}
                        deactivate={deactivateLabel}
                        id={couponGroupId}
                    />
                </DetailsWrapper>
            </PageContent>
        </Page>
    )
}

export default Coupon
