import React from 'react'
import logoPDFHygear from 'assets/img/logoPdfHygear.svg'
import {styled} from 'common'

const Headers = ({dateTitle = '', pageTitle = 'Page 1 out of 2'}) => {
    return (
        <div class="d-flex justify-content-between">
            <img
                className="rounded float-start align-self-center"
                src={logoPDFHygear}
                alt="logo"
                width={117}
                height={33}
            />
            <div className="p-2">
                <Title>OVERVIEW WEEKLY REPORT</Title>
                <DateTitle>({dateTitle})</DateTitle>
            </div>
            <PageText>{pageTitle}</PageText>
        </div>
    )
}

const Title = styled.p`
    font-size: 16px;
    font-weight: 700;
    font-family: 'Hanken Grotesk';
    font-optical-sizing: auto;
    font-style: normal;
    line-height: 20.85px;
    color: black;
`

const DateTitle = styled.p`
    font-size: 10px;
    text-align: center;
    font-family: 'Hanken Grotesk';
    font-optical-sizing: auto;
    font-weight: 600;
    color: black;
`

const PageText = styled.p`
    font-size: 8px;
    text-align: center;
    color: black;
    margin-top: 12px;
`

export default Headers
