import {Input} from 'reactstrap'
import {validateNumberInput} from 'services/utils'
import styled from 'styled-components'

const DefaultVariablesInput = ({type, setDefault, value, defaultNumber}) => {
    return (
        <InputBox
            className="form-control text-center"
            value={value}
            placeholder={defaultNumber}
            onChange={(ev) =>
                validateNumberInput(ev.target.value) &&
                setDefault(ev.target.value)
            }
        />
    )
}

export default DefaultVariablesInput

const InputBox = styled(Input)`
    width: 40px;
    margin-inline: 2px;
`
