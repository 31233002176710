import {createApi} from '@reduxjs/toolkit/query/react'
import {axiosBaseQuery} from 'store/common'

const transformResponseFromData = (response) => response.data

const provideTag = (tagName, data) =>
    data
        ? {type: tagName, id: `${data.device_type}/${data.start_of_quarter}`}
        : {}

const provideTags = (tagName, data) =>
    data
        ? [
              ...data.map(({name, label}) => ({
                  type: tagName,
                  id: tagName === 'RoutineGroupByDevice' ? label : name,
              })),
              {type: tagName, id: 'LIST'},
          ]
        : [{type: tagName, id: 'LIST'}]

const routineApi = createApi({
    baseQuery: axiosBaseQuery(),

    reducerPath: 'routine',
    tagTypes: [
        'Routine',
        'RoutineGroupByDevice',
        'RoutineGroupByDeviceQuarter',
    ],
    endpoints: (build) => ({
        getRoutines: build.query({
            query: (params) => ({url: 'admin/dhr', method: 'GET', params}),
            transformResponse: transformResponseFromData,
            providesTags: (data) => provideTags('Routine', data?.data),
        }),
        getRoutinesGroupByDevice: build.query({
            query: () => ({
                url: '/admin/dhr/group-by-device',
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) =>
                provideTags('RoutineGroupByDevice', data?.dhrs),
        }),
        getRoutinesGroupByDeviceQuarter: build.query({
            query: (params) => ({
                url: '/admin/dhr/group-by-device-quarter',
                method: 'GET',
                params,
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) =>
                provideTags('RoutineGroupByDeviceQuarter', data?.dhrs),
        }),
        getRoutineDetailQuarter: build.query({
            query: (params) => ({
                url: '/admin/dhr/detail-quarter',
                method: 'GET',
                params,
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('Routine', data)],
        }),
        verifyRoutineName: build.mutation({
            query: (body) => ({
                url: '/admin/dhr/verify-dhr-name',
                method: 'POST',
                body,
            }),
            transformResponse: transformResponseFromData,
        }),
        saveRoutine: build.mutation({
            query: ({routine}) => ({
                url: 'admin/dhr/',
                method: 'POST',
                body: routine,
            }),
            transformResponse: transformResponseFromData,
            invalidatesTags: (result, error, data) => [
                {type: 'Routine', id: 'LIST'}, // invalidate getRoutines
                {
                    type: 'Routine',
                    id: `${data.routine.device_type}/${data.routine.start_date}`,
                }, // invalidate getRoutineDetailQuarter
                {type: 'RoutineGroupByDevice', id: 'LIST'}, // invalidate getRoutinesGroupByDevice
                {type: 'RoutineGroupByDeviceQuarter', id: 'LIST'}, // invalidate getRoutinesGroupByDeviceQuarter
            ],
        }),
    }),
})

export default routineApi

export const {
    useSaveRoutineMutation,
    useGetRoutinesQuery,
    useGetRoutinesGroupByDeviceQuery,
    useGetRoutinesGroupByDeviceQuarterQuery,
    useGetRoutineDetailQuarterQuery,
    useVerifyRoutineNameMutation,
} = routineApi
