import {Row, Col} from 'components/general/Control'
import {movementPatterns} from 'constant/exercise'
import SelectInput from 'components/general/Control/SelectInput'

const MovementPattern = ({showError, movementPattern, setMovementPattern}) => {
    return (
        <Row form>
            <Col md={4}>
                <SelectInput
                    required
                    showError={showError}
                    label={'movement pattern'}
                    options={movementPatterns}
                    name={'movement_pattern'}
                    selected={movementPattern}
                    onChange={setMovementPattern}
                />
            </Col>
        </Row>
    )
}

export default MovementPattern
