import {ImageUpload} from 'components/Exercise/Details'
import styled from 'styled-components'

const WorkoutImages = ({
    imageFile,
    setImageFile,
    longImage,
    setLongImage,
    smallImage,
    setSmallImage,
    showError,
}) => {
    return (
        <ImageUploadContainer>
            <ImageUpload
                required
                imageFile={imageFile}
                setImage={setImageFile}
                showError={showError}
                title="Main Image"
            />
            <ImageUpload
                required
                height={'226px'}
                width={'159px'}
                title="Long Image"
                imageFile={longImage}
                setImage={setLongImage}
                showError={showError}
            />
            <ImageUpload
                required
                height={'155px'}
                width={'140px'}
                title="Small Image"
                imageFile={smallImage}
                setImage={setSmallImage}
                showError={showError}
            />
        </ImageUploadContainer>
    )
}

export default WorkoutImages

const ImageUploadContainer = styled.div`
    min-height: 350px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
`
