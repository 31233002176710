import {React} from 'common'
import {Page} from 'components/general/Page'
import {TableWrapper} from 'components/general/Display/Table'

import {useGetCouponsPagesQuery, useGetSubscriptionPlanQuery} from 'store/store'

import {useMemo} from 'services/hooks'
import TableLink from 'components/general/Display/Table/TableLink'
import api from 'services/api'

const columns = [
    {
        Header: 'Reseller',
        accessor: 'reseller',
        filter: 'default',
        isSortableIcon: true,
    },
    {
        Header: 'Subscription Plan',
        accessor: 'subscriptionPlan',
        filter: 'default',
        isSortableIcon: true,
    },
    {
        Header: 'Coupon Used',
        accessor: 'couponUsed',
        filter: 'default',
        isSortableIcon: true,
    },
    {
        Header: 'Timeframe',
        accessor: 'timeframe',
        filter: 'default',
        isSortableIcon: true,
    },
    {
        Header: 'Contact Name',
        accessor: 'contactName',
        filter: 'default',
        isSortableIcon: true,
    },
    {
        Header: 'Contact Email',
        accessor: 'contactEmail',
        filter: 'default',
        isSortableIcon: true,
    },
    {
        Header: 'Download QR',
        accessor: 'qr',
        maxWidth: 70,
        isDownloadable: true,
    },
]

const formatTimeframe = (start, end) => {
    if (start && end) {
        const splitedStart = start.split('-')
        const formatStart = `${splitedStart[1]}/${splitedStart[2]}/${splitedStart[0]}`
        const splitedEnd = end.split('-')
        const formatEnd = `${splitedEnd[1]}/${splitedEnd[2]}/${splitedEnd[0]}`
        return `${formatStart} - ${formatEnd}`
    }
    return 'No Limit'
}
const Coupons = () => {
    const coupons = useGetCouponsPagesQuery('en', {
        refetchOnMountOrArgChange: true,
    })
    const plans = useGetSubscriptionPlanQuery('en', {
        refetchOnMountOrArgChange: true,
    })

    const onDownloadQR = (couponsGroupId) => {
        const link = `${api.baseUrl}admin/coupons/qr/${couponsGroupId}`
        window.open(link, '_blank')
    }

    const data = useMemo(
        () =>
            coupons?.data?.map((prop, key) => {
                const subscriptionPlan = plans.data
                    .filter((plan) => prop.subscription_plan === plan.value)
                    .pop()

                return {
                    id: prop._id,
                    reseller: prop.reseller.name,
                    subscriptionPlan: subscriptionPlan?.label,
                    couponUsed: prop.couponsUsedDisplay,
                    // timeframe: prop.timeframe,
                    timeframe: formatTimeframe(prop.start_date, prop.end_date),
                    contactName: prop.contact_name,
                    contactEmail: prop.contact_email,
                    qr: (
                        <TableLink
                            color="green"
                            icon={'tim-icons icon-single-copy-04'}
                            onClick={() => onDownloadQR(prop._id)}
                            text="Download"
                        />
                    ),
                    pathname: `/admin/coupon/${prop._id}`,
                    _id: prop._id,
                    labelsCount: prop.labels?.length,
                }
            }),
        [coupons.data, plans.data]
    )

    return (
        <Page hideSidbar={false}>
            <TableWrapper
                title="Coupons"
                columns={columns}
                data={data}
                createNew={'/admin/coupon/new'}
                isError={coupons?.isError}
            />
        </Page>
    )
}

export default Coupons
