import {bandAngles} from 'constant/exercise'
import {Col, FormGroup, Row} from 'components/general/Control'
import RadioButtonsInput from 'components/general/Control/RadioButtonsInput'

const BandAngle = ({showError, bandAngle, setBandAngle}) => {
    return (
        <Row>
            <Col className="checkbox-radios py-2" sm="12" lg="8">
                <FormGroup>
                    <RadioButtonsInput
                        required
                        showError={showError}
                        options={bandAngles}
                        name={'band_angle'}
                        selected={bandAngle ? +bandAngle : bandAngle}
                        label={'band angle'}
                        onChange={setBandAngle}
                    />
                </FormGroup>
            </Col>
        </Row>
    )
}

export default BandAngle
