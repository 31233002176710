import {Col, FormGroup, Row} from 'components/general/Control'
import {anchorLocationOptions} from 'constant/exercise'
import RadioButtonsInput from 'components/general/Control/RadioButtonsInput'

const AnchorLocation = ({showError, anchorLocation, setAnchorLocation}) => {
    return (
        <Row>
            <Col className="checkbox-radios py-2 pr-md-0" sm="12" md="6">
                <FormGroup>
                    <RadioButtonsInput
                        required
                        showError={showError}
                        options={anchorLocationOptions}
                        name={'anchor_location'}
                        selected={anchorLocation}
                        label={'anchor location'}
                        onChange={setAnchorLocation}
                    />
                </FormGroup>
            </Col>
        </Row>
    )
}

export default AnchorLocation
