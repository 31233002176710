import {styled} from 'common'
import {CardTitle} from 'components/general/Control'
import React from 'react'
import IconStrength from 'assets/img/icon-strength.svg'
import IconHearts from 'assets/img/icon-hearts.svg'
import IconWalk from 'assets/img/icon-walk.svg'

const PlanGoalDescription = ({StagePlanData = []}) => {
    return (
        <Cards className="bg-white mt-4 mb-4 p-3">
            <CardTitles>Plan Goal Description</CardTitles>
            <hr />

            <ContainerCard>
                {StagePlanData && StagePlanData.length > 0
                    ? // eslint-disable-next-line complexity
                      StagePlanData.map((item, index) => (
                          <CardStage key={index} isFirst={index === 0}>
                              <TitleStage>Stage {item.stage}</TitleStage>
                              <CardContentStage>
                                  <div className="d-flex">
                                      <img
                                          src={IconStrength}
                                          alt="strength"
                                          width={25}
                                          height={25}
                                      />
                                      <div className="ml-2">
                                          <TitleContentStage>
                                              Strength
                                          </TitleContentStage>
                                          <SubContentStage>
                                              {item.strengthWeeklyGoal
                                                  ? item.strengthWeeklyGoal
                                                  : 0}{' '}
                                              x{' '}
                                              {item.strengthRepetitionsGoal
                                                  ? item.strengthRepetitionsGoal
                                                  : 0}{' '}
                                              reps
                                          </SubContentStage>
                                      </div>
                                  </div>
                                  <div className="d-flex">
                                      <img
                                          src={IconHearts}
                                          alt="hearts"
                                          width={25}
                                          height={25}
                                      />
                                      <div className="ml-2">
                                          <TitleContentStage>
                                              Aerobic
                                          </TitleContentStage>
                                          <SubContentStage>
                                              {item.aerobicWeeklyGoal
                                                  ? item.aerobicWeeklyGoal
                                                  : 0}{' '}
                                              x{' '}
                                              {item.aerobicMinutesGoal
                                                  ? item.aerobicMinutesGoal
                                                  : 0}{' '}
                                              min
                                          </SubContentStage>
                                          <SubContentStage>
                                              Heart rate{' '}
                                              {item.heartRate
                                                  ? item.heartRate
                                                  : 55}
                                              %
                                          </SubContentStage>
                                      </div>
                                  </div>
                                  <div className="d-flex">
                                      <img
                                          src={IconWalk}
                                          alt="walk"
                                          width={25}
                                          height={25}
                                      />
                                      <div className="ml-2">
                                          <TitleContentStage>
                                              Steps
                                          </TitleContentStage>
                                          <SubContentStage>
                                              {item.stepsWeeklyGoal
                                                  ? item.stepsWeeklyGoal
                                                  : 0}{' '}
                                              x{' '}
                                              {item.stepsGoal
                                                  ? item.stepsGoal
                                                  : 0}{' '}
                                              steps
                                          </SubContentStage>
                                      </div>
                                  </div>
                              </CardContentStage>
                          </CardStage>
                      ))
                    : null}
            </ContainerCard>
        </Cards>
    )
}

export default PlanGoalDescription

const ContainerCard = styled.div`
    display: flex;
    gap: 20px 15px;
    flex-wrap: wrap;
    padding: 5px;
`

const Cards = styled.div`
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 10px;
`

const CardTitles = styled(CardTitle)`
    font-size: 14px;
    color: black;
    font-family: 'Hanken Grotesk';
    font-weight: 800;
`

const TitleContentStage = styled.p`
    font-size: 12px;
    color: black;
    font-family: 'Hanken Grotesk';
    font-weight: 400;
`
const SubContentStage = styled.p`
    font-size: 12px;
    color: black;
    font-weight: 600;
    font-family: 'Hanken Grotesk';
`

const CardStage = styled.div`
    min-width: 150px;
    max-width: 150px;
    background-color: rgba(237, 241, 248, 1);
`
const CardContentStage = styled.div`
    background-color: rgba(229, 238, 255, 255);
    padding: 10px;
`

const TitleStage = styled.p`
    font-size: 14px;
    font-weight: 800;
    color: black;
    padding: 10px;
    font-family: 'Hanken Grotesk';
`
