import {React} from 'common'

import {useGetCatalogsPagesQuery} from 'store/store'
import {useMemo} from 'services/hooks'

import {Page} from 'components/general/Page'
import {TableWrapper} from 'components/general/Display/Table'

const columns = [
    {
        Header: 'First Name',
        accessor: 'name',
        filter: 'default',
        isSortableIcon: true,
    },
    {
        Header: 'Labels Count',
        accessor: 'labelsCount',
    },
]

const CatalogPages = () => {
    const catalog = useGetCatalogsPagesQuery(null, {
        refetchOnMountOrArgChange: true,
    })

    const data = useMemo(
        () =>
            catalog?.data?.pages?.map((prop, key) => {
                return {
                    id: prop._id,
                    name: prop.name,
                    _id: prop._id,
                    pathname: `/admin/catalog-pages/${prop._id}`,
                    labelsCount: prop.labels?.length,
                    labels: prop.labels,
                }
            }),
        [catalog.data]
    )

    return (
        <Page hideSidbar={false}>
            <TableWrapper
                title="Catalog pages"
                columns={columns}
                data={data}
                createNew={{
                    pathname: '/admin/catalog-pages/new',
                    page: {
                        page: {labels: []},
                        available_labels: catalog?.data?.available_labels,
                    },
                }}
                isError={catalog?.isError}
            />
        </Page>
    )
}

export default CatalogPages
