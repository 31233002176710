import React from 'react'
import {FormGroup} from 'components/general/Control'
import AppInput from 'components/general/Control/Input'
import {Col} from 'react-bootstrap'

const NameInput = ({
    name = '',
    label = '',
    setName,
    showError,
    nameError,
    disableName,
    sm = 12,
    md = 6,
    lg = 6,
}) => {
    return (
        <Col className="pl-0 pr-0 pr-sm-0 pr-md-3" sm={sm} lg={lg} md={md}>
            <FormGroup>
                <label>{`${label} NAME *`}</label>
                <AppInput
                    disable={disableName}
                    name="name"
                    placeholder="Enter Exercise Name"
                    value={name}
                    setter={setName}
                    showError={showError}
                    disableAutoComplete
                />
                {nameError && (
                    <small className="text-danger initialism">
                        {name + ' is already exist'}
                    </small>
                )}
            </FormGroup>
        </Col>
    )
}

export default NameInput
