import {React, styled} from 'common'
import {Card, CardHeader, CardBody, CardTitle} from 'components/general/Control'

const MyCard = ({imageHeader, header, title, children, width, height}) => {
    return (
        <StyledCard width={width} height={height}>
            {imageHeader && <StyledImgHeader>{imageHeader}</StyledImgHeader>}
            {title && <CardTitle>{title}</CardTitle>}
            <StyledCardBody>{children}</StyledCardBody>
        </StyledCard>
    )
}

export default MyCard

const StyledCard = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${({width}) => width + 'px'};
    height: ${({height}) => height + 'px'};
    align-self: center;
`
const StyledImgHeader = styled(CardHeader)`
    border-radius: 50%;
    width: 20%;
    margin-top: -10%;
`
const StyledCardBody = styled(CardBody)`
    width: 100%;
`
