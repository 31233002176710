import {createApi} from '@reduxjs/toolkit/query/react'

import {
    axiosBaseQuery,
    transformResponseFromData,
    transformResponseFromMessage,
    provideTags,
    provideTag,
} from 'store/common'

const userApi = createApi({
    baseQuery: axiosBaseQuery(),

    reducerPath: 'user',
    tagTypes: [
        'Stats',
        'User',
        'Csv',
        'UserCsv',
        'Versions',
        'osl',
        'yunxi',
        'jointcorp',
        'hygear',
        'UserInDelteion',
    ],
    endpoints: (build) => ({
        getAccounts: build.query({
            query: () => ({
                url: 'accounts',
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => provideTags('User', data?.data),
        }),
        getUsers: build.query({
            query: () => ({url: 'admin/user', method: 'GET'}),
            transformResponse: transformResponseFromData,
            providesTags: (data) => provideTags('User', data?.data),
        }),
        getUser: build.query({
            query: (id) => ({url: `admin/user/${id}`, method: 'GET'}),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('User', data, 'id')],
        }),
        deleteUser: build.mutation({
            query: (id) => ({
                url: `/admin/user/${id}`,
                method: 'DELETE',
            }),
            transformResponse: transformResponseFromData,
            invalidatesTags: (data) => [provideTag('User', data, 'id')],
        }),
        stats: build.query({
            query: () => ({url: 'admin/stats', method: 'GET'}),
            transformResponse: transformResponseFromData,
            providesTags: (data) =>
                data ? [provideTag('Stats', data, 'users')] : [],
        }),
        getVersions: build.query({
            query: () => ({url: 'firmware/lastversion', method: 'GET'}),
            transformResponse: transformResponseFromData,
            providesTags: (data) =>
                Object.keys(data || {}).map((dataType) => {
                    return provideTag(dataType, data[dataType], '_id')
                }),
        }),
        updateVersions: build.mutation({
            query: (body) => {
                console.log({body}, 'mutation of update version')
                return {
                    url: 'firmware',
                    method: 'POST',
                    body,
                }
            },
            transformResponse: transformResponseFromData,
            invalidatesTags: (data) => {
                console.log({data}, 'return from update version')
                return [provideTag(data.device_type, data, '_id')]
            },
        }),
        updateUser: build.mutation({
            query: ({user}) => {
                return {
                    url: `admin/user/${user.id}`,
                    method: 'PUT',
                    body: user,
                }
            },

            providesTags: (data) => [provideTag('User', data, 'id')],
            invalidatesTags: (result, error, data) =>
                result && [{type: 'User', id: data?.id}],
            transformResponse: transformResponseFromData,
        }),
        getUsersSpreadsheet: build.query({
            query: () => ({url: 'admin/user/csv', method: 'GET'}),
            transformResponse: (res) => {
                console.log('RESCSV', res)
                return res
            },
            // transformResponse: transformResponseFromMessage,
            providesTags: (data) => [provideTag('Csv', data || [], 'url')],
        }),
        getUserSpreadsheet: build.query({
            query: (id) => ({url: `admin/user/csv/${id}`, method: 'GET'}),
            transformResponse: transformResponseFromMessage,
            providesTags: (data) => [provideTag('UserCsv', data, 'url')],
        }),
        getUsersInDeletion: build.query({
            query: (id) => ({url: `admin/user/inDeleteion`, method: 'GET'}),
            transformResponse: transformResponseFromData,
            providesTags: (data) => provideTags('UserInDelteion', data),
        }),

        getUserReportPDF: build.query({
            query: (id) => ({
                url: `admin/statistics/weekly/${id}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            // providesTags: (data) => provideTags('ReportWeekly', data?.data),
        }),
    }),
})

export default userApi

export const {
    useUpdateUserMutation,
    useDeleteUserMutation,
    useGetUserSpreadsheetQuery,
    useGetUsersSpreadsheetQuery,
    useGetUsersQuery,
    useGetUserQuery,
    useGetVersionsQuery,
    useStatsQuery,
    useUpdateVersionsMutation,
    useGetUsersInDeletionQuery,
    useLazyGetAccountsQuery,
    useLazyGetUserReportPDFQuery,
} = userApi
