/* eslint-disable no-unused-vars */

import MultiSelectDropdown from 'components/FilterExercisesWoBuilder.js/MultiSelectDropdown'
import {Col, Row} from 'components/general/Control'
import AppInput from 'components/general/Control/Input'
import RadioButtonsInput from 'components/general/Control/RadioButtonsInput'
import {useMemo, useState} from 'react'
import styled from 'styled-components'

const filterByGenderOptions = [
    {
        value: 'female',
        label: 'Famale',
    },
    {
        value: 'male',
        label: 'Male',
    },
    {value: '', label: 'All Genders'},
]
/*
exercise_type
purpose
posture
action
segment
band_angle
muscle_group
// difficulty
primary_plane
trainer_gender
anchor_location
movement_pattern
contraction_focus
anchor_orientation
anchor_position_type
grip_type // attachement section in web
*/

const styling = (inputFocused) => {
    if (inputFocused) {
        return {
            search: {
                class: 'px-1 mb-1',
                size: 12,
            },
            gender: {
                class: 'px-0',
                size: 0,
            },
            dropDown: {
                class: 'px-0',
                size: 0,
            },
        }
    }

    return {
        search: {
            class: 'px-1 mb-1',
            size: 3,
        },
        gender: {
            class: 'px-1 mb-1',
            size: 7,
        },
        dropDown: {
            class: 'px-1 mb-1',
            size: 2,
        },
    }
}

const FilterExercises = ({
    searchText,
    setSearchText,
    filterByGender,
    setFilterByGender,
    selectedFilterTypes,
    setSelectedFilterTypes,
}) => {
    const [inputFocused, setInputFocused] = useState(false)
    const typing = inputFocused ? 1 : 0
    const styles = styling(inputFocused)

    return (
        <Row className="d-flex justify-content-beteween mx-0 mt-2 mb-1">
            <StyledInput
                sm={12}
                md={styles.search.size}
                lg={styles.search.size}
                typing={typing}
                className={styles.search.class}>
                <AppInput
                    inputFocused={inputFocused}
                    setInputFocused={setInputFocused}
                    name="filter_exercises"
                    placeholder="Search. . ."
                    value={searchText}
                    setter={setSearchText}
                />
            </StyledInput>
            <StyledCol
                sm={8}
                md={styles.gender.size}
                lg={styles.gender.size}
                typing={typing}
                className={styles.gender.class}>
                <RadioButtonsInput
                    filter
                    selected={filterByGender}
                    onChange={setFilterByGender}
                    options={filterByGenderOptions}
                    name={'filter_by_trainer_gender'}
                />
            </StyledCol>
            <StyledCol
                sm={4}
                md={styles.dropDown.size}
                lg={styles.dropDown.size}
                typing={typing}
                className={styles.dropDown.class}>
                <MultiSelectDropdown
                    isInputFocused={inputFocused}
                    selectedItems={selectedFilterTypes}
                    setSelectedItems={setSelectedFilterTypes}
                />
            </StyledCol>
        </Row>
    )
}

export default FilterExercises

const StyledInput = styled(Col)`
    flex-grow: ${({typing}) => (typing ? 1 : 0.5)};
    /* transition: all 0.4s ease-in; */
`

const StyledCol = styled(Col)`
    ${({typing}) => typing && 'display:none'};
`
