import {React, styled} from 'common'
import {Spinner as Spin, Row, Col, Label} from 'components/general/Control'

const Spinner = ({label}) => {
    return (
        <Row className="w-100 justify-content-center flex-grow-1">
            <Col
                xs={12}
                className="w-100 h-100 d-flex flex-column align-items-center justify-content-center flex-grow-1">
                <StyledSpin color={'primary'} />
                {label && <Label className="my-3">{label}</Label>}
            </Col>
        </Row>
    )
}

export default Spinner

const StyledSpin = styled(Spin)`
    width: 3rem;
    height: 3rem;
    span {
        visibility: hidden;
    }
`
