import {Col} from 'components/general/Control'
import {difficultyOptions} from 'constant/exercise'
import SelectInput from 'components/general/Control/SelectInput'

const DifficultySelection = ({difficulty, setDifficulty, showError}) => {
    return (
        <Col md={6} className="px-0  pr-md-3">
            <SelectInput
                required
                showError={showError}
                label={'difficulty'}
                selected={difficulty ? +difficulty : difficulty}
                onChange={setDifficulty}
                options={difficultyOptions}
                name={'difficulty_selection'}
            />
        </Col>
    )
}

export default DifficultySelection
