import {React, styled} from 'common'

import {ImageUpload} from 'components/Details'
import {Row} from 'components/general/Control'

const ImagesUploaders = ({
    avatarImageFile,
    setAvatarImageFile,
    coverImageFile,
    setCoverImageFile,
}) => {
    return (
        <Row>
            <ImageUploadContainer>
                <ImageUpload
                    imageFile={avatarImageFile}
                    setImage={setAvatarImageFile}
                />
            </ImageUploadContainer>
            <ImageUploadContainer>
                <ImageUpload
                    imageFile={coverImageFile}
                    setImage={setCoverImageFile}
                />
            </ImageUploadContainer>
        </Row>
    )
}

export default ImagesUploaders
const ImageUploadContainer = styled.div`
    margin-block: 20px;
`
