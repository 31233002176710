import table from './Table/Table'
import tablePagination from './Table/TablePagination'
import spinner from './Spinner'
import sweetAlert from './SweetAlert'
import sweetMessage from './SweetMessage'
import language from './Language'

export const Table = table
export const TablePagination = tablePagination
export const Spinner = spinner
export const SweetAlert = sweetAlert
export const SweetMessage = sweetMessage
export const Language = language
