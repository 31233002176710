import {React} from 'common'
import TableHeaderTitleAndSort from './TableHeaderTitleAndSort'
import TablePagination from './TablePagination'

const TableHeader = ({
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageIndex,
    pageSize,
    setPageSize,
    gotoPage,
    pageOptions,
    pageCount,
    headerGroups,
}) => {
    return (
        <thead>
            <TablePagination
                nextPage={nextPage}
                canNextPage={canNextPage}
                previousPage={previousPage}
                canPreviousPage={canPreviousPage}
                pageIndex={pageIndex}
                pageSize={pageSize}
                setPageSize={setPageSize}
                gotoPage={gotoPage}
                pageOptions={pageOptions}
                pageCount={pageCount}
            />
            <TableHeaderTitleAndSort headerGroups={headerGroups} />
        </thead>
    )
}

export default TableHeader
