import {React} from 'common'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import {useState, useEffect, useCallback} from 'services/hooks'

const SweetAlert = React.forwardRef(
    (
        {
            deactivate,
            id,
            title,
            mainText,
            confirmBtnText,

            action,
            hideCancel,
            hideAlertOnConfirm,
            hideAlertExtraFunction,
        },
        ref
    ) => {
        const [alert, setAlert] = useState(null)

        const showWarningConfirmAndCancelMessage = useCallback(() => {
            setAlert('warningWithConfirmAndCancelMessage')
        }, [])

        const hideAlert = () => {
            setAlert(null)
            hideAlertExtraFunction && hideAlertExtraFunction()
        }

        const onConfirm = () => {
            if (hideAlertOnConfirm) {
                hideAlertOnConfirm()
                hideAlert()
            } else {
                successDelete()
            }
        }

        const successDelete = () => setAlert('successDelete')

        const cancelDelete = () => setAlert('cancelDelete')

        useEffect(() => {
            ref.current = showWarningConfirmAndCancelMessage
        }, [ref, showWarningConfirmAndCancelMessage])

        if (alert === 'warningWithConfirmAndCancelMessage') {
            return (
                <div className="content">
                    <ReactBSAlert
                        info
                        style={{display: 'block', marginTop: '-100px'}}
                        title={title}
                        onConfirm={() => onConfirm()}
                        onCancel={() =>
                            hideCancel ? hideAlert() : cancelDelete()
                        }
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        confirmBtnText={confirmBtnText}
                        cancelBtnText="Cancel"
                        showCancel={!hideCancel}
                        btnSize="">
                        {mainText} {action ? 'will be ' + action : ''}
                    </ReactBSAlert>
                </div>
            )
        }

        if (alert === 'successDelete') {
            return (
                <div className="content">
                    <ReactBSAlert
                        success
                        style={{display: 'block', marginTop: '-100px'}}
                        title={action + '!'}
                        onConfirm={() => {
                            deactivate(id)
                            hideAlert()
                        }}
                        onCancel={() => hideAlert()}
                        confirmBtnBsStyle="success"
                        btnSize="">
                        {mainText} has been {action}
                    </ReactBSAlert>
                </div>
            )
        }

        if (alert === 'cancelDelete') {
            return (
                <div className="content">
                    <ReactBSAlert
                        danger
                        style={{display: 'block', marginTop: '-100px'}}
                        title="Cancelled"
                        onConfirm={() => hideAlert()}
                        onCancel={() => hideAlert()}
                        confirmBtnBsStyle="success"
                        btnSize="">
                        {mainText} has not been {action}
                    </ReactBSAlert>
                </div>
            )
        }

        return null
    }
)

export default SweetAlert
