import {objectiveOptions} from 'constant/workout'
import SelectInput from 'components/general/Control/SelectInput'
const WorkoutObjective = ({objective, setObjective, showError}) => {
    return (
        <SelectInput
            required
            showError={showError}
            selected={objective}
            onChange={setObjective}
            options={objectiveOptions}
            name={'workout_objective'}
            label={'objective'}
        />
    )
}

export default WorkoutObjective
