import {React, styled} from 'common'
import {AppSelect} from 'components/general/Control'
import {useMemo, useEffect, useUrlParams} from 'services/hooks'

const SelectColumnFilter = ({
    column: {filterValue, setFilter, preFilteredRows, id},
}) => {
    const [urlParams, setUrlParam] = useUrlParams()

    const {filter} = urlParams

    const options = useMemo(() => {
        const options = new Set()
        options.add('All')
        preFilteredRows.forEach((row) => {
            let value = row.values[id]
            if (value && value.props) {
                value = value.props.defaultValue
            }
            options.add(value)
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    const formatOption = (option) => {
        if (option === 'All' || option === '') {
            return {value: 'All', label: 'All'}
        }
        return {value: option, label: option}
    }

    useEffect(() => {
        if (filter) {
            const parsedFilter = JSON.parse(filter)
            if (parsedFilter[id] === 'All') {
                if (filterValue && filterValue !== '') {
                    return setFilter(undefined)
                }
            } else if (parsedFilter[id] !== filterValue) {
                setFilter(parsedFilter[id])
            }
        }
    }, [filter, filterValue, id, setFilter])

    const onChangeValue = (value) => {
        const newFilter = filter ? JSON.parse(filter) : {}
        newFilter[id] = value
        setUrlParam('filter', JSON.stringify(newFilter))
    }

    return (
        <StyledSelect
            className={'form-control'}
            type="select"
            value={filterValue || 'All'}
            name={filterValue}
            onChange={(e) => {
                onChangeValue(e.target.value || '')
            }}
            options={options
                .sort()
                .reverse()
                .map((option) => formatOption(option))}
        />
    )
}

export default SelectColumnFilter

const StyledSelect = styled(AppSelect)``
