/* eslint-disable complexity */
import Animations from 'appearance/animations'
import DraggableItemForm from 'components/Workout/Details/WorkoutBuilder/DraggableItemForm'

import {React, styled} from 'common'
import {Draggable} from 'react-beautiful-dnd'
import {Input, FormGroup} from 'reactstrap'

import {Button} from 'components/general/Control'
import {usePathList} from 'services/hooks'
import DraggableItemFormProgram from './DraggableItemFormProgram'
import {calculateWorkout} from 'services/utils'

const getItemName = (item, isCheckboxExercise) => {
    if (!isCheckboxExercise) {
        if (item?.activity_type === 'transition') {
            return 'Change anchor point'
        }
        return item.name
    }

    return item?.display_name
        ? `${item?.display_name} <${item.name}> | ${getWoReps(
              item?.workout_activities
          )}`
        : `${item.name} | ${getWoReps(item?.workout_activities)}`
}

const getWoReps = (workoutActivities) => {
    return calculateWorkout(workoutActivities)?.totalTarget
}

const getItemInfo = (item) => {
    const isCheckboxExercise =
        item.activity_type === 'exercise' || item.activity_type === 'group'
    const isSection = item?.activity_type === 'section'
    const isTransition = item?.activity_type === 'transition'
    const isGroup = item?.activity_type === 'group'

    let itemClassName = 'bg-primary text-white'
    if (isSection) {
        itemClassName = 'bg-warning text-white text-center'
    } else if (isTransition) {
        itemClassName = 'bg-orange text-white text-center'
    }

    return {
        isCheckboxExercise,
        isSection,
        isTransition,
        isGroup,
        itemClassName,
    }
}

const DraggableItem = ({
    item,
    index,
    isClass,
    itemSelection,
    isSelected,
    handleSelection,
    onRemoveItem,
    defaultWork,
    defaultRest,
    defaultSets,
    children,
    selectedItems,
    setSelectedItems,
    isProgram,
    workoutsGaps,
    setWorkoutsGaps,
    programWorkoutFlowType,
}) => {
    const {
        isCheckboxExercise,
        isSection,
        isTransition,
        isGroup,
        itemClassName,
    } = getItemInfo(item)

    const showCheckboxControls = itemSelection && !isClass

    const path = usePathList()

    return (
        <ItemContainer
            className="d-flex w-100"
            isSection={isSection}
            itemSelection={itemSelection}
            isHighlight={item.highlight}>
            {showCheckboxControls && (
                <CheckBox className="custom-checkbox custom-control">
                    <Input
                        id={item.uuid}
                        className="custom-control-input"
                        type="checkbox"
                        checked={isSelected}
                        onChange={(ev) => {
                            handleSelection(item)
                        }}
                    />
                    <label
                        htmlFor={item.uuid}
                        className={`custom-control-label mt-2  ${
                            !isCheckboxExercise && 'd-none'
                        }`}></label>
                </CheckBox>
            )}

            <Draggable key={item.uuid} draggableId={item.uuid} index={index}>
                {(provided, snapshot) => {
                    const title = isGroup ? 'Group' : item.name
                    return (
                        <div
                            title={title}
                            className={
                                ' d-flex flex-column w-100 tooltip-list-draggable'
                            }
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            tooltip={item?.id}>
                            {/* Linter escape */}
                            {item?.image !== '' &&
                                !isProgram &&
                                !showCheckboxControls &&
                                !isGroup && (
                                    <span>
                                        <StyledButton
                                            className={'btn btn-success'}
                                            children={`Go To ${item.name}`}
                                            onClick={() =>
                                                window.open(
                                                    `/admin/${
                                                        path === 'routine'
                                                            ? 'workout'
                                                            : 'exercise'
                                                    }/${item.id}`,
                                                    '_blank'
                                                )
                                            }
                                        />
                                        <ImageHoverList src={item.image} />
                                    </span>
                                )}
                            {item?.image === '' &&
                                !isProgram &&
                                !showCheckboxControls &&
                                !isGroup && (
                                    <span>
                                        <StyledButton
                                            className={'btn btn-success'}
                                            children={`Go To ${item.name}`}
                                            onClick={() =>
                                                window.open(
                                                    `/admin/${
                                                        path === 'routine'
                                                            ? 'workout'
                                                            : 'exercise'
                                                    }/${item.id}`,
                                                    '_blank'
                                                )
                                            }
                                        />
                                        No Image
                                    </span>
                                )}
                            <div className="d-flex">
                                {!isGroup && (
                                    <Container
                                        className={itemClassName + ' d-flex'}>
                                        {getItemName(item, isCheckboxExercise)}
                                    </Container>
                                )}
                                {showCheckboxControls && !isGroup && (
                                    <DraggableItemForm
                                        item={item}
                                        itemIndex={index}
                                        defaultWork={defaultWork}
                                        defaultRest={defaultRest}
                                        defaultSets={defaultSets}
                                        isCheckboxExercise={isCheckboxExercise}
                                        isSection={isSection}
                                        isTransition={isTransition}
                                        selectedItems={selectedItems}
                                        setSelectedItems={setSelectedItems}
                                    />
                                )}
                                {/* ONLY FOR PROGRAM */}
                                {isProgram && (
                                    <DraggableItemFormProgram
                                        name="workout_gaps"
                                        index={index}
                                        values={workoutsGaps}
                                        setter={setWorkoutsGaps}
                                        programWorkoutFlowType={
                                            programWorkoutFlowType
                                        }
                                    />
                                )}
                            </div>
                            {children}
                        </div>
                    )
                }}
            </Draggable>
            {itemSelection && (
                <CloseButton
                    onClick={(ev) => {
                        ev.preventDefault()
                        onRemoveItem(item.uuid)
                    }}>
                    X
                </CloseButton>
            )}
        </ItemContainer>
    )
}

export default DraggableItem

const Container = styled.div`
    flex: 1;
    margin-top: 5px;
    font-weight: 500;
    border-radius: 4px;
    padding: 5px;
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Likely future */
`

const ItemContainer = styled.div`
    align-items: flex-start;
    ${({isHighlight}) => isHighlight && Animations.fadedHilight};
`

const CloseButton = styled.button`
    border: none;
    background-color: inherit;
    color: white;
    margin-left: 5px;
    opacity: 0.5;
    transition: opacity 0.2s;
    outline: none;
    padding-top: 10px;
    &:hover {
        opacity: 1;
    }
`
const CheckBox = styled(FormGroup)`
    margin-bottom: 0 !important;
    padding-top: 2px;
`

const StyledButton = styled(Button)`
    width: 100%;
`

const ImageHoverList = styled.img`
    max-height: 100%;
`
