import {React, styled} from 'common'
import RadioButtonsInput from 'components/general/Control/RadioButtonsInput'

const FirmwareToUpdateRadios = ({
    manufacturers,
    onSelectCompany,
    selectedManufactorer,
    devicesBySelectedManufactorer,
    setSelectedDevice,
    selectedDevice,
    fileTypesByManufactorerAndDevice,
    setSelectedFileType,
    selectedFileType,
}) => {
    return (
        <Container>
            <RadioButtonsInput
                label="Manufactorer"
                options={manufacturers}
                onChange={onSelectCompany}
                selected={selectedManufactorer}
            />
            <BottomButtonsContainer>
                <RadioButtonsInput
                    label="Device type"
                    options={devicesBySelectedManufactorer}
                    onChange={setSelectedDevice}
                    selected={selectedDevice}
                />
                {selectedFileType !== 'both' && (
                    <RadioButtonsInput
                        label="File type"
                        options={fileTypesByManufactorerAndDevice}
                        onChange={setSelectedFileType}
                        selected={selectedFileType}
                    />
                )}
            </BottomButtonsContainer>
        </Container>
    )
}

export default FirmwareToUpdateRadios

const Container = styled.div``
const BottomButtonsContainer = styled.div`
    display: flex;
`
