import {Row, Col} from 'components/general/Control'
import SelectInput from 'components/general/Control/SelectInput'
import {segmentMotion} from 'constant/exercise'

const SegmentMotion = ({showError, segment, setSegment}) => {
    return (
        <Row form>
            <Col md={4}>
                <SelectInput
                    required
                    showError={showError}
                    selected={segment}
                    onChange={setSegment}
                    name={'segment_motion'}
                    options={segmentMotion}
                    label={'segment motion'}
                />
            </Col>
        </Row>
    )
}

export default SegmentMotion
