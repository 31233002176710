import {React, styled} from 'common'

import {Row, Col, Label, Input} from 'components/general/Control'

const VersionsInputs = ({newFirmwareData, setNewFirmwareData, handleFile}) => {
    return (
        <InputsContainer>
            <Col>
                <Label for="version" sm={4} lg={8}>
                    Version Number
                </Label>
                <Input
                    type="type"
                    value={newFirmwareData?.version}
                    setter={(version) => {
                        setNewFirmwareData({
                            ...newFirmwareData,
                            version,
                        })
                    }}
                    id="version"
                    placeholder="Enter version number"
                />
            </Col>
            <Col>
                <Label for="file" sm={4} lg={8}>
                    New Version File
                </Label>
                <input
                    type="file"
                    onChange={(e) =>
                        handleFile(e, setNewFirmwareData, newFirmwareData)
                    }
                    name="file"
                    id="file"
                />
            </Col>
        </InputsContainer>
    )
}

export default VersionsInputs

const InputsContainer = styled(Row)`
    padding: 0 50px;
    text-align: center;
`
