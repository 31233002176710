import {Button} from 'components/general/Control'
import {TableWrapper} from 'components/general/Display/Table'
import {Page} from 'components/general/Page'
import {useMemo} from 'react'

import {useGetRoutinesGroupByDeviceQuery} from 'store/store'

const columns = [
    {
        Header: 'Gear',
        accessor: 'device_type',
    },
    {
        Header: 'Total Routine',
        accessor: 'total_routine',
    },
    {
        Header: '',
        accessor: 'path',
        Cell: () => (
            <Button id="save_button" color="primary" type="submit">
                See Detail
            </Button>
        ),
    },
]

const RoutineGears = () => {
    const {data: routines} = useGetRoutinesGroupByDeviceQuery()
    const data = useMemo(
        () =>
            routines?.dhrs.map((prop, key) => {
                return {
                    id: key,
                    device_type: prop.label,
                    total_routine: prop.total_routine,
                    pathname: `/admin/routines/${prop.device_type}`,
                    queryParams: `device_label=${prop.label}`,
                }
            }),
        [routines]
    )
    return (
        <Page hideSidbar={false}>
            <TableWrapper
                title="Daily Habit Routine"
                columns={columns}
                data={data}
                filterByVersion
            />
        </Page>
    )
}

export default RoutineGears
