import {React, styled} from 'common'
import {
    useState,
    useEffect,
    useCallback,
    useSelector,
    useDispatch,
    useMemo,
} from 'services/hooks'
import {setMessage, useAppMessage} from 'store/store'

const SweetMessage = React.forwardRef(({message}, ref) => {
    const [alert, setAlert] = useState(null)
    const dispatch = useDispatch()

    const appMessage = useSelector(useAppMessage)

    const setTheAlert = useMemo(
        () =>
            setAlert(
                <StyledAlert
                    id="response-alert"
                    top={appMessage.message ? 0 : -60}
                    className={`alert ${
                        appMessage.type ? appMessage.type : 'alert-success'
                    } alert-dismissible fade show`}
                    role="alert">
                    {appMessage.message}
                </StyledAlert>
            ),
        [appMessage]
    )

    const messageWithTimer = useCallback(() => {
        return setTheAlert
    }, [setTheAlert])

    useEffect(() => {
        ref.current = messageWithTimer
        if (appMessage.message) {
            setTimeout(() => {
                dispatch(setMessage({...appMessage, message: ''}))
            }, 3000)
        }
    }, [ref, messageWithTimer, appMessage, dispatch])

    return <div className="content">{alert}</div>
})

export default SweetMessage

const StyledAlert = styled.div`
    position: fixed;
    left: 0;
    top: ${({top}) => top}px;
    transition: top 1s;
    width: 100%;
    text-align: center;
    z-index: 9999999999;
`
