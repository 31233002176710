import {styled} from 'common'
import {CardTitle} from 'components/general/Control'
import React from 'react'
const WeeklyActiveRate = ({dataList}) => {
    return (
        <Cards className="bg-white mt-4 mb-4 p-3">
            <CardTitles>Weekly Active Rate</CardTitles>
            <hr />
            <CardBar className="d-flex row justify-content-center">
                {dataList.map((item, index) => (
                    <div
                        key={index}
                        className="mr-1"
                        style={{minWidth: '80px'}}>
                        <div className="d-flex flex-row">
                            <Title color={item.color}>{item.total}</Title>
                            <TitleSubs color={item.color}>
                                {item.title}
                            </TitleSubs>
                        </div>
                        <CardTexts>{item.description}</CardTexts>
                        <ContainerBar>
                            <TextPercentages color={item.color}>
                                {item.percentage ? item.percentage : 0}%
                            </TextPercentages>
                            <BarSubs
                                heightBar={item.heightBar}
                                bgColor={item.color}
                            />
                        </ContainerBar>
                    </div>
                ))}
            </CardBar>
        </Cards>
    )
}

const Cards = styled.div`
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 10px;
`

const CardTitles = styled(CardTitle)`
    font-size: 14px;
    color: black;
    font-family: 'Hanken Grotesk';
    font-weight: 800;
`

const CardBar = styled.div`
    padding-left: 20px;
    padding-right: 20px;
`
const Title = styled(CardTitle)`
    font-size: 16px;
    color: ${({color}) => color};
    font-weight: 900;
    margin-right: 4px;
    font-family: 'Hanken Grotesk';
    font-optical-sizing: auto;
`

const TitleSubs = styled(CardTitle)`
    font-size: 14px;
    font-weight: 800;
    color: ${({color}) => color};
    font-family: 'Hanken Grotesk';
    font-optical-sizing: auto;
`

const CardTexts = styled.p`
    font-size: 12px;
    color: black;
    margin-bottom: 20px;
    max-width: 130px;
    min-width: 130px;
    min-height: 1px;
    max-height: 1px;
    font-family: 'Hanken Grotesk';
    font-optical-sizing: auto;
`

const ContainerBar = styled.div`
    height: 150px;
    align-content: end;
`

const TextPercentages = styled.p`
    font-size: 14px;
    color: ${({color}) => color};
    font-family: 'Hanken Grotesk';
    font-optical-sizing: auto;
    font-weight: 800;
`

const BarSubs = styled.div`
    height: ${({heightBar}) => (heightBar / 100) * 80}px;
    width: 125px;
    align-self: end;
    margin-right: 10px;
    background-color: ${({bgColor}) => bgColor};
`

export default WeeklyActiveRate
