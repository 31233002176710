import React, {useEffect} from 'react'

import './App.css'
import NavigationRouter from 'navigation/NavigationRouter'
import {Provider} from 'react-redux'
import store from 'store/store'

function App() {
    useEffect(() => {}, [])

    return (
        <Provider store={store}>
            <NavigationRouter />
        </Provider>
    )
}

export default App
