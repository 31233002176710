import {bodyRegionsFocus} from 'constant/workout'
import SelectInput from 'components/general/Control/SelectInput'

const BodyRegionsFocus = ({region, setRegion, showError}) => {
    return (
        <SelectInput
            required
            showError={showError}
            selected={region}
            onChange={setRegion}
            options={bodyRegionsFocus}
            name={'body_region_focus'}
            label={'body region focus'}
        />
    )
}

export default BodyRegionsFocus
