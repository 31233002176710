import colors from './colors'
import images from './images'
import texts from './texts'
import styles from './styles'
import animations from './animations'

export const Colors = colors
export const Images = images
export const Texts = texts
export const Styles = styles
export const Animations = animations
