import React from 'react'
import {Col} from 'reactstrap'

const ColumnSpacer = ({showSearchSpacer}) => {
    if (!showSearchSpacer) {
        return null
    }
    return <Col className="mt-sm-0 mt-md-5" />
}

export default ColumnSpacer
