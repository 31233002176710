import {React, styled} from 'common'

const TableHeaderTitleSortAndFilter = ({headerGroups}) => {
    // console.log(headerGroups, 'headerGroups')
    return headerGroups.map((headerGroup) => (
        <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, idx) => {
                const rotationAngle = column?.isSortedDesc ? -90 : 90
                const rotate = `${column.isSorted ? rotationAngle : false}`
                return (
                    <Th key={idx} maxWidth={column.maxWidth}>
                        <TitleWithSort
                            {...column.getHeaderProps(
                                column.isSortableIcon &&
                                    column.getSortByToggleProps()
                            )}
                            alignCenter={!column.isSortableIcon}>
                            {column.render('Header')}
                            <StyledI
                                rotate={rotate}
                                className={
                                    column.isSortableIcon
                                        ? 'tim-icons icon-minimal-right'
                                        : false
                                }
                            />
                        </TitleWithSort>
                        <FilterWrapper>
                            {column.canFilter ? column.render('Filter') : null}
                        </FilterWrapper>
                    </Th>
                )
            })}
        </Tr>
    ))
}

export default TableHeaderTitleSortAndFilter

const FilterWrapper = styled.div`
    height: 40px;
    display: flex;
    justify-content: center;
    margin: 2px 0;
`
const TitleWithSort = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${({alignCenter}) =>
        alignCenter ? 'center' : 'space-between'};
`
const StyledI = styled.div`
    transform: rotate(${(props) => props.rotate}deg);
    transition: transform 0.3s;
`
const Th = styled.th`
    max-width: ${({maxWidth}) => maxWidth}px;
    padding: 0 20px !important;
`
const Tr = styled.tr``
