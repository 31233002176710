/* eslint-disable no-unused-vars */
import {React} from 'common'
import {DragDropContext} from 'react-beautiful-dnd'
import {useEffect} from 'services/hooks'
import * as uuid from 'uuid'

import {Col} from 'components/general/Control'
import DroppableColumn from 'components/general/Control/DragAndDrop/DroppableColumn'
import {useMemo} from 'react'
import {TARGET_EXERCISE_TIME} from 'constant/workout'

const isSameItemDraggedBack = (source, destination) =>
    destination.droppableId === source.droppableId &&
    destination.droppableId === 'column-1'

const getDataOrDefault = (data, defaultData = '') => data || defaultData

const initItem = (item = {}) => {
    return {
        ...item,
        uuid: uuid.v4(),
        target: getDataOrDefault(item.target || item.work_time),
        rest_time: getDataOrDefault(item.rest_time),
        target_type: getDataOrDefault(item.target_type, TARGET_EXERCISE_TIME),
        sets: getDataOrDefault(item.sets),
        activity_type: getDataOrDefault(item.activity_type, 'exercise'),
        highlight: false,
    }
}

const DragAndDrop = ({
    showSearch,
    selectedItems,
    setSelectedItems,
    collection,
    setCollection,
    dragTitle,
    dropTitle,
    colXs,
    colLg,
    showFilter,
    isClass,
    showColumnSelection,
    fixCollection,
    filterByGender,
    setFilterByGender,
    searchText,
    setSearchText,
    onRemoveItem,
    checkboxList,
    setCheckboxList,
    selectedFilterTypes,
    setSelectedFilterTypes,
    showSelectedExerciseError,
    defaultWork,
    setDefaultWork,
    defaultRest,
    setDefaultRest,
    defaultSets,
    setDefaultSets,
    updatedWorkout,
    onUpdateWorkoutTarget,
    enableDuplication,
    isProgram,
    workoutsGaps,
    setWorkoutsGaps,
    programWorkoutFlowType,
}) => {
    const selectedItemsLength = useMemo(
        () => selectedItems.length,
        [selectedItems]
    )
    useEffect(() => {
        if (selectedItemsLength !== selectedItems.length) {
            let arr = []
            selectedItems.forEach((item) => {
                arr.push({
                    ...item,
                    id: item?.id,
                    name: item.name,
                    uuid: getDataOrDefault(item.uuid, uuid.v4()),
                    activity_type: item?.activity_type,
                    items: item?.items,
                    rest_time: getDataOrDefault(item.rest_time),
                    target_type: getDataOrDefault(
                        item.target_type,
                        TARGET_EXERCISE_TIME
                    ),
                    sets: getDataOrDefault(item.sets),
                    target: getDataOrDefault(item.target || item.work_time),
                    rounds: getDataOrDefault(item.rounds),
                })
            })
            setSelectedItems(arr)
        }
    }, [selectedItemsLength, selectedItems, setSelectedItems])

    useEffect(() => {
        if (collection.length > 0 && fixCollection) {
            let arr = []
            collection.forEach((item) => {
                arr.push({id: item._id, name: item.name, uuid: uuid.v4()})
            })
            setCollection(arr)
        }
    }, [collection?.length, fixCollection, collection, setCollection])

    const reorder = (list, startIndex, endIndex) => {
        const [removed] = list.splice(startIndex, 1)
        list.splice(endIndex, 0, removed)
        showColumnSelection && list.forEach((item) => (item.highlight = false))

        return list
    }
    const onDragEnd = (result) => {
        const {source, destination} = result
        if (!destination) {
            return
        }
        if (isSameItemDraggedBack(source, destination)) {
            return
        }

        let newSelected
        if (source.droppableId === destination.droppableId) {
            newSelected = reorder(
                selectedItems,
                source.index,
                destination.index
            )
        } else if (source.droppableId === 'column-2') {
            newSelected = remove(selectedItems, source.index)
        } else if (source.droppableId === 'column-1') {
            newSelected = copy(collection, selectedItems, source, destination)
        }

        setSelectedItems(newSelected)
    }
    const copy = (
        source,
        destination,
        droppableSource,
        droppableDestination
    ) => {
        const item = source[droppableSource.index]
        const itemText = item.name
        let itemExists = false

        destination?.forEach((itm) => {
            if (showColumnSelection) {
                initItem(itm)
            }
            if (itm.name === itemText && !enableDuplication) {
                itemExists = true
            }
        })

        if (showColumnSelection || !itemExists) {
            const itemToAdd = showColumnSelection
                ? initItem(item)
                : {...item, uuid: uuid.v4()}
            destination.splice(droppableDestination.index, 0, itemToAdd)
        }

        return [...destination]
    }

    const remove = (state, startIndex) => {
        state.splice(startIndex, 1)
        return state
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Col xs={colXs || 12} md={12} lg={colLg || 6} className="my-3">
                <DroppableColumn
                    showSearch={showSearch}
                    showFilter={showFilter}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    filterByGender={filterByGender}
                    setFilterByGender={setFilterByGender}
                    selectedFilterTypes={selectedFilterTypes}
                    setSelectedFilterTypes={setSelectedFilterTypes}
                    title={dragTitle}
                    column={'column-1'}
                    items={collection}
                />
            </Col>
            <Col xs={colXs || 12} md={12} lg={colLg || 6} className="my-3">
                <DroppableColumn
                    isClass={isClass}
                    showSearchSpacer={showSearch}
                    defaultWork={defaultWork}
                    setDefaultWork={setDefaultWork}
                    defaultRest={defaultRest}
                    setDefaultRest={setDefaultRest}
                    defaultSets={defaultSets}
                    setDefaultSets={setDefaultSets}
                    showSelectedExerciseError={showSelectedExerciseError}
                    showColumnSelection={showColumnSelection}
                    onRemoveItem={onRemoveItem}
                    checkboxList={checkboxList}
                    setCheckboxList={setCheckboxList}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    items={selectedItems}
                    title={dropTitle}
                    column={'column-2'}
                    updatedWorkout={updatedWorkout}
                    onUpdateWorkoutTarget={onUpdateWorkoutTarget}
                    isProgram={isProgram}
                    workoutsGaps={workoutsGaps}
                    setWorkoutsGaps={setWorkoutsGaps}
                    programWorkoutFlowType={programWorkoutFlowType}
                />
            </Col>
        </DragDropContext>
    )
}

export default DragAndDrop

// eslint-disable-next-line react-hooks/exhaustive-deps
