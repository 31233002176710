import nameInput from './NameInput'
import imageUpload from './ImageUpload'
import instructions from './Instructions'
import controlButtons from './ControlButtons'
import activationInput from './ActivationInput'
import vimeoInformation from './VimeoInformation'
import nameAndDisplayName from './NameAndDisplayName'
import difficultySelection from './DifficultySelection'

export const NameInput = nameInput
export const ImageUpload = imageUpload
export const Instructions = instructions
export const ControlButtons = controlButtons
export const ActivationInput = activationInput
export const VimeoInformation = vimeoInformation
export const NameAndDisplayName = nameAndDisplayName
export const DifficultySelection = difficultySelection
