import {React} from 'common'

import {checkValidFields} from 'services/utils'

import {
    useGetResellerPageQuery,
    setMessage,
    useSaveResellerPageMutation,
    useUpdateResellerPageMutation,
    useDeleteResellerMutation,
} from 'store/store'

import {SweetAlert} from 'components/general/Display'
import {
    useEffect,
    useHistory,
    useState,
    useParams,
    createRef,
    useDispatch,
    useCallback,
} from 'services/hooks'
import {DetailsInput, Row} from 'components/general/Control'
import {Page, PageContent, DetailsWrapper} from 'components/general/Page'

const Reseller = () => {
    const history = useHistory()
    const params = useParams()
    const alertRef = createRef()
    const dispatch = useDispatch()

    const resellerId = params.id
    const validate = params?.id === 'new'

    const resellerFromServer = useGetResellerPageQuery(resellerId, {
        skip: validate,
        refetchOnMountOrArgChange: true,
    })

    const [
        saveResellerPage,
        {isSuccess: isSuccessSaving, isError: isErrorSaving},
    ] = useSaveResellerPageMutation()

    const [
        updateResellerPage,
        {isSuccess: isSuccessUpdating, isError: isErrorUpdating},
    ] = useUpdateResellerPageMutation()

    const [
        deleteCatalogLabel,
        {isSuccess: isSuccessDeleting, isError: isErrorDeleting},
    ] = useDeleteResellerMutation()

    const reseller = resellerFromServer?.data

    const [showError, setShowError] = useState(false)
    const [resellerName, setResellerName] = useState('')

    useEffect(() => {
        if (reseller) {
            const {name} = reseller

            name && setResellerName(name)
        }
    }, [reseller])

    const setValidationMessage = useCallback(
        (message, type) => {
            dispatch(
                setMessage({
                    message,
                    type: type === 'fail' ? 'alert-danger' : 'alert-success',
                })
            )
        },
        [dispatch]
    )

    const onSaveReseller = () => {
        const areFieldsValid = checkValidFields({resellerName})
        if (!areFieldsValid) {
            setValidationMessage('Fields are not valid', 'fail')
            return setShowError(true)
        }
        if (resellerId === 'new') {
            saveResellerPage({
                name: resellerName,
            })
        } else {
            updateResellerPage({resellerId, name: resellerName})
        }
    }
    const onRemoveLabel = () => {
        alertRef.current()
    }
    const deactivateLabel = (resellerId) => {
        deleteCatalogLabel(resellerId)
    }

    useEffect(() => {
        let msg = `Error saving ${resellerName}`
        if (isErrorDeleting) {
            msg = `Error deleting ${resellerName}`
        }
        if (isErrorSaving || isErrorDeleting || isErrorUpdating) {
            setValidationMessage(msg, 'fail')
        }
    }, [
        isErrorSaving,
        isErrorDeleting,
        isErrorUpdating,
        setValidationMessage,
        resellerName,
    ])

    useEffect(() => {
        let msg = `${resellerName} has been saved!`
        if (isSuccessDeleting) {
            msg = `${resellerName} has been deleted!`
        }
        if (isSuccessSaving || isSuccessDeleting || isSuccessUpdating) {
            setValidationMessage(msg, 'success')
            history.push('/admin/resellers')
        }
    }, [
        isSuccessSaving,
        isSuccessDeleting,
        isSuccessUpdating,
        setValidationMessage,
        resellerName,
        history,
    ])

    const isLoading = resellerFromServer.isLoading

    return (
        <Page>
            <PageContent>
                <DetailsWrapper
                    isLoading={isLoading}
                    header="Edit Reseller"
                    backButtonText="Back to Resellers"
                    saveButtonText="Save Reseller"
                    saveButtonAction={onSaveReseller}
                    deleteButtonText="Delete Reseller"
                    deleteButtonAction={onRemoveLabel}
                    disableDelete={validate}>
                    <Row>
                        <DetailsInput
                            type="text"
                            name="reseller name"
                            placeholder="name"
                            value={resellerName}
                            setter={setResellerName}
                            showError={showError}
                        />
                    </Row>
                    <SweetAlert
                        title={'Are you sure?'}
                        confirmBtnText="Delete reseller"
                        mainText="Reseller"
                        action="deleted"
                        ref={alertRef}
                        deactivate={deactivateLabel}
                        id={resellerId}
                    />
                </DetailsWrapper>
            </PageContent>
        </Page>
    )
}

export default Reseller
