import {createSlice} from '@reduxjs/toolkit'
import api from 'services/api'

import {sendOTPToMail, verifyOTP} from 'store/thunk/auth'

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        authenticated: false,
        verifyingSession: true,
        isLoading: false,
        otpSent: false,
    },
    reducers: {
        setAuthenticated: (state, action) => {
            state.authenticated = true
            state.verifyingSession = false
            state.isLoading = false
        },
        setLogout: (state, action) => {
            localStorage.removeItem('token')
            state.authenticated = false
            state.verifyingSession = true
            state.otpSent = false
        },
    },
    extraReducers: {
        [sendOTPToMail.pending]: (state) => {
            state.isLoading = true
        },
        [sendOTPToMail.fulfilled]: (state, {payload}) => {
            state.isLoading = false
            state.message = {
                message: 'OTP has been sent to mail',
                type: 'alert-success',
            }
            state.otpSent = Boolean(payload?.success)
            state.verifyingSession = Boolean(payload?.success)
        },
        [sendOTPToMail.rejected]: (state, payload) => {
            state.message = {
                message: payload.error.message,
                type: 'alert-danger',
            }
            state.isLoading = false
            state.verifyingSession = false
        },
        [verifyOTP.pending]: (state) => {
            state.isLoading = true
        },
        [verifyOTP.fulfilled]: (state, {payload}) => {
            state.isLoading = false
            if (payload && payload.success) {
                console.log('set access token', payload.data.accessToken)
                api.setAccessToken(payload.data.accessToken)
                state.authenticated = true
                state.verifyingSession = false
            }
        },
        [verifyOTP.rejected]: (state, payload) => {
            state.message = {
                message: 'The verification of the OTP has failed',
                type: 'alert-danger',
            }
            state.isLoading = false
            state.verifyingSession = false
        },
    },
})

export const {setAuthenticated, setLogout} = authSlice.actions

export const useAuthState = (state) => state?.auth

export const sendMail = sendOTPToMail

export default authSlice.reducer
