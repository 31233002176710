import {React} from 'common'
import {Row, DetailsInput} from 'components/general/Control'
import SelectInput from 'components/general/Control/SelectInput'
import {TableLink} from 'components/general/Display/Table'
import {userRoleOptions} from 'constant/user'

const UserDetails = ({
    name,
    email,
    dob,
    gender,
    height,
    weight,
    id,
    active,
    role,
    setRole,
    onCopyUserId,
}) => {
    const onCopy = () => {
        navigator.clipboard.writeText(id)
        onCopyUserId && onCopyUserId()
    }

    const firstRowInputs = [
        {type: 'text', name: 'name', placeholder: 'Name', value: name},
        {type: 'email', name: 'email', placeholder: 'Email', value: email},
        {type: 'text', name: 'dob', placeholder: 'Date of birth', value: dob},
    ]
    const secondRowInputs = [
        {
            type: 'text',
            name: 'gender',
            placeholder: 'Gender',
            value: gender || '',
        },
        {type: 'number', name: 'height', placeholder: 'Height', value: height},
        {type: 'number', name: 'weight', placeholder: 'Weight', value: weight},
    ]
    const thirdRowInputs = [
        {
            type: 'text',
            name: 'id',
            placeholder: 'User ID',
            value: id,
            iconLabel: (
                <TableLink
                    color="green"
                    icon={'tim-icons icon-single-copy-04'}
                    onClick={onCopy}
                    text="Copy"
                />
            ),
        },
        {
            type: 'text',
            name: 'active',
            placeholder: 'Active',
            value: active ? 'Active' : 'Inactive',
        },
        {
            type: 'select',
            name: 'role',
            placeholder: 'Role',
            value: role,
            setter: setRole,
            options: userRoleOptions,
        },
    ]

    return (
        <React.Fragment>
            <Row>
                {firstRowInputs.map(
                    ({type, name, placeholder, value, options}) => (
                        <DetailsInput
                            key={name}
                            type={type}
                            name={name}
                            placeholder={placeholder}
                            value={value}
                            disabled
                            options={options}
                        />
                    )
                )}
            </Row>
            <Row>
                {secondRowInputs.map(({type, name, placeholder, value}) => (
                    <DetailsInput
                        key={name}
                        type={type}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        disabled
                    />
                ))}
            </Row>
            <Row>
                {thirdRowInputs.map(
                    ({
                        type,
                        name,
                        placeholder,
                        value,
                        setter,
                        options,
                        iconLabel,
                    }) =>
                        type === 'select' ? (
                            <SelectInput
                                key={name}
                                label={placeholder}
                                selected={value}
                                onChange={(val) => setRole(+val)}
                                options={options}
                                name={name}
                                className={'mx-3 flex-grow-1'}
                            />
                        ) : (
                            <DetailsInput
                                key={name}
                                type={type}
                                name={name}
                                placeholder={placeholder}
                                value={value}
                                setter={setter}
                                iconLabel={iconLabel}
                                onClick={onCopy}
                            />
                        )
                )}
            </Row>
        </React.Fragment>
    )
}

export default UserDetails
