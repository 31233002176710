import {React} from 'common'
import {LANGUAGES} from 'constant/languages'
import {skipToken} from '@reduxjs/toolkit/dist/query'
import {useEffect, useRef, useState} from 'services/hooks'
import {useParams} from 'react-router-dom'
import {Page, PageContent, DetailsWrapper} from 'components/general/Page'
import {
    useGetExerciseQuery,
    useSaveExerciseMutation,
    useSaveExerciseTranslationMutation,
    useDeleteExerciseMutation,
} from 'store/store'

import ExerciseDetails from 'components/Exercise/ExerciseDetails'
import {SweetAlert} from 'components/general/Display'
import {userPositionTypes} from 'constant/exercise'
import {useMemo} from 'react'
import ActivitySwitchLanguage from 'components/general/Control/ActivitySwitchLanguage'

const alertTextsMap = {
    showError: {
        title: 'Missing fields',
        mainText: 'Please fill in all the missing fields before saving',
    },
    showNameError: {
        title: 'Exercise name problem',
        mainText: 'This name is already exist \n please select another one',
    },
}

const Exercise = () => {
    const [
        saveExercise,
        {
            isSuccess: isSuccessSaving,
            isError: isErrorSaving,
            isLoading: isSaving,
        },
    ] = useSaveExerciseMutation()

    const [
        saveTranslation,
        {
            isError: isSavingTranslationError,
            isSaving: isSavingTranslation,
            isSuccess: isSavingTranslationSuccess,
        },
    ] = useSaveExerciseTranslationMutation()

    const params = useParams()

    const alertRef = useRef()
    const isNewExercise = params?.id === 'new'
    const [errorType, setErrorType] = useState(null)
    const [language, setLanguage] = useState(LANGUAGES[0].value)
    const [showError, setShowError] = useState(false)
    const [showNameError, setShowNameError] = useState(false)
    const [showDraftError, setShowDraftError] = useState(false)

    const skipRequest = isNewExercise
    const {
        data: exercise,
        isFetching: isFetchingExercise,
        // isError: isErrorFetchingExercise,
    } = useGetExerciseQuery(
        skipRequest ? skipToken : {id: params?.id, language: language},
        {refetchOnMountOrArgChange: true}
    )

    const [
        deleteExercise,
        {isSuccess: isSuccessDeleting, isError: isErrorDeleting},
    ] = useDeleteExerciseMutation()

    const [updatedExercise, setUpdatedExercise] = useState({})

    const [imageFile, setImageFile] = useState({
        file: null,
        previewUrl: null,
    })

    const {name, display_name} = updatedExercise

    useEffect(() => {
        if (exercise) {
            setUpdatedExercise({
                ...exercise,
                user_position:
                    exercise.user_position || userPositionTypes[0].value,
            })
            setImageFile({file: null, previewUrl: exercise.image})
        }
    }, [exercise])

    const onError = (error) => {
        setErrorType(error)
        if (error) {
            alertRef.current()
        }
    }

    const alertInfo = useMemo(() => alertTextsMap[errorType], [errorType])

    const showSpinner = useMemo(
        () => (!isNewExercise && !exercise) || isSaving || isFetchingExercise,
        [exercise, isFetchingExercise, isSaving, isNewExercise]
    )

    return (
        <Page>
            <PageContent>
                <DetailsWrapper
                    header="Exercise Details"
                    backButtonText="Back to Excercises">
                    {language !== 'en' ? (
                        <ActivitySwitchLanguage
                            title={'EDIT EXERCISE'}
                            language={language}
                            activityId={exercise?.id}
                            setLanguage={setLanguage}
                            activityName={name}
                            activityDisplayName={display_name}
                            hideInstructions
                            saveTranslation={saveTranslation}
                            isSavingError={isSavingTranslationError}
                            isSavingSuccess={isSavingTranslationSuccess}
                            loading={isSavingTranslation || isFetchingExercise}
                        />
                    ) : (
                        <ExerciseDetails
                            saveOptions={{
                                isSuccessSaving,
                                isErrorSaving,
                                isSaving,
                            }}
                            onError={onError}
                            isNewExercise={isNewExercise}
                            showNameError={showNameError}
                            setShowNameError={setShowNameError}
                            showDraftError={showDraftError}
                            setShowDraftError={setShowDraftError}
                            showSpinner={showSpinner}
                            exercise={exercise}
                            saveExercise={saveExercise}
                            language={language}
                            setLanguage={setLanguage}
                            showError={showError}
                            setShowError={setShowError}
                            imageFile={imageFile}
                            setImageFile={setImageFile}
                            updatedExercise={updatedExercise}
                            setUpdatedExercise={setUpdatedExercise}
                            deleteExercise={deleteExercise}
                            isSuccessDeleting={isSuccessDeleting}
                            isErrorDeleting={isErrorDeleting}
                        />
                    )}

                    <SweetAlert
                        ref={alertRef}
                        title={alertInfo?.title}
                        mainText={alertInfo?.mainText}
                        action=""
                        deactivate={() => {}}
                        confirmBtnText="Got it"
                        id={exercise?.id}
                        hideAlertOnConfirm={() => {
                            setErrorType(null)
                        }}
                        hideCancel
                    />
                </DetailsWrapper>
            </PageContent>
        </Page>
    )
}
export default Exercise
