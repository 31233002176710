import AppInput from '../Input'
import {Col, Input, Row} from 'reactstrap'
import {FLOW_TYPE_FLEXIBLE, FLOW_TYPE_SCHEDULED} from 'constant/program'
import {DAYS} from 'constant/days'
import {useSelector} from 'react-redux'
import {useIsDarkTheme} from 'store/store'
import {useCallback, useEffect, useMemo} from 'react'
import {Colors} from 'appearance'

const DraggableItemFormProgram = ({
    name,
    values,
    setter,
    index,
    programWorkoutFlowType: flowType,
}) => {
    const isDarkTheme = useSelector(useIsDarkTheme)
    const dropDownBackground = useMemo(
        () => (isDarkTheme ? Colors.OPAC_DARK : Colors.WHITE),
        [isDarkTheme]
    )

    const onChangeWorkoutGaps = useCallback(
        (newValue) => {
            setter((wg) => {
                const newWg = wg?.map((g) => Number(g)) // Create a copy of the array
                newWg[index] = Number(newValue) // Update the specific index
                return newWg // Return the new array
            })
        },
        [index, setter]
    )

    useEffect(() => {
        if (values[index] === undefined) {
            onChangeWorkoutGaps(1)
        }
    }, [index, onChangeWorkoutGaps, values])

    return (
        <div style={{flex: 1, alignContent: 'center', paddingLeft: 20}}>
            <Row>
                <Col xs={6} style={{alignContent: 'center'}}>
                    <h6 style={{margin: 0, padding: 0}}>
                        {flowType === FLOW_TYPE_SCHEDULED
                            ? 'Workout Day'
                            : 'Workout Gap'}
                    </h6>
                </Col>
                <Col xs={6}>
                    {flowType === FLOW_TYPE_FLEXIBLE ? (
                        <AppInput
                            marginbottom={4}
                            pattern="\d*"
                            inputMode="numeric"
                            value={values[index]}
                            placeholder="0-7 Default 0"
                            setter={onChangeWorkoutGaps}
                            disableAutoComplete
                            name={name}
                        />
                    ) : (
                        <Input
                            className={`form-control mb-1`}
                            name={name}
                            onChange={(ev) => {
                                onChangeWorkoutGaps(ev.target.value)
                            }}
                            placeholder={'Workout Day'}
                            value={values[index]}
                            style={{backgroundColor: dropDownBackground}}
                            type="select">
                            <option value="" disabled>
                                Workout Day
                            </option>
                            {DAYS?.map((opt, idx) => (
                                <option
                                    key={`${opt.label}_${idx}`}
                                    value={opt.value}>
                                    {opt.label}
                                </option>
                            ))}
                        </Input>
                    )}
                </Col>
            </Row>
        </div>
    )
}

export default DraggableItemFormProgram
