import * as uuid from 'uuid'
import {useCallback, useEffect, useRef, useState} from 'react'
import {Row} from 'components/general/Control'
import WorkoutBuilderControlButtons from './WorkoutBuilder/WorkoutBuilderControlButtons'
import {SweetAlert} from 'components/general/Display'
import WorkoutTarget from './WorkoutTarget'

export const DEFAULT_VALUES = {
    target: 30,
    rest_time: 30,
    sets: 1,
    rounds: 1,
}

const WorkoutBuilderControls = ({
    isClass,
    checkboxList,
    setCheckboxList,
    selectedItems,
    setSelectedItems,
    updatedWorkout,
    onUpdateWorkoutTarget,
}) => {
    const [alert, setAlert] = useState('')
    const alertRef = useRef()

    useEffect(() => {
        alert && alertRef.current()
    }, [alert])

    const prepareNewItem = useCallback((item, type) => {
        const newItem = {
            activity_type: type,
            name: item.label,
            value: item.value,
            uuid: uuid.v4(),
        }
        if (type === 'transition') {
            newItem.rest_time = ''
        }
        return newItem
    }, [])

    const onAddSpecialItem = (item, type = null) => {
        const newitem = prepareNewItem(item, type)
        setSelectedItems([newitem, ...selectedItems])
    }

    const getSelectedExercisesByOrder = useCallback(
        (list) => {
            return selectedItems
                .map((item) =>
                    list?.find((listitem) => listitem.uuid === item.uuid)
                )
                ?.filter((e) => e)
        },
        [selectedItems]
    )

    const findIndexInListByUuid = useCallback(
        (itemUuid) => selectedItems.findIndex((item) => item.uuid === itemUuid),
        [selectedItems]
    )

    const getNewgGroupItem = useCallback((orderedItems) => {
        return {
            uuid: uuid.v4(),
            activity_type: 'group',
            items: orderedItems,
            highlight: true,
            rounds: '',
        }
    }, [])

    const updateItemBeforeSpread = useCallback((item) => {
        if (item.highlight) {
            item.highlight = false
        }
        item.uuid = uuid.v4()

        return item
    }, [])

    const removeChildrenFromList = useCallback(
        (list) => {
            const idsToRemove = checkboxList.map((item) => item.uuid)
            return list.filter((item) => !idsToRemove.includes(item.uuid))
        },
        [checkboxList]
    )
    const groupItems = () => {
        if (!checkboxList.length) {
            return setAlert(`No items selected`)
        }
        if (checkboxList.length === 1) {
            return setAlert(`Group must contain more than one exercise`)
        }
        const isContainGroup = checkboxList?.find(
            (item) => item.activity_type === 'group'
        )
        if (isContainGroup) {
            return setAlert(`Group can not contain a group item`)
        }
        const orderedItems = getSelectedExercisesByOrder(checkboxList)
        orderedItems?.forEach((t) => (t.uuid = uuid.v4()))
        if (orderedItems?.length > 0) {
            orderedItems[0].rounds = ''
        }

        const newGroup = getNewgGroupItem(orderedItems)

        const indexToPush = findIndexInListByUuid(orderedItems[0].uuid)
        updateSelectedItems(indexToPush, 0, [newGroup], true)
    }

    const unGroup = () => {
        const indexToReplace = findIndexInListByUuid(checkboxList[0].uuid)
        updateSelectedItems(indexToReplace, 1, checkboxList[0].items)
    }

    const setNewItemsClearCheckbox = useCallback(
        (newItems) => {
            setSelectedItems(newItems)
            setCheckboxList([])
        },
        [setCheckboxList, setSelectedItems]
    )

    const updateSelectedItems = useCallback(
        (index, itemsToRemove, itemsToReplace, isNewgroup = false) => {
            let newSelected = [...selectedItems]
            if (isNewgroup) {
                newSelected = removeChildrenFromList(newSelected)
            } else {
                newSelected.forEach((item) => updateItemBeforeSpread(item))
            }

            newSelected.splice(index, itemsToRemove, ...itemsToReplace)
            setNewItemsClearCheckbox(newSelected)
        },
        [
            updateItemBeforeSpread,
            removeChildrenFromList,
            selectedItems,
            setNewItemsClearCheckbox,
        ]
    )

    return (
        <Row className="d-flex mt-2 mb-1 mx-0 justify-content-between">
            <WorkoutBuilderControlButtons
                checkboxList={checkboxList}
                isClass={isClass}
                unGroup={unGroup}
                groupItems={groupItems}
                onAddSpecialItem={onAddSpecialItem}
            />

            <WorkoutTarget
                updatedWorkout={updatedWorkout}
                onUpdateWorkoutTarget={onUpdateWorkoutTarget}
            />

            <SweetAlert
                ref={alertRef}
                title={'Invalid action'}
                mainText={alert}
                action=""
                deactivate={() => {}}
                hideAlertOnConfirm={() => {
                    setAlert('')
                }}
                confirmBtnText="Got it"
                hideCancel
            />
        </Row>
    )
}

export default WorkoutBuilderControls
