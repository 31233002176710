import {FormGroup, Input, Label} from 'reactstrap'
import styled from 'styled-components'

const Instructions = ({instructions, setInstractions}) => {
    return (
        <FormGroup className="col-sm-12 col-md-6 col-lg-6 px-0 h-auto pr-md-3 mb-0">
            <Label>DESCRIPTION</Label>
            <StyledInput
                id="instructions"
                className="px-2"
                type="textarea"
                placeholder="Add Description"
                value={instructions}
                onChange={(ev) => setInstractions(ev.target.value)}
            />
        </FormGroup>
    )
}

export default Instructions

const StyledInput = styled(Input)`
    resize: auto;
    max-height: 200;
    min-height: 50;
    margin-bottom: 0px !important;
`
