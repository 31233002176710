import {React} from 'common'
import {Col, Card, CardBody, Row, CardTitle} from 'components/general/Control'

const DashboardCard = ({title, stats, icon, type, show}) => {
    const value = stats?.data && stats?.data[type]

    if (!show) {
        return null
    }
    return (
        <Col id={'dashboard-card-' + type} xl="4" l="6" md="6">
            <Card className="card-stats">
                <CardBody>
                    <Row>
                        <Col xs="5">
                            <div className="info-icon text-center icon-warning">
                                <i className={`tim-icons ${icon}`} />
                            </div>
                        </Col>
                        <Col xs="7">
                            <div className="numbers">
                                <p className="card-category">{title}</p>
                                <CardTitle tag="h3">{value}</CardTitle>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    )
}

export default DashboardCard
