import {React} from 'common'

import {
    useEffect,
    useHistory,
    useState,
    useLocation,
    useParams,
    useDispatch,
} from 'services/hooks'
import {
    useGetCatalogPageQuery,
    setMessage,
    useSaveCatalogPageMutation,
} from 'store/store'

import {DetailsInput, Row} from 'components/general/Control'
import {Page, PageContent, DetailsWrapper} from 'components/general/Page'
import DragAndDrop from 'components/general/Control/DragAndDrop'
import {checkValidFields, formatDataToDragAndDrop} from 'services/utils'
import {useCallback} from 'react'

const CatalogPage = () => {
    const history = useHistory()
    const location = useLocation()
    const params = useParams()
    const dispatch = useDispatch()

    const pageFromProps = location.page
    const pageId = params.id
    const validate = params?.id === 'new'

    const pageFromServer = useGetCatalogPageQuery(pageId, {
        skip: validate || (!validate && pageFromProps),
        refetchOnMountOrArgChange: true,
    })

    const [saveCatalogPage, {isLoading: isSavingCatalog, isSuccess, isError}] =
        useSaveCatalogPageMutation()

    const page = pageFromProps || pageFromServer?.data

    const [showError, setShowError] = useState(false)
    const [pageName, setPageName] = useState('')
    const [selectedLabels, setSelectedLabels] = useState([])
    const [currentLabels, setCurrentLabels] = useState([])

    useEffect(() => {
        if (page) {
            const {name} = page.page

            name && setPageName(name)
            setCurrentLabels(formatDataToDragAndDrop(page?.available_labels))
            setSelectedLabels(formatDataToDragAndDrop(page?.page.labels))
        }
    }, [page, page?.available_labels, page?.page?.labels])

    const setValidationMessage = useCallback(
        (message, type) => {
            dispatch(
                setMessage({
                    message,
                    type: type === 'fail' ? 'alert-danger' : 'alert-success',
                })
            )
        },
        [dispatch]
    )

    const onSavePage = () => {
        const areFieldsValid = checkValidFields({pageName})
        if (!areFieldsValid) {
            setValidationMessage('Fields are not valid', 'fail')
            return setShowError(true)
        }
        const labels = selectedLabels.map((sl) =>
            page.available_labels.find(
                (availableLabel) => sl.id === availableLabel._id
            )
        )

        const catalogPageToSave = {
            _id: pageId === 'new' ? null : pageId,
            name: pageName,
            labels,
        }
        saveCatalogPage(catalogPageToSave)
    }

    useEffect(() => {
        if (isSuccess) {
            setValidationMessage(pageName + ' has been saved!', 'success')
            return history.push('/admin/catalog-pages')
        }
        if (isError) {
            setValidationMessage(
                'Something went wrong saving ' + pageName + ', try again',
                'fail'
            )
        }
    }, [
        isSavingCatalog,
        history,
        isError,
        isSuccess,
        pageName,
        setValidationMessage,
    ])

    return (
        <Page>
            <PageContent>
                <DetailsWrapper
                    isLoading={pageFromServer.isLoading}
                    header="Edit Page"
                    backButtonText="Back to Pages Catalog"
                    saveButtonText="Save Page Catalog"
                    saveButtonAction={onSavePage}
                    deleteButtonText=""
                    deleteButtonAction="">
                    <Row>
                        <DetailsInput
                            type="text"
                            name="Page name"
                            placeholder="name"
                            value={pageName}
                            setter={setPageName}
                            showError={showError}
                        />
                    </Row>
                    <Row className="d-flex justify-content-center mb-4">
                        <DragAndDrop
                            selectedItems={selectedLabels}
                            setSelectedItems={setSelectedLabels}
                            collection={currentLabels}
                            setCollection={setCurrentLabels}
                            dragTitle={'Available Menu Topics'}
                            dropTitle={`${pageName || 'New'} Layout`}
                        />
                    </Row>
                </DetailsWrapper>
            </PageContent>
        </Page>
    )
}

export default CatalogPage
