import {React, styled} from 'common'
import {useDispatch, useState, useUser} from 'services/hooks'
// import {register} from 'store/actions'
import Message from 'components/general/Page/Message'

const Register = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState(null)

    const dispatch = useDispatch()
    console.debug(dispatch)

    const user = useUser()
    const error = user?.message

    const submitHandler = (e) => {
        e.preventDefault()
        if (password !== confirmPassword) {
            setMessage('Passwords do not match')
        } else {
            // dispatch(register(email, password, name))
        }
    }

    return (
        <Container>
            <h1>Sign Up</h1>
            {message && <Message variant="danger">{message}</Message>}
            {error && <Message variant="danger">{error}</Message>}
            <form onSubmit={submitHandler}>
                <Row>
                    <Col>Name::</Col>
                    <Col>
                        <input
                            type="text"
                            name="name"
                            placeholder="Enter Name"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>Email Address:</Col>
                    <Col>
                        <input
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>Password:</Col>
                    <Col>
                        <input
                            type="password"
                            name="password"
                            placeholder="Enter password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Col>
                </Row>{' '}
                <Row>
                    <Col>Confirm Password:</Col>
                    <Col>
                        <input
                            type="password"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <button type="submit" value="Register">
                        Register{' '}
                    </button>
                </Row>
            </form>

            <Row className="py-3">
                <Col>
                    Have an Account? <a href={'/login'}>Login</a>
                </Col>
            </Row>
        </Container>
    )
}

export default Register

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
`
