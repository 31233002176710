import {React, styled} from 'common'
import classNames from 'classnames'
import {useState, useDispatch, useSelector} from 'services/hooks'

import {SidebarHandle, ThemeAndLogout} from 'components/views/Header'
import {
    setLogout,
    useIsDarkTheme,
    toggleDarkTheme as setIsDarkTheme,
} from 'store/store'

import {Navbar} from 'components/general/Control'
import api from 'services/api'

const PageHeader = ({toggleSidebar, sidebarOpened, activeRoute, ...p}) => {
    const dispatch = useDispatch()
    const [clearTestDataText, setClearTestDataText] = useState('')
    const [headerState, setHeaderState] = useState({
        collapseOpen: false,
        modalSearch: false,
        color: 'navbar-transparent',
    })

    // const updateColor = useCallback(() => {
    //     if (window.innerWidth < 993 && headerState.collapseOpen) {
    //         setHeaderState({
    //             ...headerState,
    //             color: 'bg-primary',
    //         })
    //     } else {
    //         setHeaderState({
    //             ...headerState,
    //             color: 'navbar-transparent',
    //         })
    //     }
    // }, [headerState])

    // useEffect(() => {
    //     window.addEventListener('resize', updateColor)

    //     return () => {
    //         window.removeEventListener('resize', updateColor)
    //     }
    // }, [updateColor])

    const isDarkTheme = useSelector(useIsDarkTheme)

    const toggleCollapse = () => {
        if (headerState.collapseOpen) {
            setHeaderState({
                ...headerState,
                collapseOpen: false,
                color: 'navbar-transparent',
            })
        } else {
            setHeaderState({
                ...headerState,
                collapseOpen: true,
                color: 'bg-primary',
            })
        }
    }

    const handleActiveMode = () => {
        dispatch(setIsDarkTheme())
    }

    const logout = () => {
        dispatch(setLogout())
    }

    const clearTestData = async () => {
        await api.clearTestData()
        setClearTestDataText('done')
    }

    return (
        <Navbar
            // This part is essential for the theme change, do not remove
            className={classNames('navbar-absolute', {
                [headerState.color]:
                    window.location.pathname.indexOf('full-screen-map') === -1,
            })}
            expand="lg">
            <NavbarWrapper className="navbar-wrapper" isDarkTheme={isDarkTheme}>
                <SidebarHandle
                    sidebarOpened={sidebarOpened}
                    toggleSidebar={toggleSidebar}
                    activeRoute={activeRoute}
                />
                <ClearTestDataButton
                    id="clear-test-data"
                    onClick={clearTestData}>
                    {clearTestDataText}
                </ClearTestDataButton>
                <ThemeAndLogout
                    toggleCollapse={toggleCollapse}
                    collapseOpen={headerState.collapseOpen}
                    handleActiveMode={handleActiveMode}
                    darkMode={isDarkTheme}
                    logout={logout}
                />
            </NavbarWrapper>
        </Navbar>
    )
}

export default PageHeader

const NavbarWrapper = styled.div`
    position: fixed;
    top: 15px;
    display: flex;
    justify-content: space-between;
    width: 96%;
    background: ${(p) => (p.isDarkTheme ? 'rgb(39, 41, 61)' : 'white')};
`

const ClearTestDataButton = styled.div`
    height: 10px;
    width: 10px;
`
