import {React, styled} from 'common'
import {Link} from 'services/hooks'

const TableLinkV2 = ({color, to, text, onClick, icon}) => {
    return to ? (
        <StyledLink to={to}>
            {icon ? <i className={'text-danger ' + icon} /> : <p>{text}</p>}
        </StyledLink>
    ) : (
        <StyledNotLink className="icon-info" color={color} onClick={onClick}>
            <i className={'text-success ' + icon} />
        </StyledNotLink>
    )
}

export default TableLinkV2

const StyledLink = styled(Link)`
    font-weight: 400;
    cursor: pointer;
`
const StyledNotLink = styled.div`
    font-weight: 400;
    cursor: pointer;
    display: flex;
    justify-content: center;
`
