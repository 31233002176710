/* eslint-disable no-unused-vars */
import React from 'react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
//screens
import Login from 'screens/Login'
import Register from 'screens/Register'
import RouteManager from 'navigation/RouteManager'
import routes from './routes'

const ProtectedRoute = RouteManager.ProtectedRoute
const OutRoute = RouteManager.OutRoute
export const Redirect = RouteManager.Redirect
const outerRoutes = [
    {
        path: '/login',
        component: Login,
    },
    {
        path: '/register',
        component: Register,
    },
]

const getUnprotectedRoutes = (outerRoutes) =>
    outerRoutes.map(({path, component}, i) => (
        <OutRoute
            key={'out_routes' + i}
            exact
            path={path}
            component={component}></OutRoute>
    ))

const getProtectedRoutes = (routes) =>
    routes.map(({layout, path, component, specific}, i) => {
        const routePath = specific ? layout + path + specific : layout + path
        return (
            <ProtectedRoute
                exact={path === '/'}
                key={'routes' + i}
                path={routePath}
                component={component}></ProtectedRoute>
        )
    })

const NavigationRouter = () => {
    return (
        <div>
            <Router>
                <Switch>
                    {getUnprotectedRoutes(outerRoutes)}
                    {getProtectedRoutes(routes)}
                    <Route path="*" component={Login} />
                </Switch>
            </Router>
        </div>
    )
}

export default NavigationRouter
