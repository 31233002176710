import {useAuthState, setAuthenticated} from 'store/store'
import {Route} from 'react-router-dom'
import {Redirect, useDispatch, useSelector} from 'services/hooks'
import React from 'react'

const ProtectedRoute = ({component: Component, exact, ...restOfProps}) => {
    let {authenticated} = useSelector(useAuthState)
    const dispatch = useDispatch()
    if (!authenticated) {
        const token = localStorage.getItem('token')
        if (token) {
            dispatch(setAuthenticated())

            authenticated = true
        }
    }

    return (
        <Route
            exact={exact}
            {...restOfProps}
            render={(props) => {
                return authenticated ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }}
        />
    )
}

const OutRoute = ({component: Component, ...restOfProps}) => {
    return (
        <Route {...restOfProps} render={(props) => <Component {...props} />} />
    )
}

const RouteManager = {ProtectedRoute, OutRoute}

export default RouteManager
