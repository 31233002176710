import {React} from 'common'
import {Row} from 'components/general/Control'
import {FormGroup} from 'react-bootstrap'
import FormCheckLabel from 'react-bootstrap/esm/FormCheckLabel'
import {Input} from 'reactstrap'
import styled from 'styled-components'

const headers = ['Target', 'Rest', 'Sets', 'Rounds']

const BuilderFormHeader = ({checked, updateCheckboxSelected}) => {
    return (
        <Row className="d-flex  justify-content-between mr-2">
            <FormGroup className="custom-checkbox custom-control ">
                <FormCheckLabel
                    className="ml-3 mb-0 "
                    style={{cursor: 'pointer', color: 'inherit'}}>
                    <Input
                        className="custom-control-input form-check-input"
                        type="checkbox"
                        checked={checked}
                        onChange={(ev) =>
                            updateCheckboxSelected(ev.target.checked)
                        }
                    />
                    <span className="form-check-sign custom-control-label">
                        {`${checked ? 'CLEAR' : 'SELECT'} ALL`}
                    </span>
                </FormCheckLabel>
            </FormGroup>

            <HeadersContainer className="d-flex justify-content-end">
                {headers.map((hd) => (
                    <HeaderName key={'form_header_' + hd}>{hd}</HeaderName>
                ))}
            </HeadersContainer>
        </Row>
    )
}

export default BuilderFormHeader

const HeaderName = styled.p`
    margin-inline-end: 13px;
    color: initial;
    font-size: 13px;
`

const HeadersContainer = styled.div``
