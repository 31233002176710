import {Row} from 'components/general/Control'
import CaloriesMETcategory from 'components/Exercise/Details/CaloriesMETcategory'

const RopeContent = ({updatedExercise, showError, updateExerciseField}) => {
    const {met} = updatedExercise
    return (
        <Row className="justify-content-between px-3">
            <CaloriesMETcategory
                selected={met}
                onChange={(value) => updateExerciseField('met', value)}
                showError={showError}
            />
        </Row>
    )
}

export default RopeContent
