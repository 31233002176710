export const programMandatoryFields = {
    general: ['name', 'image', 'tall_image', 'small_image'],
}

export const PROGRAM_TYPE_B2C = 'b2c'
export const PROGRAM_TYPE_CORPORATE = 'corporate'

export const PROGRAM_TYPES_OPTIONS = [
    {label: 'B2C', value: PROGRAM_TYPE_B2C},
    {label: 'Corporate', value: PROGRAM_TYPE_CORPORATE},
]

export const FLOW_TYPE_FLEXIBLE = 'flexible'
export const FLOW_TYPE_SCHEDULED = 'scheduled'

export const PROGRAM_FLOW_TYPE_OPTIONS = [
    {label: FLOW_TYPE_FLEXIBLE.toUpperCase(), value: FLOW_TYPE_FLEXIBLE},
    {label: FLOW_TYPE_SCHEDULED.toUpperCase(), value: FLOW_TYPE_SCHEDULED},
]

export const AEROBIC_FIELDS = [
    {
        name: 'aerobic_time_daily_target',
        label: 'Aerobic activity duration (min)',
    },
    {
        name: 'aerobic_pulse_daily_target',
        label: 'Target HR (% from max HR)',
    },
    {
        name: 'aerobic_days_target',
        label: 'Number of days to complete aerobic goals',
    },
]

export const STEPS_FIELDS = [
    {name: 'step_daily_target', label: 'Number of required daily steps'},
    {
        name: 'steps_days_target',
        label: 'Number of days to complete steps goal',
    },
]

export const STRENGTH_FIELDS = [
    {
        name: 'strength_reps_daily_target',
        label: 'Number of repetitions to complete (Per Day)',
    },
    {
        name: 'strength_days_target',
        label: 'Number of days to complete strength goal',
    },
]
