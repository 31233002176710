import {createApi} from '@reduxjs/toolkit/query/react'

import {
    transformResponseFromData,
    provideTags,
    provideTag,
    axiosBaseQuery,
} from 'store/common'

const couponsApi = createApi({
    baseQuery: axiosBaseQuery(),

    reducerPath: 'coupons',
    tagTypes: ['Coupon', 'Reseller', 'Plans'],
    endpoints: (build) => ({
        getSubscriptionPlan: build.query({
            query: () => ({
                url: 'admin/coupons/group/plans',
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => provideTags('Plans', data?.pages),
        }),
        getResellerPages: build.query({
            query: () => ({
                url: 'admin/coupons/reseller',
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => provideTags('Reseller', data?.pages),
        }),
        getResellerPage: build.query({
            query: (resellerId) => ({
                url: `admin/coupons/reseller/${resellerId}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('Reseller', data, '_id')],
        }),
        saveResellerPage: build.mutation({
            query: (body) => ({
                url: `/admin/coupons/reseller`,
                method: 'POST',
                body,
            }),
            transformResponse: transformResponseFromData,
            invalidatesTags: (data) => [provideTag('Reseller', data, '_id')],
        }),
        updateResellerPage: build.mutation({
            query: (body) => ({
                url: `/admin/coupons/reseller/${body.resellerId}`,
                method: 'PUT',
                body,
            }),
            transformResponse: transformResponseFromData,
            invalidatesTags: (data) => [provideTag('Reseller', data, '_id')],
        }),
        deleteReseller: build.mutation({
            query: (resellerId) => ({
                url: `/admin/coupons/reseller/${resellerId}`,
                method: 'DELETE',
            }),
            transformResponse: transformResponseFromData,
            invalidatesTags: (data) => [provideTag('Reseller', data, '_id')],
        }),
        getCouponsPages: build.query({
            query: () => ({
                url: 'admin/coupons/group',
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => provideTags('Coupon', data?.pages),
        }),
        getCouponGroupPage: build.query({
            query: (coupnGroupId) => ({
                url: `admin/coupons/group/${coupnGroupId}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('Coupon', data, '_id')],
        }),
        saveCouponGroupPage: build.mutation({
            query: (body) => ({
                url: `/admin/coupons/group`,
                method: 'POST',
                body,
            }),
            transformResponse: transformResponseFromData,
            invalidatesTags: (data) => [provideTag('Coupon', data, '_id')],
        }),
        updateCouponGroupPage: build.mutation({
            query: (body) => ({
                url: `/admin/coupons/group/${body.couponGroupId}`,
                method: 'PUT',
                body,
            }),
            transformResponse: transformResponseFromData,
            invalidatesTags: (data) => [provideTag('Coupon', data, '_id')],
        }),
        deleteCouponGroup: build.mutation({
            query: (couponGroupId) => ({
                url: `/admin/coupons/group/${couponGroupId}`,
                method: 'DELETE',
            }),
            transformResponse: transformResponseFromData,
            invalidatesTags: (data) => [provideTag('Coupon', data, '_id')],
        }),
    }),
})
export default couponsApi

export const {
    useGetSubscriptionPlanQuery,
    useGetResellerPagesQuery,
    useGetResellerPageQuery,
    useSaveResellerPageMutation,
    useUpdateResellerPageMutation,
    useDeleteResellerMutation,
    useGetCouponsPagesQuery,
    useGetCouponGroupPageQuery,
    useSaveCouponGroupPageMutation,
    useUpdateCouponGroupPageMutation,
    useDeleteCouponGroupMutation,
} = couponsApi
