import React, {useEffect, useState, useRef, useMemo, useCallback} from 'react'
import {skipToken} from '@reduxjs/toolkit/dist/query'

import {Page, PageContent, DetailsWrapper} from 'components/general/Page'
import {useParams} from 'react-router'
import {LANGUAGES} from 'constant/languages'
import {
    useGetExercisesQuery,
    useGetWorkoutQuery,
    useGetWorkoutsQuery,
    useSaveWorkoutMutation,
    useSaveWorkoutTranslationMutation,
    useDeleteWorkoutMutation,
} from 'store/store'
import SweetAlert from 'components/general/Display/SweetAlert'
import WorkoutDetails from 'components/Workout/WorkoutDetails'
import {AWS_BASE_URL} from 'services/api'
import {bodyRegionsFocus, objectiveOptions} from 'constant/workout'
import {getInstructions} from 'services/utils'
import ActivitySwitchLanguage from 'components/general/Control/ActivitySwitchLanguage'

const alertTextsMap = {
    showError: {
        title: 'Missing fields',
        mainText: 'Please fill in all the missing fields before saving',
    },
    showClassError: {
        title: 'Invalid!',
        mainText:
            'Workout can not be of type "Class" when the selected exercises list contains group',
    },
    showNameError: {
        title: 'Workout name problem',
        mainText: 'This name is already exist \n please select another one',
    },
    showSelectedExerciseError: {
        title: 'Exercises list',
        mainText: 'exercises list must contain at least one exercise',
    },
    showSelectedTagsError: {
        title: 'Tags list',
        mainText: 'Tags list must contain at least one tag',
    },
}

const Workout = () => {
    const [saveWorkout, {isError, isLoading: isSaving, isSuccess, data}] =
        useSaveWorkoutMutation()
    const [
        saveTranslation,
        {
            isError: isSavingTranslationError,
            isSaving: isSavingTranslation,
            isSuccess: isSavingTranslationSuccess,
        },
    ] = useSaveWorkoutTranslationMutation()

    const alertRef = useRef()

    const params = useParams()
    const isNewWorkout = params?.id === 'new'

    const {data: workouts} = useGetWorkoutsQuery()
    const {data: exercisesFromServer} = useGetExercisesQuery()

    const [errorType, setErrorType] = useState(null)
    const [showError, setShowError] = useState(false)
    const [showNameError, setShowNameError] = useState(false)
    const [showDraftError, setShowDraftError] = useState(false)
    const [showSelectedTagsError, setShowSelectedTagsError] = useState(false)
    const [showSelectedExerciseError, setShowSelectedExerciseError] =
        useState(false)
    const [language, setLanguage] = useState(LANGUAGES[0].value)
    const [doneExercisesFormatting, setDoneExercisesFormatting] =
        useState(false)

    const skipRequest = isNewWorkout

    const {data: workout, isFetching: isFetchingWorkout} = useGetWorkoutQuery(
        skipRequest ? skipToken : {id: params?.id, language: language},
        {refetchOnMountOrArgChange: true}
    )

    const [
        deleteWorkout,
        {isSuccess: isSuccessDeleting, isError: isErrorDeleting},
    ] = useDeleteWorkoutMutation()

    const [updatedWorkout, setUpdatedWorkout] = useState({})

    const initializeBackgroundMusicValue = useCallback((workout) => {
        const backgroundMusicValue = workout?.background_music
            ?.replace(AWS_BASE_URL + '/music/', '')
            .replace('.mp3', '')
        return backgroundMusicValue?.toLowerCase()
    }, [])
    const getFieldValue = (itemValue, list) => {
        const foundItem = list.find((item) => item?.value === itemValue)
        return foundItem ? foundItem.value : null
    }

    useEffect(() => {
        if (workout) {
            setUpdatedWorkout({
                ...workout,
                instructions: getInstructions(workout),
                background_music: initializeBackgroundMusicValue(workout),
                goal: getFieldValue(workout.goal[0], objectiveOptions),

                body_region_focus: getFieldValue(
                    workout.body_region_focus[0],
                    bodyRegionsFocus
                ),
            })
        }
    }, [initializeBackgroundMusicValue, workout, language])

    const alertInfo = useMemo(() => alertTextsMap[errorType], [errorType])
    const {name, instructions} = updatedWorkout

    const onError = (error) => {
        setErrorType(error)
        if (error) {
            alertRef.current()
        }
    }

    const showSpinner = useMemo(
        () =>
            (!isNewWorkout && !workout) ||
            isSaving ||
            isFetchingWorkout ||
            !doneExercisesFormatting,
        [
            isNewWorkout,
            workout,
            isSaving,
            isFetchingWorkout,
            doneExercisesFormatting,
        ]
    )
    return (
        <Page>
            <PageContent>
                <DetailsWrapper
                    header="Workout Details"
                    backButtonText="Back to Workouts">
                    {language !== 'en' ? (
                        <ActivitySwitchLanguage
                            title={'EDIT WORKOUT'}
                            language={language}
                            activityId={workout?.id}
                            setLanguage={setLanguage}
                            activityName={name}
                            hideDisplayName
                            activityInstructions={instructions}
                            saveTranslation={saveTranslation}
                            isSavingError={isSavingTranslationError}
                            isSavingSuccess={isSavingTranslationSuccess}
                            loading={isSavingTranslation || isFetchingWorkout}
                        />
                    ) : (
                        <WorkoutDetails
                            onError={onError}
                            showSpinner={showSpinner}
                            isNewWorkout={isNewWorkout}
                            showDraftError={showDraftError}
                            setShowDraftError={setShowDraftError}
                            workout={workout}
                            workouts={workouts}
                            exercisesFromServer={exercisesFromServer}
                            saveWorkout={saveWorkout}
                            saveOptions={{
                                isError,
                                isSaving,
                                isSuccess,
                                data,
                            }}
                            language={language}
                            setLanguage={setLanguage}
                            showError={showError}
                            setShowError={setShowError}
                            showNameError={showNameError}
                            setShowNameError={setShowNameError}
                            updatedWorkout={updatedWorkout}
                            setUpdatedWorkout={setUpdatedWorkout}
                            showSelectedExerciseError={
                                showSelectedExerciseError
                            }
                            setShowSelectedExerciseError={
                                setShowSelectedExerciseError
                            }
                            showSelectedTagsError={showSelectedTagsError}
                            setShowSelectedTagsError={setShowSelectedTagsError}
                            doneExercisesFormatting={doneExercisesFormatting}
                            setDoneExercisesFormatting={
                                setDoneExercisesFormatting
                            }
                            deleteWorkout={deleteWorkout}
                            isSuccessDeleting={isSuccessDeleting}
                            isErrorDeleting={isErrorDeleting}
                        />
                    )}
                    <SweetAlert
                        ref={alertRef}
                        title={alertInfo?.title}
                        mainText={alertInfo?.mainText}
                        action=""
                        deactivate={() => {}}
                        hideAlertOnConfirm={() => {
                            setErrorType(null)
                        }}
                        confirmBtnText="Got it"
                        id={workout?.id}
                        hideCancel
                    />
                </DetailsWrapper>
            </PageContent>
        </Page>
    )
}

export default Workout
