import React from 'react'
import {Row} from 'components/general/Control'
import {
    BandAngle,
    PrimaryPlane,
    SegmentMotion,
    GripSelection,
    AnchorLocation,
    ExerciseAction,
    AnchorPosition,
    MovementPattern,
    ContractionFocus,
    OrientationToAnchor,
} from 'components/Exercise/Details'
import styled from 'styled-components'

const GearGoContent = ({showError, updatedExercise, updateExerciseField}) => {
    const {
        action,
        segment,
        grip_type,
        band_angle,
        primary_plane,
        anchor_location,
        movement_pattern,
        contraction_focus,
        anchor_orientation,
        anchor_position_type,
    } = updatedExercise || {}
    return (
        <Container>
            <Row>
                <GripSelection
                    type={grip_type}
                    setType={(value) => updateExerciseField('grip_type', value)}
                    showError={showError}
                />
            </Row>

            <AnchorPosition
                showError={showError}
                anchorPositionType={anchor_position_type}
                handleAnchorPositionType={(value) =>
                    updateExerciseField('anchor_position_type', value)
                }
            />
            <AnchorLocation
                showError={showError}
                anchorLocation={anchor_location}
                setAnchorLocation={(value) =>
                    updateExerciseField('anchor_location', value)
                }
            />
            <OrientationToAnchor
                showError={showError}
                anchorOrientation={anchor_orientation}
                setAnchorOrientation={(value) =>
                    updateExerciseField('anchor_orientation', value)
                }
            />

            <SegmentMotion
                showError={showError}
                segment={segment}
                setSegment={(value) => updateExerciseField('segment', value)}
            />
            <PrimaryPlane
                showError={showError}
                primaryPlane={primary_plane}
                setPrimaryPlane={(value) =>
                    updateExerciseField('primary_plane', value)
                }
            />
            <MovementPattern
                showError={showError}
                movementPattern={movement_pattern}
                setMovementPattern={(value) =>
                    updateExerciseField('movement_pattern', value)
                }
            />

            <ExerciseAction
                showError={showError}
                action={action}
                setAction={(value) => updateExerciseField('action', value)}
            />
            <ContractionFocus
                showError={showError}
                contractionFocus={contraction_focus}
                band_angle
                setContractionFocus={(value) =>
                    updateExerciseField('contraction_focus', value)
                }
            />
            <BandAngle
                showError={showError}
                bandAngle={band_angle}
                setBandAngle={(value) =>
                    updateExerciseField('band_angle', value)
                }
            />
        </Container>
    )
}
export default GearGoContent

const Container = styled.div``
