import {React} from 'common'
import {useGetUsersInDeletionQuery} from 'store/store'
import {useMemo} from 'services/hooks'
import {Page} from 'components/general/Page'
import {TableWrapper} from 'components/general/Display/Table'

const columns = [
    {
        Header: 'Email',
        accessor: 'email',
        filter: 'default',
        isSortableIcon: true,
        defaultSort: true,
    },
    {
        Header: 'Id',
        accessor: 'id',
        filter: 'default',
        isSortableIcon: true,
        defaultSort: true,
    },
    {
        Header: 'Deletion request date',
        accessor: 'deletionRequestDate',
        filter: 'default',
        isSortableIcon: true,
        defaultSort: true,
    },
    {
        Header: 'Inner Data',
        accessor: 'innerData',
        maxWidth: 100,
    },
    {
        Header: 'Zendesk',
        accessor: 'zendesk',
        maxWidth: 70,
    },
    {
        Header: 'Amplitude',
        accessor: 'amplitude',
        maxWidth: 70,
    },
]

const UsersInDeletion = () => {
    const users = useGetUsersInDeletionQuery(
        {},
        {
            refetchOnMountOrArgChange: true,
        }
    )

    console.log({users}, 'USERS IN DELETION')

    const getICon = (isActive) =>
        isActive ? (
            <i value={1} className={'tim-icons icon-check-2 text-success'} />
        ) : (
            <i
                value={0}
                className={'tim-icons icon-simple-remove text-danger'}
            />
        )

    const data = useMemo(
        () =>
            users?.data?.map((prop, key) => {
                return {
                    id: prop.id,
                    email: prop.email,
                    deletionRequestDate: prop.deletionRequestDate,
                    innerData: getICon(prop.isInnerDataDeleted),
                    zendesk: getICon(prop.isZendeskDataDeleted),
                    amplitude: getICon(prop.isAmplitudeDataDeleted),
                }
            }),
        [users.data]
    )

    // console.log(tableParams, 'tableParams')
    return (
        <Page hideSidbar={false}>
            <TableWrapper
                title="Users"
                columns={columns}
                data={data}
                isError={users?.isError}
                isFetching={users?.isLoading}
            />
        </Page>
    )
}

export default UsersInDeletion
