import {React, styled} from 'common'
import {Input} from 'components/general/Control'
import {useEffect, useUrlParams} from 'services/hooks'

const DefaultColumnFilter = ({
    column: {filterValue, preFilteredRows, setFilter, id},
    visibility = true,
}) => {
    const [urlParams, setUrlParam] = useUrlParams()
    const {filter} = urlParams

    const count = preFilteredRows.length

    useEffect(() => {
        if (filter && id) {
            const parsedFilter = JSON.parse(filter)
            if (
                parsedFilter[id] !== filterValue &&
                (parsedFilter[id] || filterValue)
            ) {
                setFilter(parsedFilter[id])
            }
        }
    }, [filter, filterValue, id, setFilter])

    const onChangeValue = (value) => {
        const newFilter = filter ? JSON.parse(filter) : {}
        newFilter[id] = value
        setUrlParam('filter', JSON.stringify(newFilter))
    }

    return (
        visibility && (
            <StyledInput
                id="search_filter"
                value={filterValue || ''}
                style={{visibility: visibility ? 'visible' : 'hidden'}}
                setter={onChangeValue}
                placeholder={`Search ${count} records...`}
            />
        )
    )
}

export default DefaultColumnFilter

const StyledInput = styled(Input)`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
