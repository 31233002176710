import {Col} from 'components/general/Control'
import SingleCheckbox from 'components/general/Control/SingleCheckbox'
import React from 'react'

const SingleBand = ({singleBand, setSingleBand}) => {
    return (
        <Col sm="12" md="6" lg="6">
            <SingleCheckbox
                label={'Single Band Exercise'}
                description={
                    '(when NOT selected exercise targets - weight, calories and total work are multiplied by 2)'
                }
                option={{
                    label: 'Single Band Exercise',
                    value: singleBand,
                }}
                onChange={setSingleBand}
            />
        </Col>
    )
}
export default SingleBand
