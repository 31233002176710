/* eslint-disable no-unused-vars */
import React from 'react'
import {FormGroup, Col} from 'components/general/Control'
import {gripTypes} from 'constant/exercise'
import RadioButtonsInput from 'components/general/Control/RadioButtonsInput'

const GripSelection = ({type, setType, showError}) => {
    return (
        <Col className="pr-md-0" sm="12" md="6">
            <FormGroup>
                <RadioButtonsInput
                    required
                    options={gripTypes}
                    name={'attachments_types'}
                    selected={type}
                    label={'Attachment'}
                    onChange={setType}
                    showError={showError}
                />
            </FormGroup>
        </Col>
    )
}

export default GripSelection
