import {React} from 'common'
import {Page} from 'components/general/Page'
import {TableWrapper} from 'components/general/Display/Table'

import {useGetCatalogLabelsQuery} from 'store/store'

import {useMemo} from 'services/hooks'

const columns = [
    {
        Header: 'First Name',
        accessor: 'name',
        filter: 'default',
        isSortableIcon: true,
    },

    {
        Header: 'Labels Count',
        accessor: 'labelsCount',
    },
]

const Lables = () => {
    const catalog = useGetCatalogLabelsQuery('en', {
        refetchOnMountOrArgChange: true,
    })

    const data = useMemo(
        () =>
            catalog?.data?.map((prop, key) => {
                return {
                    id: prop._id,
                    name: prop.name,
                    pathname: `/admin/label/${prop._id}`,
                    _id: prop._id,
                    labelsCount: prop.labels?.length,
                }
            }),
        [catalog.data]
    )

    return (
        <Page hideSidbar={false}>
            <TableWrapper
                title="Labels"
                columns={columns}
                data={data}
                createNew={'/admin/label/new'}
                isError={catalog?.isError}
            />
        </Page>
    )
}

export default Lables
