import {React, styled} from 'common'

import {
    Button,
    Nav,
    NavItem,
    InputGroup,
    Collapse,
    Switch,
    Container,
} from 'components/general/Control'
// import {Switch} from 'components/general/Control'

const ThemeAndLogout = ({
    toggleCollapse,
    collapseOpen,
    handleActiveMode,
    darkMode,
    logout,
}) => {
    return (
        <StyledContainer>
            <Collapse navbar isOpen={collapseOpen}>
                <Nav className="ml-auto" navbar>
                    <NavItem>
                        <div
                            style={{
                                whiteSpace: 'nowrap',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            className="togglebutton switch-change-color mt-3">
                            <span
                                style={{margin: '0 10px 10px 0'}}
                                className="label-switch">
                                LIGHT MODE
                            </span>
                            <Switch
                                onChange={handleActiveMode}
                                value={darkMode}
                                onText=""
                                offText=""
                            />
                            <span
                                style={{margin: '0 0 10px 10px'}}
                                className="label-switch">
                                DARK MODE
                            </span>
                        </div>
                    </NavItem>
                    <InputGroup className="search-bar" tag="li">
                        <Button color="link" id="logout" onClick={logout}>
                            <i className="tim-icons icon-button-power" />
                            <span className="d-block">Log Out</span>
                        </Button>
                    </InputGroup>
                    <li className="separator d-lg-none" />
                </Nav>
            </Collapse>
            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navigation"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={toggleCollapse}>
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
            </button>
        </StyledContainer>
    )
}

export default ThemeAndLogout

const StyledContainer = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
`
